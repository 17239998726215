var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "transactions-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", lg: "3", xl: "3" } },
            [
              _c("qs-export-menu", {
                on: {
                  export: function($event) {
                    return _vm.exportWithWarningAs($event)
                  }
                }
              }),
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  flat: "",
                  "model-name": _vm.slug
                },
                on: { input: _vm.setTransactionsParam },
                scopedSlots: _vm._u([
                  {
                    key: "contact.id.item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  }
                ])
              }),
              _vm.stats
                ? _c("qs-stats-card", {
                    attrs: {
                      color: "blue",
                      label: "Montant total des transactions",
                      icon: "mdi-currency-usd",
                      stat: _vm.stats.sum_amount_in_cad,
                      type: "currency"
                    }
                  })
                : _vm._e(),
              _vm.stats
                ? _c("qs-stats-card", {
                    staticClass: "mt-3 mb-3",
                    attrs: {
                      color: "blue",
                      label: "Nombre de transactions",
                      icon: "mdi-sigma",
                      stat: _vm.stats.total
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "7", lg: "9", xl: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  actions: ["edit", "delete"],
                  flat: "",
                  headers: _vm.headers,
                  items: _vm.data,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  tile: "",
                  total: _vm.total || 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.type",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("enumVal")(
                                item.type,
                                "transactions",
                                "type"
                              )
                            ) +
                            " "
                        ),
                        item.external_id
                          ? _c("small", [
                              _vm._v("(" + _vm._s(item.external_id) + ")")
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.external_type",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("enumVal")(
                                item.external_type,
                                "transactions",
                                "external_type"
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.contact.full_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.contact_id
                          ? _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("contact-admin-popover", {
                                  attrs: { item: item.contact }
                                }),
                                _vm._v(
                                  " " + _vm._s(item.contact.full_name) + " "
                                )
                              ],
                              1
                            )
                          : _c("span", {}, [_vm._v("Contact manquant")])
                      ]
                    }
                  },
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm._f("statusColor")(
                                                item.status
                                              )
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v(" mdi-radiobox-marked ")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("status")(item.status, "transactions")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.amount_in_cad",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(item.amount_in_cad)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.executed_at",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("date")(item.executed_at)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.goto("transactions", item)
                              }
                            }
                          },
                          [_vm._v(" mdi-pencil ")]
                        ),
                        (item.memberships && item.memberships.length > 0) ||
                        (item.contributions && item.contributions.length > 0)
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              { attrs: { small: "" } },
                                              on
                                            ),
                                            [_vm._v(" mdi-delete ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(
                                  " Supprimez d'abord les adhésions ou contributions "
                                )
                              ]
                            )
                          : _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmThenDeleteTransaction(
                                      item
                                    )
                                  }
                                }
                              },
                              [_vm._v(" mdi-delete ")]
                            )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.listDialog.value = false
              },
              "click:confirm": _vm.listDialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.listDialog,
          false
        )
      ),
      _c("router-view", { attrs: { "model-name": "transactions" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }