import { render, staticRenderFns } from "./SmsCampaigns.vue?vue&type=template&id=0f0a8d4c&"
import script from "./SmsCampaigns.vue?vue&type=script&lang=ts&"
export * from "./SmsCampaigns.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f0a8d4c')) {
      api.createRecord('0f0a8d4c', component.options)
    } else {
      api.reload('0f0a8d4c', component.options)
    }
    module.hot.accept("./SmsCampaigns.vue?vue&type=template&id=0f0a8d4c&", function () {
      api.rerender('0f0a8d4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Sms/SmsCampaigns.vue"
export default component.exports