var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "automated-email-template", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-3" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    color: "primary",
                    icon: "",
                    link: "",
                    tile: "",
                    to: "/automated_email_templates"
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-menu-left")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "text-h6" }, [
                _vm._v("Retour à la liste")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.itemReady
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c(
                    "qs-card",
                    { staticStyle: { "min-height": "100%" } },
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              disabled:
                                _vm.item.is_system && !_vm.userIsSuperadmin,
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: [
                                "name",
                                "description",
                                "template",
                                "send_once"
                              ]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e(),
                      _vm.item.is_system
                        ? _c("div", [
                            _c("hr"),
                            _c("p", { staticClass: "text-center" }, [
                              _c("em", [
                                _vm._v(
                                  "Envoi système associé à une tâche programmée"
                                )
                              ])
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.item
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "9" } },
                    [
                      _c(
                        "qs-card",
                        { staticStyle: { "min-height": "100%" } },
                        [
                          _vm.itemReady
                            ? _c("qs-form-builder", {
                                attrs: {
                                  error: _vm.error,
                                  form: _vm.form,
                                  order: ["subject", "content"],
                                  item: _vm.item,
                                  "model-name": _vm.slug
                                },
                                on: { input: _vm.syncItem },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function(ref) {
                                        var errorMessagesFor =
                                          ref.errorMessagesFor
                                        var item = ref.item
                                        return [
                                          _c("qs-html-editor", {
                                            class: "theme-" + item.template,
                                            attrs: {
                                              errors: errorMessagesFor(
                                                "content"
                                              ),
                                              loading: _vm.loading,
                                              options: { image: true }
                                            },
                                            model: {
                                              value: item.content,
                                              callback: function($$v) {
                                                _vm.$set(item, "content", $$v)
                                              },
                                              expression: "item.content"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2944716889
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "40vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          ),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.dialog.value,
          color: _vm.dialog.color,
          icon: _vm.dialog.icon,
          loading: _vm.loading || _vm.dialog.loading,
          message: _vm.dialog.message,
          title: _vm.dialog.title
        },
        on: {
          "click:cancel": function() {
            return (_vm.dialog.value = false)
          },
          "click:confirm": _vm.dialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }