





































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { PersistedAutomatedEmailTemplate } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';

const global: any = namespace('global');
const store: any = namespace('automated_email_templates');
const view: any = namespace('automatedEmailTemplatesView');

@Component({
  components: {
    QsBaseLayout,
    QsButton,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class AutomatedEmailTemplatesList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  InteractsWithNavbar,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;

  @store.Getter data!: PersistedAutomatedEmailTemplate[];
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedAutomatedEmailTemplate[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  setListParams = this.buildSetParam('automated_email_templates', this.setParams);

  headers = [
    { text: 'Id', value: 'id', sortable: true },
    { text: 'Nom', value: 'name', sortable: true },
    { text: 'Sujet', value: 'subject', sortable: true },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  get viewParams() {
    return {
      automated_email_templates: {
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  mounted() {
    this.setGlobalActions();
  }

  async confirmAndDelete(item: PersistedAutomatedEmailTemplate): Promise<void> {
    this.confirmList(
      '',
      'Êtes-vous certain·e de vouloir <strong>supprimer ce gabarit</strong>?'
        + '<br>Cette action est irréversible',
      'error',
      'mdi-alert',
      async () => {
        try {
          await this.$store.dispatch('automated_email_templates/destroy', { id: item.id });
          this.addNotification({
            message: 'Le gabarit a été supprimé',
            color: 'success',
          });
          this.reloadDataRoutesData(['automated_email_templates']);
        } catch (e) {
          this.addNotification({
            message: 'Erreur lors de la suppression du gabarit',
            color: 'error',
          });
        }
      },
    );
  }

  loadCopy(item: PersistedAutomatedEmailTemplate): void {
    this.$router.push('/automated_email_templates/new');
    setTimeout(() => {
      this.$store.commit('automated_email_templates/item', {
        ...item,
        id: null,
        name: `Copie de ${item.name}`,
      });
    }, 500);
  }

  reloadRoutesData() {
    this.reloadDataRoutesData(['automated_email_templates']);
  }


  setGlobalActions() {
    if (this.userHas('AUTOMATED_EMAIL_TEMPLATES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/automated_email_templates/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }
}
