import { Module } from 'vuex';

import { InstancesViewsState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const instancesViews: Module<InstancesViewsState, RootState> = {
  namespaced: true,
  state: {
    options: {
      sortBy: ['name'],
      sortDesc: [],
      page: 1,
      itemsPerPage: -1,
    },
    params: {
      fields: [
        'delegation_stats',
        'instance_type.name',
        'is_active',
        'name',
      ].join(','),
      'instance_type.id': '10,11',
      order: 'name',
      per_page: '*',
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
    params(state) {
      return state.params;
    },
  },
  mutations: {
    options(state, options) {
      state.options = options;
    },
    params(state, params) {
      state.params = params;
    },
  },
};

export default instancesViews;
