import { ButtonProps } from 'qs_vuetify/src/types/components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class InteractsWithNavbar extends Vue {
  commitGlobalActions(actions: ButtonProps[]): void {
    this.$store.commit('global/actions', actions);
  }

  commitGlobalSubtitle(subtitle: string): void {
    this.$store.commit('global/subtitle', subtitle);
  }

  @Watch('$route.path')
  onInteractisWithNavbarRouteChanged() {
    this.setGlobalActions();
  }

  @Watch('routeDataLoaded')
  onInteractisWithNavbarRouteDataLoadedChanged() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  @Watch('itemReady')
  onInteractisWithNavbarItemReadyChanged() {
    this.setGlobalSubtitle();
  }

  @Watch('userIsConnected')
  onInteractisWithNavbarUserIsConnectedChanged() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  // eslint-disable-next-line class-methods-use-this
  reloadRoutesData() {
    //
  }

  // eslint-disable-next-line class-methods-use-this
  setGlobalActions() {
    //
  }

  // eslint-disable-next-line class-methods-use-this
  setGlobalSubtitle() {
    //
  }
}
