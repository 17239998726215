var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qs-events-visibility-indicator" },
    [
      _vm.disabled
        ? _c(
            "div",
            [
              _vm.withTooltip
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", "open-on-click": false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: _vm.computedColor,
                                        large: _vm.large
                                      }
                                    },
                                    on
                                  ),
                                  [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        937041033
                      )
                    },
                    [_vm._v(" " + _vm._s(_vm.tooltipLabel) + " ")]
                  )
                : _c(
                    "v-icon",
                    { attrs: { color: _vm.computedColor, large: _vm.large } },
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  )
            ],
            1
          )
        : _c(
            "v-speed-dial",
            {
              attrs: { value: _vm.speedDialOpen, direction: "right" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function() {
                    return [
                      _vm.loading
                        ? _c("v-progress-circular", {
                            staticClass:
                              "qs-events-visibility-indicator__loading",
                            attrs: {
                              size: "18",
                              width: "3",
                              indeterminate: "",
                              color: "light"
                            }
                          })
                        : _vm.withTooltip
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", "open-on-click": false },
                              on: {
                                click: function($event) {
                                  _vm.speedDialOpen = true
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        { attrs: { icon: "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: _vm.computedColor,
                                                  large: _vm.large
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.icon) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_vm._v(" " + _vm._s(_vm.tooltipLabel) + " ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.speedDialOpen = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: _vm.computedColor,
                                    large: _vm.large
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              )
                            ],
                            1
                          )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { fab: "", small: "", color: "success" },
                  on: {
                    click: function($event) {
                      return _vm.updateContactEventIntention("public")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-eye")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { fab: "", small: "", color: "info" },
                  on: {
                    click: function($event) {
                      return _vm.updateContactEventIntention("private")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-eye-off")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { fab: "", small: "", color: "grey" },
                  on: {
                    click: function($event) {
                      return _vm.updateContactEventIntention("draft")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-file")])],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }