































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsTagsForm from 'qs_vuetify/src/components/Tags/QsTagsForm.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { PersistedTag } from 'qs_vuetify/src/types/models';
import { Form } from 'qs_vuetify/src/types/components';
import { RestParams } from 'qs_vuetify/src/types/states';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const global: any = namespace('global');
const store: any = namespace('tags');
const view: any = namespace('tagsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsTagsForm,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class TagsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Mutation addNotification!: any;

  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter item!: PersistedTag;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  formOrder = [
    'name',
    'description',
    'instance_id',
    'parent_id',
  ];

  mounted() {
    this.setAction();
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams() {
    return {
      tags: {
        fields: [
          '*',
          'instance.name',
          'parent.name',
          'created_by_user.contact_name',
          'updated_by_user.contact_name',
        ].join(','),
      },
      'tags.index': ListMixin.buildListState(this.params),
    };
  }

  setAction() {
    if (this.userHas('TAGS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/tags/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  async submitForm() {
    try {
      await this.submit();
      this.$router.push('/tags');
    } catch (e) {
      this.addNotification({
        message: 'Erreur lors de la sauvegarde',
        color: 'error',
      });
    }
  }
}
