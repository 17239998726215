





















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Membership } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import ContactAdminPopover from '@/components/ContactAdminPopover.vue';
import TransactionAdminPopover from '@/components/TransactionAdminPopover.vue';

const store: any = namespace('memberships');
const view: any = namespace('membershipsViews');

@Component({
  components: {
    ContactAdminPopover,
    QsContactListItem,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    TransactionAdminPopover,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Memberships extends mixins(
  AuthenticationMixin,
  ExportMixin,
  ListMixin,
) {
  @store.Getter data!: Array<Membership>;
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;
  @store.Getter stats!: object;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setParams!: any;

  setTransactionsParam = this.buildSetParam('memberships', this.setParams);

  headers = [
    { text: 'ID', value: 'id' },
    { text: 'Transaction', value: 'transaction_id', align: 'center' },
    { text: 'Contact', value: 'contact.full_name', sortable: false },
    { text: 'Date de début', value: 'period_start', align: 'right' },
    { text: 'Date de fin', value: 'period_end', align: 'right' },
    {
      text: 'Montant', value: 'amount_in_cad', align: 'right', sortable: false,
    },
    { text: '', value: 'actions' },
  ];

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      memberships: {
        fields: [
          'amount_in_cad',
          'contact.age',
          'contact.birthdate',
          'contact.district.name',
          'contact.email',
          'contact.full_name',
          'contact.home_phone',
          'contact.postal_code',
          'contact.status',
          'contact_v1_contact_id',
          'period_start',
          'period_end',
          'transaction.*',
          'transaction_id',
        ].join(','),
        ...ListMixin.buildListState(this.options, this.params),
      },
    };
  }

  mounted() {
    this.$store.commit('memberships/exportFields', [
      'id',
      'period_start',
      'period_end',
      'amount_in_cad',
      'contact_full_name',
      'contact_v1_contact_id',
      'transaction_executed_at',
      'transaction_type',
      'transaction_external_id',
    ]);
  }
}
