var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        border: "left",
        color: "error",
        icon: _vm.icon,
        text: "",
        tile: ""
      }
    },
    [
      _vm._v(
        " Attention! Cette action est irréversible. Voulez-vous continuer? "
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row justify-center mt-4" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "error", depressed: "", tile: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("confirm:true")
                }
              }
            },
            [_vm._v(" Oui ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "info", outlined: "", tile: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("confirm:false")
                }
              }
            },
            [_vm._v(" Non ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }