var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        height: "70vh",
        loading: _vm.loading,
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Nouveau contact ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    depressed: "",
                    disabled:
                      (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                    large: "",
                    loading: _vm.loading,
                    tile: ""
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [_c("qs-contact-form", { attrs: { id: "new" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }