






































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { PersistedDistrict, PersistedPostalCode } from 'qs_vuetify/src/types/models';

const global: any = namespace('global');
const districts: any = namespace('districts');
const store: any = namespace('postal_codes');
const view: any = namespace('postalCodesViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class PostalCodesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  InteractsWithNavbar,
) {
  @global.Mutation addNotification!: any;

  @districts.Getter('data') districts!: PersistedDistrict[];

  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter form!: Form;
  @store.Getter item!: PersistedPostalCode;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  get formOrder(): string[] {
    const form: string[] = ['districts.id'];

    if (this.isNew) {
      form.unshift('code');
    }

    return form;
  }

  get viewParams() {
    return {
      postal_codes: ListMixin.buildListState(this.options, this.params),
    };
  }

  closeModal() {
    this.$router.push({ name: 'PostalCodesList' });
  }

  setGlobalActions() {
    if (this.userHas('POSTAL_CODES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/postal_codes/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (this.isNew) {
      this.commitGlobalSubtitle('Nouveau');
    } else if (!this.itemReady) {
      this.commitGlobalSubtitle('Chargement...');
    } else {
      this.commitGlobalSubtitle(this.item?.code);
    }
    this.$emit('updateHead');
  }
}
