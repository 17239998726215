var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "conversation", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "3" } },
            [
              _c("sent-sms-list", {
                attrs: {
                  context: { id: _vm.$route.params.sms_campaign_id },
                  items: _vm.data,
                  loading: _vm.loading,
                  "mark-as-read-loading": _vm.markAsReadLoading
                },
                on: {
                  "click:read": function($event) {
                    return _vm.markAsRead($event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "6" } },
            [
              _c("messenger", {
                attrs: {
                  messages: _vm.item
                    ? [_vm.item].concat(_vm.item.sms_replies)
                    : []
                },
                on: {
                  reply: function($event) {
                    return _vm.reply({ id: _vm.item.id, body: $event })
                  }
                },
                model: {
                  value: _vm.replyBody,
                  callback: function($$v) {
                    _vm.replyBody = $$v
                  },
                  expression: "replyBody"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "3" } },
            [
              _c("context", {
                attrs: {
                  campaign: _vm.smsCampaign || null,
                  contact: _vm.item ? _vm.item.contact : null
                },
                on: {
                  "click:reply": function($event) {
                    _vm.replyBody = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.sendDialog.value,
          color: _vm.sendDialog.color,
          icon: _vm.sendDialog.icon,
          loading: _vm.loading || _vm.sendDialog.loading,
          message: _vm.sendDialog.message,
          title: _vm.sendDialog.title
        },
        on: {
          "click:cancel": function() {
            _vm.sendDialog.value = false
          },
          "click:confirm": _vm.sendDialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }