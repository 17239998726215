var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { "content-class": "postal-codes-form", value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.isNew
                ? [_vm._v(" Créer un code postal ")]
                : [_vm._v(" Modifier " + _vm._s(_vm.item.code) + " ")]
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                { attrs: { theme: "labase" }, on: { click: _vm.submit } },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.item
        ? _c("qs-form-builder", {
            attrs: {
              form: _vm.form,
              item: _vm.item,
              "model-name": _vm.slug,
              order: _vm.formOrder
            },
            on: { input: _vm.syncItem }
          })
        : _c(
            "div",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }