


























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import type { RawLocation } from 'vue-router';

import QsCard from 'qs_vuetify/src/components/QsCard.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { RestParams } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');

interface ReportItem {
    name: string;
    route: RawLocation;
    store: string;
    params: RestParams;
}

interface ReportGroup {
    name: string;
    children: ReportTree;
}

type ReportTree = (ReportItem | ReportGroup)[];

const isReportItem = (item: any): item is ReportItem => item
  && (item as ReportItem).route !== undefined
  && typeof (item as ReportItem).store === 'string'
  && (item as ReportItem).params !== undefined;

@Component({
  components: {
    QsCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Home extends mixins(AuthenticationMixin, DataRouteGuards) {
  @auth.Getter loading!: boolean;

  reports: ReportTree = [
    {
      name: 'Délégations',
      route: { name: 'DelegationReport' },
      store: 'instancesViews',
      params: {
        'instance_type.id': '10,11',
      },
    },
    {
      name: 'Adhésions',
      children: [
        {
          name: 'Revenus futurs',
          children: [
            {
              name: '2022',
              route: { name: 'Memberships' },
              store: 'membershipsViews',
              params: {
                period_start_year: 2022,
              },
            },
            {
              name: '2023',
              route: { name: 'Memberships' },
              store: 'membershipsViews',
              params: {
                period_start_year: 2023,
              },
            },
            {
              name: '2024',
              route: { name: 'Memberships' },
              store: 'membershipsViews',
              params: {
                period_start_year: 2024,
              },
            },
            {
              name: '2025',
              route: { name: 'Memberships' },
              store: 'membershipsViews',
              params: {
                period_start_year: 2025,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Transactions',
      children: [
        {
          name: 'Adhésions : toutes',
          route: { name: 'Transactions' },
          store: 'transactionsView',
          params: {
            external_type: 'membership',
            status: 'success',
          },
        },
        {
          name: 'Contributions : toutes',
          route: { name: 'Transactions' },
          store: 'transactionsView',
          params: {
            external_type: 'contribution',
            status: 'success',
          },
        },
      ],
    },
    {
      name: 'Envois courriels',
      children: [
        {
          name: 'Rappels de renouvellement J-30',
          route: { name: 'SentEmailsList' },
          store: 'sentEmailsViews',
          params: {
            mailable_class: 'App\\Mail\\MembershipRenew\\DMinus30MembershipRenewMail',
          },
        },
        {
          name: 'Rappels de renouvellement J',
          route: { name: 'SentEmailsList' },
          store: 'sentEmailsViews',
          params: {
            mailable_class: 'App\\Mail\\MembershipRenew\\DDayMembershipRenewMail',
          },
        },
        {
          name: 'Rappels de contribution',
          route: { name: 'SentEmailsList' },
          store: 'sentEmailsViews',
          params: {
            mailable_class: 'App\\Mail\\ContributionRenewMail',
          },
        },
      ],
    },
  ];

  get loginDialog(): boolean {
    return this.$store.state.global.loginDialog;
  }

  set loginDialog(val) {
    this.$store.commit('global/loginDialog', val);
  }

  openReport(item: ReportItem | ReportGroup) {
    if (isReportItem(item)) {
      this.$store.commit(`${item.store}/params`, item.params);
      this.$router.push(item.route);
    }
  }
}
