var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        "content-class": "form-definition-templates-form",
        height: "80vh",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                !_vm.itemReady
                  ? [_vm._v(" Chargement... ")]
                  : _vm.isNew
                  ? [_vm._v(" Nouveau gabarit ")]
                  : [_vm._v(" Modifier le gabarit ")]
              ]
            },
            proxy: true
          },
          _vm.userHas("FORM_DEFINITION_TEMPLATES_UPDATE")
            ? {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "qs-button",
                      { attrs: { theme: "labase" }, on: { click: _vm.submit } },
                      [_vm._v(" Enregistrer ")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady
        ? [
            _vm.item
              ? _c("qs-form-builder", {
                  attrs: {
                    errors: _vm.error,
                    form: _vm.form,
                    item: _vm.item,
                    "model-name": _vm.slug,
                    order: _vm.formOrder
                  },
                  on: { input: _vm.syncItem },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "form_definition",
                        fn: function(ref) {
                          var item = ref.item
                          var update = ref.update
                          return [
                            _c("qs-form-editor", {
                              attrs: {
                                title: "Questions",
                                value: _vm.formOrEmpty(item.form_definition)
                              },
                              on: {
                                input: function($event) {
                                  return update("form_definition", $event)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3854523973
                  )
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }