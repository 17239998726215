



































import { PersistedContact } from 'qs_vuetify/src/types/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import AdminPopover from './AdminPopover.vue';

@Component({
  components: {
    AdminPopover,
  },
})
export default class ContactAdminPopover extends Vue {
  @Prop({ required: true, type: Object }) item!: PersistedContact;
}
