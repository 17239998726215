



















































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsFormEditor from 'qs_vuetify/src/components/QsFormEditor.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { FormDefinitionTemplate, PersistedFormDefinitionTemplate } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('form_definition_templates');
const view: any = namespace('formDefinitionTemplatesViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
    QsFormEditor,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class FormDefinitionTemplatesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  InteractsWithNavbar,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter form!: Form;
  @store.Getter item!: PersistedFormDefinitionTemplate;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter params!: RestParams;
  @view.Mutation setParams!: any;

  @Prop([String, Number]) id!: string | number;

  defaultFields: string = [
    '*',
  ].join(','); // FIXME

  formOrder: string[] = [
    'name',
    'instance_id',
    'description',
    'form_definition',
  ];

  destroyed() {
    this.syncItem(null);
    this.commitGlobalSubtitle('');
  }

  mounted() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  get viewParams() {
    return {
      form_definition_templates: {
        fields: this.defaultFields,
      },
    };
  }

  closeModal() {
    this.$router.push({ name: 'FormDefinitionTemplatesList' });
  }

  // eslint-disable-next-line class-methods-use-this
  formOrEmpty(value: Array<any> | FormDefinitionTemplate): Omit<FormDefinitionTemplate, 'id'> {
    if (Array.isArray(value)) {
      return {};
    }

    return value;
  }

  setGlobalActions() {
    if (this.userHas('FORM_DEFINITION_TEMPLATES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/phones/new');
          },
          color: 'primary',
          disabled: true,
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (this.isNew) {
      this.commitGlobalSubtitle('Nouveau');
    } else if (!this.itemReady) {
      this.commitGlobalSubtitle('Chargement...');
    } else {
      this.commitGlobalSubtitle(this.item?.name);
    }
    this.$emit('updateHead');
  }
}
