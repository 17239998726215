










































































































import Vue from 'vue';
import Component from 'vue-class-component';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';

import axios from 'qs_vuetify/src/plugins/axios';
import { Prop } from 'vue-property-decorator';
import { PersistedTag } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsButton,
    QsCard,
  },
})
export default class DataEntryTable extends Vue {
  @Prop({ required: false, default: null }) tag!: PersistedTag | null;

  items: { [key: number]: any } = {
    1: {
      district: null,
      email: '',
      first_name: '',
      home_phone: '',
      last_name: '',
      loading: null,
      message: '',
      postal_code: '',
    },
  };

  get entries() {
    return Object.entries(this.items);
  }

  addBlankLine() {
    const newLine = Object.keys(this.items).length + 1;
    Vue.set(this.items, newLine, {
      district: null,
      email: '',
      first_name: '',
      home_phone: '',
      last_name: '',
      loading: false,
      postal_code: '',
    });
  }

  debounceFindContact = this.$helpers.debounce((email: string, index: number) => {
    if (email.match(/[a-zA-Z0-9._]+[@]+[a-zA-Z0-9]+[.]+[a-zA-Z]{2,6}/)) {
      this.getFindContactFromEmail(email, index);
    }
  }, 1000);

  async getFindContactFromEmail(email: string, index: number) {
    this.items[index].loading = 'email';
    try {
      const ajax = axios.get('/emails', {
        params: {
          email,
          fields: [
            'email',
            'contact.district.name',
            'contact.first_name',
            'contact.home_phone',
            'contact.last_name',
            'contact.postal_code',
            'contact.v1_contact_id',
          ].join(','),
        },
      });

      const { data: { data } } = await ajax;
      if (data) {
        this.items[index] = {
          ...data[0].contact,
          email,
        };
      }
    } catch (e) {
      //
    } finally {
      this.items[index].loading = null;
    }
  }

  debounceFindDistrict = this.$helpers.debounce((postalCode: string, index: number) => {
    this.getDistrictFromPostalCode(postalCode, index);
  }, 1000);

  async getDistrictFromPostalCode(postalCode: string, index: number) {
    this.items[index].loading = 'postal_code';
    try {
      const ajax = axios.get('/postal_codes', {
        params: {
          code: postalCode,
          fields: [
            'code',
            'districts.name',
          ].join(','),
        },
      });

      const { data: { data } } = await ajax;
      if (data) {
        this.items[index].district = data[0].districts[0];
        this.items[index].postal_code = data[0].code;
      }
    } catch (e) {
      //
    } finally {
      this.items[index].loading = null;
    }
  }

  async postContact(index: number) {
    if (this.items[index]) {
      this.items[index].loading = 'create';

      let emails = null;

      if (this.items[index].email) {
        emails = [
          { email: this.items[index].email },
        ];
      }

      try {
        const ajax = axios.post('/contacts', {
          ...this.items[index],
          emails,
        });

        const { data } = await ajax;
        if (data) {
          this.items[index].id = data.id;
        }
      } catch (e) {
        //
      } finally {
        this.items[index].loading = null;
      }
    }
  }

  async putContactTag(index: number) {
    if (this.items[index] && this.items[index].id && this.tag) {
      this.items[index].loading = 'tag';
      try {
        await axios.put(`/contacts/${this.items[index].id}/tags/${this.tag.id}`);
      } catch (e) {
        //
      } finally {
        this.items[index].loading = null;
      }
    }
  }
}
