




































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { PersistedContact, PersistedPhone } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('phones');
const view: any = namespace('phonesViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsContactListItem,
    QsFormBuilder,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class PhonesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  InteractsWithNavbar,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter form!: Form;
  @store.Getter item!: PersistedPhone;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  contact: PersistedContact | null = null;
  defaultFields: string = [
    [
      'age',
      'birthdate',
      'district.name',
      'email',
      'full_name',
      'postal_code',
      'searchable_text',
      'status',
      'v1_contact_id',
    ].map((f) => `contacts.${f}`),
    'oqlf_format',
    'status',
    'type',
  ].join(',');

  isAddingContact: boolean = false;

  destroyed() {
    this.syncItem(null);
    this.commitGlobalSubtitle('');
  }

  mounted() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  afterSave() {
    this.setGlobalSubtitle();
    this.reloadDataRoutesData();
  }

  get formOrder(): string[] {
    if (this.isNew) {
      return [
        'phone_number',
        'type',
        'status',
      ];
    }

    return [
      'type',
      'status',
    ];
  }

  get viewParams() {
    return {
      phones: ListMixin.buildListState(this.params),
    };
  }

  addContact() {
    if (this.contact) {
      const index = this.item.contacts
        .map((c: PersistedContact) => c.id)
        .find((id: number) => id === this.contact?.id);

      if (index) {
        return;
      }

      this.item.contacts = [
        ...this.item.contacts,
        { ...this.contact },
      ];
      this.contact = null;
      this.isAddingContact = false;
    }
  }

  closeModal() {
    this.$router.push({ name: 'PhonesList' });
  }

  removeContact(contact: PersistedContact) {
    if (contact) {
      const index = this.item.contacts
        .map((c: PersistedContact) => c.id)
        .findIndex((id: number) => id === contact.id);
      this.item.contacts.splice(index, 1);
    }
  }

  setGlobalActions() {
    if (this.userHas('PHONES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/phones/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (this.isNew) {
      this.commitGlobalSubtitle('Nouveau');
    } else if (!this.itemReady) {
      this.commitGlobalSubtitle('Chargement...');
    } else {
      this.commitGlobalSubtitle(this.item?.oqlf_format);
    }
    this.$emit('updateHead');
  }

  submitForm() {
    this.submit({
      fields: this.defaultFields,
    });
  }
}
