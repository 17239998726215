


































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsContactForm from '@/components/contacts/QsContactForm.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Contact } from 'qs_vuetify/src/types/models';

const global: any = namespace('global');
const store: any = namespace('contacts');

@Component({
  components: {
    QsActionModal,
    QsContactForm,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewContactsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Mutation addNotification!: any;
  @global.Mutation removeNotification!: any;

  @store.Getter item!: Contact;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;

  closeModal() {
    this.$router.push({ name: 'ContactsList' });
  }

  async submitForm() {
    try {
      await this.submit();
      this.addNotification({
        color: 'success',
        message: 'Contact enregistré',
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la création du contact',
      });
    }
  }
}
