import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import ItemNavigation from '@/components/ItemNavigation.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsTagChip from 'qs_vuetify/src/components/Tags/QsTagChip.vue';
import QsTagsAutocomplete from 'qs_vuetify/src/components/Tags/QsTagsAutocomplete.vue';
import QsTextField from 'qs_vuetify/src/components/Fields/QsTextField.vue';

import {
  Contact,
  Email,
  FormDefinitionTemplate,
  PersistedContact,
  PersistedImport,
  PersistedImportable,
  PersistedTag,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';

import axios from 'qs_vuetify/src/plugins/axios';
import { FiltersDefinition, RelationQueryDefinition, RestParams } from 'qs_vuetify/src/types/states';

const contacts: any = namespace('contacts');
const global: any = namespace('global');
const imports: any = namespace('imports');
const importsView: any = namespace('importsView');
const importables: any = namespace('importables');
const importablesViews: any = namespace('importablesViews');
const tags: any = namespace('tags');

@Component({
  components: {
    ItemNavigation,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsRelationField,
    QsTagChip,
    QsTagsAutocomplete,
    QsTextField,
  },
  filters: {
    statusColor(status: string) {
      switch (status) {
        case 'failed':
          return 'error';
        case 'pending':
          return 'qs-light-blue';
        case 'completed':
          return 'success';

        default:
          return 'info';
      }
    },
  },
})
export default class Importables extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @Prop({ type: [String, Number], required: true }) importId!: string | number;

  @contacts.Getter('item') contact!: PersistedContact;
  @contacts.Mutation('item') syncContact!: any;

  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @imports.Getter availableFields!: string[];
  @imports.Getter('data') importsData!: PersistedImport[];
  @imports.Getter('error') importsError!: ErrorResponse;
  @imports.Getter('initialItem') importsInitialItem!: string;
  @imports.Getter item!: PersistedImport;
  @imports.Getter('form') importsForm!: Form;
  @imports.Getter('loading') importsLoading!: boolean;
  @imports.Getter slug!: string;
  @imports.Getter('total') importsTotal!: Form;
  @imports.Mutation setAvailableFields!: any;
  @imports.Mutation('item') syncImport!: any;

  @importsView.Getter('options') importsOptions!: DataTableOptions;
  @importsView.Getter('params') importsParams!: RestParams;
  @importsView.Mutation('options') setImportsOptions!: any;
  @importsView.Mutation('params') mutateImportsParams!: any;

  @importables.Getter('data') importablesData!: PersistedImportable[];
  @importables.Getter('error') importablesError!: ErrorResponse;
  @importables.Getter('filtersDefinition') importablesFiltersDefinition!: FiltersDefinition;
  @imports.Getter('initialItem') importablesInitialItem!: string;
  @importables.Getter('item') importablesItem!: PersistedImportable;
  @importables.Getter('form') importablesForm!: Form;
  @importables.Getter('loading') importablesLoading!: boolean;
  @importables.Getter('slug') importablesSlug!: string;
  @importables.Getter('total') importablesTotal!: number;
  @importables.Mutation('item') syncImportable!: any;

  @importablesViews.Getter('options') importablesOptions!: DataTableOptions;
  @importablesViews.Getter('params') importablesParams!: RestParams;
  @importablesViews.Mutation('options') setImportablesOptions!: any;
  @importablesViews.Mutation('params') mutateImportablesParams!: any;

  @tags.Getter('loading') tagsLoading!: boolean;

  setImportsParams = this.buildSetParam('imports', this.mutateImportsParams);
  setImportableParam = this.buildSetParam('importables', this.mutateImportablesParams);

  deleteImportsLoading = false;
  deleteImportsTagLoading = false;
  deletingImportsTagId: number | null = null;
  importableLoadingField: string | null = null;
  importImportableLoading: boolean = false;
  importLoadingField: string | null = null;
  parentRouteName!: string;
  putImportsFindContactIntervalLoading = false;
  putImportsFindContactLoading = false;
  putImportsFindContactProgress: number | null = null;
  putImportsImportIntervalLoading = false;
  putImportsImportLoading = false;
  putImportsImportProgress: number | null = null;
  putImportsTagLoading = false;
  restoreImportsLoading = false;

  metaFormDefinition: Omit<FormDefinitionTemplate, 'id'> = {
    allow_matching_on_id: {
      type: 'checkbox',
      rules: {
        boolean: true,
        required: true,
      },
    },
    allow_weak_matching: {
      type: 'checkbox',
      rules: {
        boolean: true,
        required: true,
      },
    },
  }

  tagsQueryDefinition: RelationQueryDefinition & { key: string } = {
    slug: 'tags',
    value: 'id',
    text: 'name',
    key: 'data',
    params: { fields: 'name' },
  }

  get importHasChanged(): boolean {
    return JSON.stringify(this.item) !== this.importsInitialItem;
  }

  get importableHasChanged(): boolean {
    return JSON.stringify(this.importablesItem) !== this.importablesInitialItem;
  }

  get importIsCompleted(): boolean {
    if (!this.item || !this.item.stats) {
      return false;
    }

    if (this.item.deleted_at) {
      return true;
    }

    return this.item.stats.completed === this.item.stats.total;
  }

  get jobIsLoading(): boolean {
    return this.putImportsFindContactLoading || this.putImportsImportLoading;
  }

  get showAlerts() {
    return this.$store.state.importsView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('importsView/showAlerts', val);
  }

  // eslint-disable-next-line class-methods-use-this
  canContactBeCreated(item: Contact | null = null) {
    if (!item) {
      return false;
    }

    const contactAlreadyExists: boolean = !!item.id;

    const hasName: boolean = !!item.first_name && !!item.last_name;
    const hasEmail: boolean = item.emails && item.emails.length > 0;
    const hasAnExistingEmail: boolean = item.emails.filter((e: Email) => !!e.id).length > 0;
    const hasPhoneNumber: boolean = !!item.home_phone
      && (item.home_phone.replace(/\D/g, '').length === 10
      || (
        item.home_phone.replace(/\D/g, '').length <= 11
        && parseInt(item.home_phone.replace(/\D/g, '')[0], 10) === 1
      ));

    if (contactAlreadyExists && hasAnExistingEmail) {
      /**
       * Tenter de créer un tel contact résulterait forcément en une erreur
       * puisque les courriels appartiennent déjà au contact.
       * Idéalement, il faudrait afficher une alerte au user lui suggérant
       * d'aller supprimer les doublons.
       */
      return false;
    }

    const canBeAssignedADistrict: boolean = !!item.postal_code;
    const canBeContacted: boolean = hasEmail || hasPhoneNumber;
    const canBeContactedAndIdentified: boolean = hasName && canBeContacted;
    const canBeMatchedAgainstElectoralList: boolean = hasName && item.birthdate;

    const isHumanGuessable: boolean = hasName && canBeAssignedADistrict;

    if (canBeContactedAndIdentified || canBeMatchedAgainstElectoralList || isHumanGuessable) {
      return true;
    }

    return false;
  }

  async createContact() {
    await this.$store.dispatch('contacts/createItem', { fields: 'id' });
    this.updateContact(this.contact);
  }

  async patch(
    slug: string,
    id: number | string,
    data: { [key: string]: any },
    callback: Function,
  ): Promise<void> {
    try {
      await axios.put(`/${slug}/${id}`, data);
      this.addNotification({
        color: 'success',
        message: 'Modifications enregistrées',
        timeout: 2500,
      });
      this.reloadDataRoutesData([slug]);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de l\'enregistrement',
        timeout: 2500,
      });
    } finally {
      callback();
    }
  }

  // TODO: store-ifier tout ce qui suit
  // Import
  async deleteImports(): Promise<void> {
    if (this.importId && this.importId !== 'new') {
      this.deleteImportsLoading = true;
      this.setActions();

      try {
        await axios.delete(`imports/${this.importId}`);
        this.addNotification({
          message: "L'import a été archivé.",
          color: 'success',
        });
        this.$router.push({ name: this.parentRouteName });
      } catch (e) {
        this.addNotification({
          message: "Erreur lors de l'archivage de l'import.",
          color: 'error',
        });
      } finally {
        this.deleteImportsLoading = false;
        this.setActions();
      }
    }
  }

  async getContact() {
    await this.$store.dispatch('contacts/retrieve', {
      id: this.importablesItem.data.id,
      ...this.viewParams.contacts,
    });
  }

  async optionsImports(importable_type: string) {
    if (!importable_type) {
      return;
    }

    try {
      const ajax = axios.options('imports', {
        params: {
          importable_type,
        },
      });

      const { data: { item } } = await ajax;

      this.setAvailableFields(Object.keys(item.fields_index));

      this.syncImport({
        ...item,
        importable_type,
      });
    } catch (e) {
      this.addNotification({
        message: e.message,
        color: 'error',
      });
      this.$router.push({ name: this.parentRouteName });
    }
  }

  async patchImport(field: string): Promise<void> {
    if (this.item && this.item.id && this.importHasChanged) {
      this.importLoadingField = field;
      this.patch(
        'imports',
        this.item.id,
        { [field]: this.item[field] },
        () => { this.importLoadingField = null; },
      );
    }
  }

  async putImportsFindContact(): Promise<void> {
    if (this.importId && this.importId !== 'new') {
      this.putImportsFindContactLoading = true;
      this.putImportsFindContactProgress = null;

      try {
        this.addNotification({
          message: 'La recherche des contacts a débuté et peut prendre plusieurs minutes.',
          color: 'success',
        });

        const { data: { id } } = await axios.put(`imports/${this.importId}/find_contacts`);
        this.watchFindContactJobProgress(id);
      } catch (e) {
        this.putImportsFindContactLoading = false;
        this.addNotification({
          message: 'Erreur lors de la recherche de contacts.',
          color: 'error',
        });
      }
    }
  }

  async putImportsImport(): Promise<void> {
    if (this.importId && this.importId !== 'new') {
      this.putImportsImportLoading = true;
      this.putImportsImportProgress = null;

      try {
        this.addNotification({
          message: "L'import a débuté et peut prendre plusieurs minutes à compléter.",
          color: 'success',
        });

        const { data: { id } } = await axios.put(`imports/${this.importId}/import`);
        this.watchImportJobProgress(id);
      } catch (e) {
        this.addNotification({
          message: 'Erreur lors de l\'importation',
          color: 'error',
        });
      }
    }
  }

  // Importables
  async confirmAndDeleteImportable(item: PersistedImportable): Promise<void> {
    this.confirm(
      '',
      'Êtes-vous certain·e de vouloir <strong>archiver cet importable</strong>?',
      async () => {
        try {
          await axios.delete(`importables/${item.id}`);
          this.addNotification({
            message: "L'importable a été archivé.",
            color: 'success',
          });
          this.reloadDataRoutesData(['importables']);
        } catch (e) {
          this.addNotification({
            message: "Erreur lors de l'archivage de l'importable.",
            color: 'error',
          });
        }
      },
      'error',
      'mdi-alert',
    );
  }

  async restoreImportable(item: PersistedImportable): Promise<void> {
    try {
      await axios.put(`importables/${item.id}/restore`);
      this.addNotification({
        message: "L'importable a été restauré.",
        color: 'success',
      });
      this.reloadDataRoutesData(['importables']);
    } catch (e) {
      this.addNotification({
        message: "Erreur lors de la restauration de l'importable.",
        color: 'error',
      });
    }
  }

  async putImportImportable(item: PersistedImportable): Promise<void> {
    this.importImportableLoading = true;
    try {
      await axios.put(
        `/imports/${item.import_id}/importables/${item.id}/import`,
      );

      this.$store.commit('global/addNotification', {
        color: 'success',
        message: 'Importé avec succès',
        timeout: 2500,
      });

      this.reloadDataRoutesData([
        'importables.retrieve',
        'importables.index',
        'imports.retrieve',
      ]);
    } catch (e) {
      this.$store.commit('global/addNotification', {
        color: 'error',
        message: `Erreur: ${e.getMessage()}`,
        timeout: 2500,
      });
    }
    this.importImportableLoading = false;
  }

  // Tags
  async deleteImportsTag(tag: PersistedTag): Promise<void> {
    if (tag && this.importId && this.importId !== 'new') {
      this.deleteImportsTagLoading = true;
      this.deletingImportsTagId = tag.id;

      try {
        await axios.delete(`imports/${this.importId}/tags/${tag.id}`);
        await this.reloadDataRoutesData(['imports.retrieve']);
        this.addNotification({
          message: `L'étiquette ${tag.name} a été supprimée`,
          color: 'success',
        });
      } catch (e) {
        this.addNotification({
          message: 'Erreur lors de la suppression de l\'étiquette',
          color: 'error',
        });
      } finally {
        this.deleteImportsTagLoading = false;
        this.deletingImportsTagId = null;
      }
    }
  }

  async putImportsTag(tag: PersistedTag): Promise<void> {
    if (tag && this.importId && this.importId !== 'new') {
      this.putImportsTagLoading = true;

      try {
        await axios.put(`imports/${this.importId}/tags/${tag.id}`, tag);
        await this.reloadDataRoutesData(['imports.retrieve']);
        this.addNotification({
          message: `L'étiquette ${tag.name} a été ajoutée`,
          color: 'success',
        });
      } catch (e) {
        this.addNotification({
          message: "Erreur lors de l'ajout de l'étiquette",
          color: 'error',
        });
      } finally {
        this.putImportsTagLoading = false;
      }
    }
  }

  async saveContact() {
    if (this.importablesItem?.data?.id) {
      try {
        await this.$store.dispatch('contacts/updateItem', {
          ...this.$store.getters['contacts/params'],
          ...this.viewParams.contacts,
        });
        await this.saveImportable();
      } catch (e) {
        this.$store.commit('contacts/error', e);
      }
    }
  }

  async saveImportable() {
    try {
      await this.$store.dispatch('importables/updateItem', {
        ...this.params,
      });
    } catch (e) {
      this.$store.commit('importables/error', e);
    }
  }

  async updateContact(contact: Contact | null) {
    if (contact) {
      this.importablesItem.data.id = contact.id;
      this.getContact();
    } else {
      this.importablesItem.data.id = null;
      this.$store.dispatch('contacts/loadEmpty');
    }

    await this.saveImportable();
  }

  setActions() {
    if (!this.item || !this.item.id) {
      return;
    }

    if (this.userHas('IMPORTS_UPDATE')) {
      if (this.item.deleted_at) {
        this.$store.commit('global/actions', [
          {
            onClick: async () => {
              this.restoreImportsLoading = true;
              this.setActions();

              try {
                await this.$store.dispatch('imports/restore', { id: this.item.id });
                await this.reloadDataRoutesData();
              } finally {
                this.restoreImportsLoading = false;
                this.setActions();
              }
            },
            color: 'success',
            loading: this.restoreImportsLoading,
            icon: 'mdi-archive-refresh',
            tooltip: 'Restaurer',
          },
        ]);
        return;
      }

      this.$store.commit('global/actions', [
        {
          onClick: this.submitForm,
          color: 'primary',
          disabled: !this.importHasChanged,
          icon: '$qs-save',
          loading: this.importsLoading,
          tooltip: 'Enregistrer',
        },
        {
          onClick: this.putImportsFindContact,
          color: 'success',
          disabled: !this.item || this.importHasChanged || this.putImportsImportLoading || this.importIsCompleted,
          icon: 'mdi-account-search',
          loading: this.putImportsFindContactLoading,
          progress: this.putImportsFindContactProgress,
          tooltip: 'Rechercher les contacts',
        },
        {
          onClick: this.putImportsImport,
          color: 'warning',
          disabled: !this.item || this.importHasChanged
            || this.importIsCompleted || this.putImportsFindContactLoading,
          icon: 'mdi-refresh',
          loading: this.putImportsImportLoading,
          progress: this.putImportsImportProgress,
          tooltip: 'Démarrer l\'importation',
        },
        {
          onClick: this.deleteImports,
          color: 'error',
          disabled: !this.item,
          icon: 'mdi-archive',
          loading: this.deleteImportsLoading,
          tooltip: 'Archiver',
        },
      ]);
    }
  }

  setup() {
    if (this.item) {
      if (this.item.id) {
        this.$store.commit(
          'global/title',
          this.item.name || `Importation #${this.item.id}`,
        );
      } else {
        this.$store.commit('global/title', 'Nouvelle importation');
      }
    } else {
      this.$store.commit(
        'global/title',
        'Chargement...',
      );
    }
    this.$store.commit('global/subtitle', 'Importations');
    this.$emit('updateHead');

    this.setActions();
  }

  submitForm() {
    this.submit();
    this.setActions();
  }

  @Watch('importHasChanged')
  onImportablesImportHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  onImportableItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setup();
    }
  }

  @Watch('putImportsFindContactLoading')
  onImportablePutImportsFindContactLoadingChanged() {
    this.setActions();
  }

  @Watch('putImportsImportLoading')
  onImportablePutImportsImportLoadingChanged() {
    this.setActions();
  }

  @Watch('$route', { deep: true })
  onImportableRouteChanged() {
    this.setup();
  }

  @Watch('routeDataLoaded')
  onImportableRouteDataLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setup();

      if (this.item.find_contact_job_batch_id) {
        this.watchFindContactJobProgress(this.item.find_contact_job_batch_id);
      }

      if (this.item.import_job_batch_id) {
        this.watchImportJobProgress(this.item.import_job_batch_id);
      }
    }
  }

  private watchFindContactJobProgress(id: string) {
    this.putImportsFindContactLoading = true;
    this.putImportsFindContactProgress = null;

    const putImportsFindContactInterval = setInterval(async () => {
      if (this.putImportsFindContactIntervalLoading) {
        return;
      }

      this.putImportsFindContactIntervalLoading = true;
      const { data: { progress } } = await axios.get(`batches/${id}`);
      this.putImportsFindContactIntervalLoading = false;

      this.putImportsFindContactProgress = progress;
      this.setActions();

      if (progress === 100) {
        clearInterval(putImportsFindContactInterval);
        this.putImportsFindContactLoading = false;
        this.putImportsFindContactProgress = null;
        this.addNotification({
          message: 'La recherche des contacts est terminée.',
          color: 'success',
        });
        this.reloadDataRoutesData(['imports', 'importables']);
      }
    }, 1000);
  }

  private watchImportJobProgress(id: string) {
    this.putImportsImportLoading = true;
    this.putImportsImportProgress = null;

    const putImportsImportInterval = setInterval(async () => {
      if (this.putImportsImportIntervalLoading) {
        return;
      }

      this.putImportsImportIntervalLoading = true;
      const { data: { progress } } = await axios.get(`batches/${id}`);
      this.putImportsImportIntervalLoading = false;

      this.putImportsImportProgress = progress;
      this.setActions();

      if (progress === 100) {
        clearInterval(putImportsImportInterval);
        this.putImportsImportLoading = false;
        this.putImportsImportProgress = null;
        this.addNotification({
          message: "L'importation des contacts est terminée.",
          color: 'success',
        });
        this.reloadDataRoutesData(['imports', 'importables']);
      }
    }, 1000);
  }
}
