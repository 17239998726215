var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "duplicates-form",
        dark: false,
        height: _vm.isNew ? null : "90vh",
        maxWidth: _vm.isNew ? "600" : "1000",
        value: true
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "Duplicates" })
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.itemReady
            ? {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isNew ? "Nouveau doublon" : "Modifier un doublon"
                        ) +
                        " "
                    )
                  ]
                },
                proxy: true
              }
            : {
                key: "title",
                fn: function() {
                  return [_vm._v(" Chargement... ")]
                },
                proxy: true
              },
          _vm.itemReady && !_vm.isNew
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm._v(" Consigné comme "),
                    _c("span", {
                      staticClass: "ml-1",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.item.is_duplicate
                            ? "<strong>étant</strong> un doublon"
                            : "<strong>n'étant pas</strong> un doublon"
                        )
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      disabled: !_vm.itemReady,
                      loading: _vm.loading,
                      theme: "labase"
                    },
                    on: { click: _vm.submit }
                  },
                  [_vm._v(" Enregistrer ")]
                ),
                _vm.item && _vm.item.is_duplicate && _vm.item.moderated_at
                  ? _c(
                      "qs-button",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          color: "error",
                          disabled: !_vm.itemReady || !!_vm.item.merged_at,
                          theme: "labase"
                        },
                        on: {
                          click: function($event) {
                            return _vm.confirmAndMergeDuplicates(_vm.item.id)
                          }
                        }
                      },
                      [_vm._v(" Fusionner ")]
                    )
                  : _vm._e(),
                _c(
                  "qs-confirmation-modal",
                  _vm._b(
                    {
                      on: {
                        "click:cancel": function($event) {
                          _vm.dialog.value = false
                        },
                        "click:confirm": _vm.dialog.callback
                      }
                    },
                    "qs-confirmation-modal",
                    _vm.dialog,
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.item && _vm.itemReady && _vm.isNew
        ? _c("qs-form-builder", {
            attrs: {
              "model-name": _vm.slug,
              error: _vm.error,
              form: _vm.form,
              order: [
                "duplicate_contact_id",
                "original_contact_id",
                "is_duplicate"
              ],
              item: _vm.item
            },
            on: { input: _vm.syncItem },
            scopedSlots: _vm._u(
              [
                {
                  key: "duplicate_contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "duplicate_contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "original_contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "original_contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              2431806985
            )
          })
        : _vm.itemReady && !_vm.isNew
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8" } },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.item.reciprocal
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        border: "left",
                                        color: "error",
                                        icon: "mdi-alert",
                                        outlined: "",
                                        tile: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "L'inverse de ce doublon a été consigné!"
                                                )
                                              ]
                                            ),
                                            _vm.item.original_contact.id <
                                            _vm.item.duplicate_contact.id
                                              ? _c(
                                                  "p",
                                                  { staticClass: "body-2" },
                                                  [
                                                    _vm._v(
                                                      " Entre ce doublon-ci et l'autre qui a été consigné, "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v("celui-ci")
                                                    ]),
                                                    _vm._v(
                                                      " devrait être conservé en priorité, puisqu'il préserve le contact le plus ancien. "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "p",
                                                  { staticClass: "body-2" },
                                                  [
                                                    _vm._v(
                                                      " Entre ce doublon-ci et l'autre qui a été consigné, "
                                                    ),
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name:
                                                              "DuplicatesDialog",
                                                            params: {
                                                              id:
                                                                _vm.item
                                                                  .reciprocal.id
                                                            }
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v("l'autre")
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " devrait être conservé en priorité, puisqu'il préserve le contact le plus ancien. "
                                                    )
                                                  ],
                                                  1
                                                ),
                                            _c("p", { staticClass: "body-2" }, [
                                              _c("strong", [
                                                _vm._v("Avant de procéder")
                                              ]),
                                              _vm._v(
                                                ", assurez-vous que ce contact n'a pas d'autres fiches. "
                                              )
                                            ])
                                          ]),
                                          _c("v-spacer"),
                                          _c("qs-button", {
                                            attrs: {
                                              color: "error",
                                              icon: "mdi-magnify",
                                              label: "Voir l'autre doublon",
                                              theme: "labase",
                                              to: {
                                                name: "DuplicatesDialog",
                                                params: {
                                                  id: _vm.item.reciprocal.id
                                                }
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "ContactsForm",
                                          params: {
                                            id: _vm.item.duplicate_contact.id
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c("qs-contact-list-item", {
                                        attrs: {
                                          item: _vm.item.duplicate_contact
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        to: {
                                          name: "ContactsForm",
                                          params: {
                                            id: _vm.item.original_contact.id
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c("qs-contact-list-item", {
                                        attrs: {
                                          item: _vm.item.original_contact
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("qs-moderation-form", {
                        attrs: {
                          form: _vm.contactDuplicatesDialog,
                          "left-label": "Contact doublon",
                          "left-value": _vm.item.duplicate_contact,
                          "model-name": "contacts",
                          "right-label": "Contact à conserver",
                          "right-value": _vm.item.original_contact,
                          "show-missing-fields": true
                        },
                        on: {
                          "update:leftValue": function($event) {
                            return _vm.$set(
                              _vm.item,
                              "duplicate_contact",
                              $event
                            )
                          },
                          "update:left-value": function($event) {
                            return _vm.$set(
                              _vm.item,
                              "duplicate_contact",
                              $event
                            )
                          },
                          "update:rightValue": function($event) {
                            return _vm.$set(
                              _vm.item,
                              "original_contact",
                              $event
                            )
                          },
                          "update:right-value": function($event) {
                            return _vm.$set(
                              _vm.item,
                              "original_contact",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center pl-4" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "qs-button",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "info",
                                              loading: _vm.moderating,
                                              theme: "labase"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(" Modération "),
                                          _c("v-icon", [
                                            _vm._v("mdi-menu-down")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  !_vm.item.moderated_at ||
                                  !_vm.item.is_duplicate
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.confirmAndMergeDuplicates(
                                                _vm.item.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Modérer comme vrai doublon et fusionner immédiatement "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.item.moderated_at ||
                                  !_vm.item.is_duplicate
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.moderateDuplicate(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Modérer comme vrai doublon et fusionner plus tard "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.item.moderated_at ||
                                  _vm.item.is_duplicate
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.moderateDuplicate(
                                                false
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Modérer comme faux doublon ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("duplicates-interactions", {
                        attrs: { item: _vm.item }
                      }),
                      _c(
                        "div",
                        { staticClass: "pl-4" },
                        [
                          _c("v-textarea", {
                            attrs: {
                              filled: "",
                              "hide-details": "",
                              label: "Ajouter un commentaire"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("qs-button", {
                                      attrs: {
                                        color: "info",
                                        icon: "mdi-comment-plus",
                                        label: "Enregistrer",
                                        theme: "labase"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.postComment(_vm.comment)
                                        }
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.comment,
                              callback: function($$v) {
                                _vm.comment = $$v
                              },
                              expression: "comment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c("v-progress-circular", {
                  attrs: { color: "deep-orange", indeterminate: "" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }