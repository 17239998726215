



























































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsTagsCard from 'qs_vuetify/src/components/Tags/QsTagsCard.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import LazyListMixin from 'qs_vuetify/src/mixins/LazyListMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import TagsListActions from 'qs_vuetify/src/mixins/models/TagsListActions';

import { PersistedTag } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RelationQueryDefinition, RestParams } from 'qs_vuetify/src/types/states';

import ConfirmDialog from '@/components/ConfirmDialog.vue';
import BaseLayout from '@/components/Layout/BaseLayout.vue';

import Selectable from '@/mixins/Selectable';

const global: any = namespace('global');
const store: any = namespace('tags');
const view: any = namespace('tagsView');

@Component({
  components: {
    BaseLayout,
    QsActionModal,
    QsConfirmationModal,
    QsFilters,
    QsList,
    QsRelationField,
    QsTagsCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Tags extends mixins(
  AuthenticationMixin,
  ConfirmDialog,
  DataRouteGuards,
  LazyListMixin,
  ListMixin,
  Selectable,
  TagsListActions,
) {
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @store.Getter data!: PersistedTag[];
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedTag[];
  @view.Mutation('params') setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  filtersLoaded = true;

  tagQueryDef: RelationQueryDefinition & { key: string } = {
    key: 'data',
    params: { fields: '*' },
    slug: 'tags',
    text: 'name',
    value: 'id',
  }

  get partialFiltersDefinition(): FiltersDefinition {
    const partialFiltersDefinition = {
      ...this.filtersDefinition,
    };

    delete partialFiltersDefinition.child_of;
    delete partialFiltersDefinition.parent_of;

    return partialFiltersDefinition;
  }

  get selectedItemsWithInstanceName() {
    return this.selectedItems.map((t) => ({
      id: t.id,
      name: `${t.name} (${t.instance?.name || 'Nationale'})`,
    }));
  }

  mounted() {
    this.setAction();
    this.resetActionsData();

    if (this.data.length === 0) {
      this.loadFirstPage();
    }
  }

  confirmThenMerge(children: PersistedTag[], target: PersistedTag) {
    this.confirmList(
      '',
      'Cette action est irréversible. Voulez-vous continuer?',
      'error',
      'mdi-alert',
      async () => {
        await this.mergeTags(children, target);
      },
    );
  }

  setAction() {
    if (this.userHas('TAGS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/tags/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  @Watch('routeDataLoaded')
  onTagsRouteDataLoadedChanged(loaded: boolean) {
    if (loaded && this.data.length === 0 && this.params.page !== 1) {
      this.loadFirstPage();
    }
  }

  @Watch('$route', { deep: true })
  onTagsRouteChanged() {
    this.setAction();
  }
}
