































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsUserChip from 'qs_vuetify/src/components/QsUserChip.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import axios from 'qs_vuetify/src/plugins/axios';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Event, Filter, PersistedEvent } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import EventAdminPopover from '@/components/EventAdminPopover.vue';
import EventsVisibilityIndicator from '@/components/EventsVisibilityIndicator.vue';
import QsStatsCard from '@/components/QsStatsCard.vue';

const events: any = namespace('events');
const filters: any = namespace('filters');
const view: any = namespace('eventsViews');

@Component({
  components: {
    EventAdminPopover,
    EventsVisibilityIndicator,
    QsBaseLayout,
    QsBooleanIndicator,
    QsDataTable,
    QsFilters,
    QsStatsCard,
    QsUserChip,
  },
})
export default class EventsList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ListMixin,
) {
  @events.Getter data!: Array<Event>;
  @events.Getter filtersDefinition!: FiltersDefinition;
  @events.Getter loading!: boolean;
  @events.Getter total!: number;
  @events.Getter slug!: string;

  @filters.Getter('data') eventsFilters!: Filter[];

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  get viewParams(): { [key: string]: RestParams } {
    return {
      events: ListMixin.buildListState(this.options, this.params),
    };
  }

  setEventsFilter(filter: RestParams) {
    if (!this.routeDataLoaded) {
      return;
    }

    const params: RestParams = {};

    const blankFilter = Object.keys(this.params).reduce((acc, key: string) => {
      if (key in this.filtersDefinition) {
        return acc;
      }
      acc[key] = this.params[key];

      return acc;
    }, params);
    if (Object.keys(filters).length < 1) {
      this.setParams(blankFilter);
    } else {
      this.setParams({
        ...blankFilter,
        ...filter,
      });
    }
  }

  setEventsParams = this.buildSetParam('events', this.setParams);

  headers = [
    { text: 'Visibilité', value: 'visibility' },
    { text: 'Organisé par', value: 'instances.name' },
    { text: 'Infos', value: 'title' },
    { text: 'Début', value: 'start_at' },
    { text: 'Approuvé', value: 'is_approved' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  // eslint-disable-next-line class-methods-use-this
  isSameDayEvent(item: PersistedEvent): boolean {
    if (item) {
      return this.$dayjs(item.start_at).isSame(this.$dayjs(item.end_at), 'day');
    }

    return false;
  }

  reload() {
    this.reloadDataRoutesData(['events.index'], true);
  }

  async toggleIsApproved(item: PersistedEvent) {
    await axios.put(`/events/${item.id}`, {
      is_approved: !item.is_approved,
    });

    this.reload();
  }
}
