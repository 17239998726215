import contactsView from './contactsView';
import importsViewState from './importsViewState';
import transactionsView from './transactionsView';

const duplicatesFields = [
  [
    'address',
    'age',
    'apartment',
    'birthdate',
    'city',
    'district.name',
    'email',
    'emails',
    'first_name',
    'full_name',
    'home_phone',
    'gender',
    'last_name',
    'postal_code',
    'searchable_text',
    'status',
    'v1_contact_id',
  ].map((i) => `duplicate_contact.${i}`),
  [
    'address',
    'age',
    'apartment',
    'birthdate',
    'city',
    'district.name',
    'email',
    'emails',
    'first_name',
    'full_name',
    'home_phone',
    'gender',
    'last_name',
    'postal_code',
    'searchable_text',
    'status',
    'v1_contact_id',
  ].map((i) => `original_contact.${i}`),
  [
    'created_at',
    'created_by_user',
    'text',
  ].map((i) => `comments.${i}`),
  'created_at',
  'created_by_user.contact_name',
  'is_duplicate',
  'merged_at',
  'merged_by_user.contact_name',
  'moderated_at',
  'moderated_by_user.contact_name',
  [
    'is_duplicate',
    'moderated_at',
  ].flat().map((i) => `reciprocal.${i}`),
  'updated_at',
  'updated_by_user.contact_name',
].flat().join(',');

const states = {
  contactsView,
  importsViewState,
  transactionsView,
};

export default states;

export { duplicatesFields, importsViewState };
