















// 8460, rue Saint-Urbain
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import Context from '@/components/sms/Context.vue';
import Messenger from '@/components/sms/Messenger.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import { SmsCampaign } from 'qs_vuetify/src/types/models';

const contacts: any = namespace('contacts');
const sent_sms: any = namespace('sent_sms');
const sms_campaigns: any = namespace('sms_campaigns');

@Component({
  components: {
    Context,
    Messenger,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ConversationReplies extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
) {
  @contacts.Actions('update') contactUpdate!: (payload: any) => void;

  @sent_sms.Getter item!: any;
  @sent_sms.Getter loading!: boolean;
  @sent_sms.Getter total!: number;
  @sent_sms.Getter slug!: string;
  @sms_campaigns.Getter('item') smsCampaign!: SmsCampaign | null;

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      // this.onRouteChanged();
      this.setGlobalTitle();
    }
  }

  setGlobalTitle() {
    this.$store.commit('global/title', 'SMS');
    this.$emit('updateHead');
  }
}
