





















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

import QsBaseLayout from 'qs_vuetify/src/components/Layout/QsBaseLayout.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsStatsCard from '@/components/QsStatsCard.vue';

import { PersistedPhone } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';

const phones: any = namespace('phones');
const view: any = namespace('phonesViews');

@Component({
  components: {
    QsBaseLayout,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    QsStatsCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class PhonesList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  InteractsWithNavbar,
  ListMixin,
) {
  @phones.Getter data!: PersistedPhone[];
  @phones.Getter exportUrl!: string;
  @phones.Getter filtersDefinition!: FiltersDefinition;
  @phones.Getter loading!: boolean;
  @phones.Getter total!: number;
  @phones.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedPhone[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  setListParam = this.buildSetParam('phones', this.setParams);

  filtersOrder = [
    'phone_number',
    'status',
    'type',
  ];

  headers = [
    { text: 'Id', value: 'id', sortable: true },
    { text: 'Numéro', value: 'oqlf_format', sortable: false },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Contacts', value: 'contacts_count', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      phones: ListMixin.buildListState(this.options, this.params),
    };
  }

  mounted() {
    this.setGlobalActions();

    this.$store.commit('phones/exportFields', [
      'id',
      'phone_number',
      'oqlf_format',
      'type',
      'status',
      'contacts_count',
    ]);
  }

  reloadRoutesData() {
    this.reloadDataRoutesData(['phones']);
  }

  setGlobalActions() {
    if (this.userHas('PHONES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/phones/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }
}
