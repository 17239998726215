import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { DataCollections } from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import {
  buildRestRoutes,
} from 'qs_vuetify/src/store/buildBaseRoute';

import i18n from 'qs_vuetify/src/plugins/i18n';
import { capitalize } from 'qs_vuetify/src/plugins/filters';

import { MenuItem } from 'qs_vuetify/src/types/components';
import { Model } from 'qs_vuetify/src/types/models';

import store from '../store';

import duplicateRoutes from './duplicates';
import importRoutes from './imports';
import otherRoutes from './others';
import smsRoutes from './sms';
import transactionRoutes from './transactions';

const ContactsForm = () => import('@/views/Contacts/ContactsForm.vue');
const ContactsList = () => import('@/views/Contacts/ContactsList.vue');
const DelegationReport = () => import('@/views/Reports/DelegationReport.vue');
const EventsForm = () => import('@/views/Events/EventsDialog.vue');
const EventsList = () => import('@/views/Events/EventsList.vue');
const Home = () => import('@/views/Home.vue');
const MailboxOptions = () => import('@/views/Reports/MailboxOptions.vue');
const NewContactsForm = () => import('@/views/Contacts/NewContactsForm.vue');
const RevisionsList = () => import('@/views/Revisions/RevisionsList.vue');
const RevisionsForm = () => import('@/views/Revisions/RevisionsForm.vue');
const Sandbox = () => import('@/views/Sandbox.vue');

declare module 'vue-router/types/router' {
  interface Route {
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }

  interface _RouteConfigBase {
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }
}

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      data: {},
      title: 'Accueil',
      menus: ['primary'],
    },
  },
  ...importRoutes,
  ...transactionRoutes,
  ...otherRoutes,
  ...smsRoutes,
  {
    path: '/contacts',
    name: 'ContactsList',
    component: ContactsList,
    meta: {
      data: {
        contacts: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.contacts.lastLoadedAt;
            },
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.contactsView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.contacts.name', 2)),
      menus: ['primary'],
      requires: ['CONTACTS_RETRIEVE'],
    },
    children: [
      {
        path: 'new',
        name: 'NewContactsForm',
        component: NewContactsForm,
        props: () => ({ id: 'new' }),
        meta: {
          data: {
            contacts: {
              loadEmpty: {},
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contacts.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.contactsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
            },
          },
          title: `${capitalize(i18n.tc('models.contacts.new', 1))} ${i18n.tc('models.contacts.name', 1)}`,
          requires: ['CONTACTS_CREATE'],
        },
      },
    ],
  },
  {
    path: '/contacts/:id',
    name: 'ContactsForm',
    component: ContactsForm,
    props: true,
    meta: {
      data: {
        contacts: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            params: {
              fields: '*',
            },
          },
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.contacts.lastLoadedAt;
            },
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.contactsView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.contacts.name', 1)),
      requires: ['CONTACTS_RETRIEVE'],
    },
  },
  ...duplicateRoutes,
  ...buildRestRoutes({
    dataActions: {
      params: {
        fields: [
          'description',
          'end_at',
          'instances.name',
          'is_approved',
          'location_name',
          'location.google_place_address',
          'organized_by_user.contact_name',
          'start_at',
          'title',
          'visibility',
        ].join(','),
      },
      retrieve: {
        params: {
          fields: [
            'accessibility',
            'accessibility_notes',
            'description',
            'duration',
            'end_at',
            'instances.name',
            'is_approved',
            'location_name',
            'organized_by_user.contact_name',
            'start_at',
            'status',
            'title',
            'visibility',
          ].join(','),
        },
      },
    },
    extraData: {
      instances: {
        index: {
          params: {
            fields: 'name',
            order: 'name',
            per_page: '*',
          },
        },
      },
      filters: {
        index: {
          params: {
            repository: 'Event',
            fields: '*',
          },
        },
      },
    },
    genericForm: EventsForm,
    list: EventsList,
    menus: ['moderation'],
    slug: 'events',
  }),
  ...buildRestRoutes({
    genericForm: RevisionsForm,
    list: RevisionsList,
    menus: ['moderation'],
    slug: 'revisions',
  }),
  {
    path: '/mailbox_options',
    name: 'MailboxOptions',
    component: MailboxOptions,
    meta: {
      data: {
        mailbox_options: {
          index: {
            params: {
              'tags.id': 7,
            },
          },
        },
      },
      menus: ['moderation'],
      title: 'Cartes de membre',
    },
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: Sandbox,
    meta: {
      data: {},
      menus: ['dev'],
      title: 'Bac-à-sable',
    },
  },
  {
    path: '/reports/delegations',
    name: 'DelegationReport',
    component: DelegationReport,
    meta: {
      auth: true,
      data: {
        instances: {
          index: {
            params: {
              fields: [
                'delegation_stats',
                'instance_type.name',
                'is_active',
                'name',
              ].join(','),
              'instance_type.id': '10,11',
              order: 'name',
              per_page: '*',
            },
          },
        },
        instance_types: {
          index: {
            params: {
              can_delegate: true,
              fields: 'name',
              per_page: '*',
            },
          },
        },
        regions: {
          index: {
            params: {
              fields: 'name',
              per_page: '*',
            },
          },
        },
      },
      menus: ['reports'],
      requires: ['INSTANCES_RETRIEVE'],
      title: 'Délégations',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('global/reset');
  next();
});

export default router;
