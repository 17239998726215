import { render, staticRenderFns } from "./AutomatedEmailTemplate.vue?vue&type=template&id=72122e7c&"
import script from "./AutomatedEmailTemplate.vue?vue&type=script&lang=ts&"
export * from "./AutomatedEmailTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./AutomatedEmailTemplate.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72122e7c')) {
      api.createRecord('72122e7c', component.options)
    } else {
      api.reload('72122e7c', component.options)
    }
    module.hot.accept("./AutomatedEmailTemplate.vue?vue&type=template&id=72122e7c&", function () {
      api.rerender('72122e7c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AutomatedEmailTemplates/AutomatedEmailTemplate.vue"
export default component.exports