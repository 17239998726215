var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "duplicates-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  filters: _vm.duplicateFilters,
                  "filters-definition": _vm.filtersDefinition,
                  flat: "",
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: {
                  "change:filter": _vm.setDuplicatesFilter,
                  input: _vm.setDuplicatesParam
                },
                scopedSlots: _vm._u([
                  {
                    key: "duplicate_contact.id.item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  },
                  {
                    key: "original_contact.id.item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  }
                ])
              }),
              _vm.total
                ? _c("qs-stats-card", {
                    staticClass: "mt-3 mb-3",
                    attrs: {
                      color: "blue",
                      label: "Correspondant au filtre",
                      icon: "mdi-sigma",
                      stat: _vm.total
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", lg: "9" } },
            [
              _c("v-alert", { attrs: { dismissible: "", type: "info" } }, [
                _vm._v(" Hachuré: Consigné comme "),
                _c("strong", [_vm._v("n'étant pas")]),
                _vm._v(" un doublon"),
                _c("br"),
                _vm._v(" Ligne jaune: Consigné mais non modéré"),
                _c("br"),
                _vm._v(" Ligne verte: Consigné et modéré ")
              ]),
              _c("qs-data-table", {
                attrs: {
                  actions: ["edit"],
                  flat: "",
                  headers: _vm.headers,
                  "item-class": _vm.getDataTableItemClass,
                  items: _vm.data,
                  "items-per-page-options": [8, 16, 30],
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  "set-selected": _vm.setSelectedItems,
                  "show-select": "",
                  tile: "",
                  total: _vm.total || 0
                },
                on: {
                  edit: function($event) {
                    return _vm.goto("duplicates", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.original_contact.full_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.original_contact
                          ? _c("qs-contact-list-item", {
                              attrs: { item: item.original_contact }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.duplicate_contact.full_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.duplicate_contact
                          ? _c("qs-contact-list-item", {
                              attrs: { item: item.duplicate_contact }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.is_duplicate",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.is_duplicate }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.interactions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          { attrs: { color: "qs-blue" } },
                                          on
                                        ),
                                        [_vm._v("mdi-information")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("duplicates-interactions", {
                                  attrs: { item: item }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("qs-button", {
                              attrs: {
                                color: "info",
                                icon: "mdi-pencil",
                                label: "Modifier",
                                small: "",
                                theme: "labase",
                                to: {
                                  name: "DuplicatesDialog",
                                  params: { id: item.id }
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.params.id
        ? _c("router-view", { attrs: { id: _vm.$route.params.id } })
        : _vm._e(),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.listDialog.value = false
              },
              "click:confirm": _vm.listDialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.listDialog,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }