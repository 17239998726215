import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import { SentSms } from '@/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { SentSmsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import axios from 'qs_vuetify/src/plugins/axios';

const retrieveParams = {
  fields: [
    'body',
    'contact.first_name',
    'contact.last_name',
    'contact.apartment',
    'contact.address',
    'contact.city',
    'contact.postal_code',
    'created_at',
    'from',
    'sms_campaign.*',
    'sms_replies.*',
    'sent_at',
    'status',
    'to',
  ].join(','),
  order: '-unread_replies_count,-sent_at',
};

export const smsState: SentSmsRestState = {
  ...buildRestState<SentSms>('sent_sms', {}),
  markAsReadLoading: null,
  replyLoading: null,
  queuedTotal: 0,
};

export const getters: GetterTree<SentSmsRestState, RootState> = {
  ...buildRestGetters<SentSms, SentSmsRestState>(),
  markAsReadLoading(state: SentSmsRestState) {
    return state.markAsReadLoading;
  },
  replyLoading(state: SentSmsRestState) {
    return state.replyLoading;
  },
  queuedTotal(state: SentSmsRestState) {
    return state.queuedTotal;
  },
};

export const actions: ActionTree<SentSmsRestState, RootState> = {
  ...buildRestActions<SentSms, SentSmsRestState>(),
  async markAsRead({ commit, dispatch }, id) {
    commit('markAsReadLoading', id);
    const ajax = axios.put(`sent_sms/${id}/mark_as_read`);

    try {
      await ajax;
    } catch (error) {
      commit('error', error);
    } finally {
      dispatch('retrieve', { id });
      commit('markAsReadLoading', null);
    }
  },
  async reply({ commit, dispatch, state }, { id, body }) {
    commit('replyLoading', id);
    commit('item', {
      ...state.item,
      sms_replies: [
        ...state.item?.sms_replies,
        {
          body,
          created_at: Date.now(),
          from: state.item ? state.item.from : '+15147004904',
          id: null,
        },
      ],
    });
    const ajax = axios.post(`/sent_sms/${id}/reply`, {
      body,
    });

    try {
      await ajax;
    } catch (error) {
      commit('error', error);
    } finally {
      dispatch('retrieve', { id, ...retrieveParams });
      commit('replyLoading', null);
    }
  },
  async fetchQueuedTotal({ commit }) {
    const ajax = axios.get('/sent_sms', {
      params: {
        fields: 'id',
        status: 'queued',
        per_page: 1,
      },
    });

    try {
      const { data: { total } } = await ajax;
      commit('queuedTotal', total);
    } catch (error) {
      commit('error', error);
    }
  },
};

export const mutations: MutationTree<SentSmsRestState> = {
  ...buildRestMutations<SentSms, SentSmsRestState>(),
  markAsReadLoading(state: SentSmsRestState, payload: number | null) {
    state.markAsReadLoading = payload;
  },
  replyLoading(state: SentSmsRestState, payload: number | null) {
    state.replyLoading = payload;
  },
  queuedTotal(state: SentSmsRestState, payload: number) {
    state.queuedTotal = payload;
  },
};

export const sms: Module<SentSmsRestState, RootState> = {
  namespaced: true,
  state: smsState,
  getters,
  actions,
  mutations,
};
