












































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { ButtonProps, Form } from 'qs_vuetify/src/types/components';
import { PersistedAutomatedEmailTemplate, User } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

import ItemNavigation from '@/components/ItemNavigation.vue';

const auth: any = namespace('auth');
const global: any = namespace('global');
const store: any = namespace('automated_email_templates');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('automated_email_templates/item', null);
    next();
  },
  components: {
    ItemNavigation,
    QsButton,
    QsCard,
    QsConfirmationModal,
    QsFormBuilder,
    QsHtmlEditor,
  },
})
export default class MailCampaignForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @auth.Getter user!: User;

  @global.Mutation addNotification!: Function;

  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter initialItem!: string;
  @store.Getter item!: PersistedAutomatedEmailTemplate;
  @store.Getter('loading') loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  mounted() {
    this.setActions();
    this.setGlobalSubtitle();
  }

  async automatedEmailTemplateSendTest() {
    try {
      await this.$store.dispatch('automated_email_templates/sendTest', this.item.id);
      this.addNotification({
        color: 'success',
        message: `Le courriel a été envoyé à l'adresse ${this.user.email}.`,
      });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: this.error?.message || "Une erreur s'est produite lors de l'envoi du test.",
      });
    }
    this.dialog.value = false;
  }

  setActions() {
    const actions: Array<ButtonProps> = [];

    if (this.userHas('AUTOMATED_EMAIL_TEMPLATES_UPDATE')) {
      actions.push({
        onClick: async () => {
          this.dialog = {
            callback: () => this.automatedEmailTemplateSendTest(),
            color: 'info',
            icon: undefined,
            loading: false,
            message: 'Envoyer un courriel test à<br>'
            + `<span class="font-weight-bold">${this.user.email}</span>`,
            title: null,
            value: true,
          };
        },
        disabled: this.hasChanged,
        color: 'warning',
        icon: 'mdi-email-search',
        tooltip: 'Envoyer un courriel test',
      });

      actions.push({
        onClick: this.submit,
        color: 'primary',
        disabled: !this.hasChanged || this.loading,
        icon: '$qs-save',
        tooltip: 'Enregistrer',
      });
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  @Watch('hasChanged')
  onHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  onItemReadyChanged() {
    this.setGlobalSubtitle();
    this.setActions();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged() {
    this.setGlobalSubtitle();
    this.setActions();
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
  }
}
