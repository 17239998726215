var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "admin-popover",
    { staticClass: "mr-2" },
    [
      _vm.item
        ? _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("span", { staticClass: "body-1 font-weight-bold" }, [
                  _vm._v("Transaction #" + _vm._s(_vm.item.id))
                ]),
                _c("v-spacer"),
                _c("span", { staticClass: "pt-1 ml-12 body-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("status")(_vm.item.status, "transactions")
                      ) +
                      " "
                  )
                ])
              ],
              1
            ),
            _c("div", { staticClass: "caption" }, [
              _c("span", [
                _vm._v(_vm._s(_vm._f("datetime")(_vm.item.executed_at)))
              ]),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("enumVal")(_vm.item.type, "transactions", "type")
                      )
                    )
                  ]),
                  _c("v-spacer"),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.item.amount_in_cad)))
                  ])
                ],
                1
              )
            ]),
            _c("p", { staticClass: "body-2 pt-2" }, [
              _c("span", [_vm._v(" " + _vm._s(_vm.item.external_id) + " ")])
            ])
          ])
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: {
            block: "",
            text: "",
            tile: "",
            to: { name: "TransactionsForm", params: { id: _vm.item.id } },
            small: ""
          }
        },
        [_vm._v(" Voir la fiche ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }