import { render, staticRenderFns } from "./QsLargeChoice.vue?vue&type=template&id=75c3143a&"
import script from "./QsLargeChoice.vue?vue&type=script&lang=ts&"
export * from "./QsLargeChoice.vue?vue&type=script&lang=ts&"
import style0 from "./QsLargeChoice.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VHover,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75c3143a')) {
      api.createRecord('75c3143a', component.options)
    } else {
      api.reload('75c3143a', component.options)
    }
    module.hot.accept("./QsLargeChoice.vue?vue&type=template&id=75c3143a&", function () {
      api.rerender('75c3143a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QsLargeChoice.vue"
export default component.exports