var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                flat: "",
                "model-name": _vm.slug,
                theme: "labase"
              },
              on: { input: _vm.setListParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("automated_email_templates", $event)
                }
              }
            }),
            _c("router-view"),
            _c(
              "qs-confirmation-modal",
              _vm._b(
                {
                  on: {
                    "click:cancel": function($event) {
                      _vm.listDialog.value = false
                    },
                    "click:confirm": _vm.listDialog.callback
                  }
                },
                "qs-confirmation-modal",
                _vm.listDialog,
                false
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }