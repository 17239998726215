import { Module } from 'vuex';

import { TagsViewState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';
import { PersistedTag } from 'qs_vuetify/src/types/models';

export const tagsView: Module<TagsViewState, RootState> = {
  namespaced: true,
  state: {
    detailModal: false,
    params: {
      fields: [
        'created_at',
        'created_by_user.contact_name',
        'description',
        'instance.name',
        'name',
        'parent.name',
        'stats',
      ].join(','),
      order: 'name',
      page: 1,
    },
    selectedItems: [],
  },
  getters: {
    detailModal(state) {
      return state.detailModal;
    },
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
  },
  mutations: {
    detailModal(state, value) {
      state.detailModal = value;
    },
    addSelectedItem(state, item) {
      if (!state.selectedItems.find((i: PersistedTag) => i.id === item.id)) {
        state.selectedItems.push({
          ...item,
        });
      }
    },
    removeSelectedItem(state, item) {
      const index = state.selectedItems.findIndex((i: PersistedTag) => i.id === item.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    params(state, params) {
      state.params = params;
    },
    setSelectedItems(state, items) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...items,
      );
    },
  },
};

export default tagsView;
