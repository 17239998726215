var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "duplicates-interactions" },
    _vm._l(_vm.interactions, function(interaction, key) {
      return _c(
        "v-list-item",
        { key: key },
        [
          _c(
            "v-list-item-avatar",
            { attrs: { color: interaction.color, size: "24" } },
            [
              _c("v-icon", { attrs: { color: "white", small: "" } }, [
                _vm._v(_vm._s(interaction.icon))
              ])
            ],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [
                _vm._v(" " + _vm._s(interaction.title) + " ")
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(
                  " Le " + _vm._s(_vm._f("customDate")(interaction.date)) + " "
                )
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(interaction.author) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }