var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pa-4" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": { q: _vm.filtersDefinition.q },
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: { input: _vm.setListParams }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    color: "info",
                    icon: "mdi-information",
                    outlined: ""
                  }
                },
                [
                  _c("p", { staticClass: "text-2" }, [
                    _vm._v(
                      " Une fois la demande traitée, cliquez sur les crochez verts pour la marquer comme complétée. "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("qs-data-table", {
        attrs: {
          actions: ["view"],
          headers: _vm.headers,
          items: _vm.data,
          loading: !_vm.routeDataLoaded || _vm.loading,
          options: _vm.options,
          "set-options": _vm.setOptions,
          tile: "",
          total: _vm.total || 0
        },
        on: {
          view: function($event) {
            return _vm.goto("contacts", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "qs-green",
                      icon: "",
                      loading: item.id === _vm.markingAsDone,
                      small: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.markAsDone(item.id)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-check-all")
                    ])
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      small: "",
                      color: "qs-blue",
                      to: "contacts/" + item.id
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v("mdi-magnify")
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }