













































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import Fileable from 'qs_vuetify/src/mixins/Fileable';
import Importable from '@/mixins/Importable';

import FieldsMatcher from '@/components/imports/FieldsMatcher.vue';
import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

@Component({
  components: {
    FieldsMatcher,
    QsActionModal,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewContactsImportsForm extends mixins(
  Fileable,
  Importable,
) {
  formOrder: string[] = [
    'name',
    'source_id',
  ];

  mounted() {
    this.optionsImports('App\\Models\\Contact');
  }

  @Watch('file')
  onNewContactsImportFormFileChanged() {
    this.parseFile();
  }

  @Watch('fileId')
  onNewContactsImportFormFileIdChanged(newFileId: number | null) {
    this.syncImport({
      ...this.item,
      importable_type: 'App\\Models\\Contact',
      file: {
        id: newFileId,
      },
      fields_index: {
        first_name: null,
        last_name: null,
        email: null,
        postal_code: null,
      },
    });
  }
}
