var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sms-conversation-replies" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2", attrs: { cols: "6" } },
            [_c("messenger")],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-2", attrs: { cols: "3" } },
            [_c("context", { on: { patch: _vm.reloadDataRoutesData } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }