import { ActionTree, Module } from 'vuex';

import { Transaction } from 'qs_vuetify/src/types/models';
import { TransactionsRestState } from '@/types/states';
import { RestParams, RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import axios from 'qs_vuetify/src/plugins/axios';

export const transactionsState: TransactionsRestState = {
  ...buildRestState<Transaction>('transactions', {}),
  membership: {
    // contact
    address: '',
    apartment: '',
    birthdate: null,
    city: '',
    contact_id: null,
    district: null,
    emails: [],
    first_name: '',
    gender: '',
    home_phone: '',
    last_name: '',
    postal_code: '',
    // membership
    duration: '1',
    renewal: 'manual',
    // transaction
    amount_in_cad: 5,
    executed_at: '',
    external_id: null,
    payment_method: 'cash',
  },
  stats: null,
};

export const getters = {
  ...buildRestGetters<Transaction, TransactionsRestState>(),
  membership(state: any) {
    return state.membership;
  },
  stats(state: any) {
    return state.stats;
  },
};

export const actions: ActionTree<TransactionsRestState, RootState> = {
  ...buildRestActions<Transaction, TransactionsRestState>(),
  async membership({ state, commit }) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.post('memberships/manual_payment', {
        ...state.membership,
      });

      commit('ajax', ajax);
      await ajax;

      commit('ajax', null);

      commit('loaded', true);
    } catch (e) {
      commit('ajax', null);

      if (e.response && e.response.data) {
        commit('error', e.response.data);
      }

      throw e.request;
    }
  },
  async stats({ state, commit }, params: RestParams) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.get(`${state.prefix}/${state.path}/stats`, {
        params,
      });

      commit('ajax', ajax);
      const { data } = await ajax;
      commit('stats', data);
      commit('ajax', null);

      commit('loaded', true);
    } catch (e) {
      commit('ajax', null);

      if (e.response && e.response.data) {
        commit('error', e.response.data);
      }

      throw e.request;
    }
  },
};

export const mutations = {
  ...buildRestMutations<Transaction, TransactionsRestState>(),
  membership(state: any, membership: any) {
    state.membership = membership;
  },
  stats(state: any, stats: any) {
    state.stats = stats;
  },
};

export const transactions: Module<TransactionsRestState, RootState> = {
  namespaced: true,
  state: transactionsState,
  getters,
  actions,
  mutations,
};
