var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "events-list", attrs: { fluid: "" } },
    [
      _c("qs-base-layout", {
        scopedSlots: _vm._u([
          {
            key: "sidebar",
            fn: function() {
              return [
                _c("qs-filters", {
                  attrs: {
                    "active-filters": _vm.params,
                    filters: _vm.eventsFilters,
                    "filters-definition": _vm.filtersDefinition,
                    flat: "",
                    "model-name": _vm.slug,
                    theme: "labase"
                  },
                  on: {
                    "change:filter": _vm.setEventsFilter,
                    input: _vm.setEventsParams
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "contact.id.item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("qs-contact-list-item", { attrs: { item: item } })
                        ]
                      }
                    }
                  ])
                }),
                _vm.total
                  ? _c("qs-stats-card", {
                      staticClass: "mt-3 mb-3",
                      attrs: {
                        color: "blue",
                        label: "Correspondant au filtre",
                        icon: "mdi-sigma",
                        stat: _vm.total
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "main",
            fn: function() {
              return [
                _c("qs-data-table", {
                  attrs: {
                    actions: ["edit"],
                    flat: "",
                    headers: _vm.headers,
                    items: _vm.data,
                    loading: !_vm.routeDataLoaded || _vm.loading,
                    options: _vm.options,
                    "set-options": _vm.setOptions,
                    tile: "",
                    total: _vm.total || 0
                  },
                  on: {
                    edit: function($event) {
                      return _vm.goto("events", $event)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.visibility",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("events-visibility-indicator", {
                            attrs: {
                              "event-id": item.id,
                              value: item.visibility,
                              "with-tooltip": ""
                            },
                            on: { input: _vm.reload }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.title",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("event-admin-popover", {
                                staticClass: "mr-2",
                                attrs: { item: item }
                              }),
                              _vm._v(" " + _vm._s(item.title) + " ")
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.instances.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.instances.length > 0
                            ? _vm._l(item.instances, function(ref) {
                                var id = ref.id
                                var name = ref.name
                                return _c(
                                  "v-chip",
                                  {
                                    key: id,
                                    staticClass: "pl-1 qs-user-chip",
                                    attrs: { color: "qs-light-grey", small: "" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "success" }
                                      },
                                      [_vm._v("mdi-check-circle")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(_vm._s(name))]
                                    )
                                  ],
                                  1
                                )
                              })
                            : item.organized_by_user
                            ? _c("qs-user-chip", {
                                attrs: {
                                  item: item.organized_by_user,
                                  small: ""
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.start_at",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm.isSameDayEvent(item)
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.start_at,
                                        "D MMMM YYYY, [de] H[h]mm"
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.end_at,
                                        "[ à] H[h]mm"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            : [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.start_at,
                                        "[du] D MMMM YYYY [à] H[h]mm"
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        item.end_at,
                                        "[au] D MMMM YYYY [à] H[h]mm"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                        ]
                      }
                    },
                    {
                      key: "item.is_approved",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("qs-boolean-indicator", {
                            attrs: { value: item.is_approved },
                            on: {
                              click: function($event) {
                                return _vm.toggleIsApproved(item)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }