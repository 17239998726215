




























































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRepeaterField from 'qs_vuetify/src/components/Fields/QsRepeaterField.vue';

import ItemNavigation from '@/components/ItemNavigation.vue';

import { ButtonProps, DialogProps, Form } from 'qs_vuetify/src/types/components';
import {
  PersistedContact,
  PersistedSentSms,
  PersistedSmsCampaign,
  User,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const auth: any = namespace('auth');
const contacts: any = namespace('contacts');
const contactsView: any = namespace('contactsViews');
const global: any = namespace('global');
const store: any = namespace('sms_campaigns');
const sent_sms: any = namespace('sent_sms');
const sentSmsView: any = namespace('sentSmsViews');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('sms_campaigns/item', null);
    next();
  },
  components: {
    ItemNavigation,
    QsButton,
    QsCard,
    QsConfirmationModal,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsRepeaterField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class SmsCampaign extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  InteractsWithNavbar,
  ListMixin,
) {
  @auth.Getter user!: User;

  @contacts.Getter('data') contacts!: Array<PersistedContact>;
  @contacts.Getter('filtersDefinition') contactsFiltersDefinition!: FiltersDefinition;
  @contacts.Getter('loaded') contactsLoaded!: boolean;
  @contacts.Getter('loading') contactsLoading!: boolean;
  @contacts.Getter('total') contactsTotal!: number;

  @contactsView.Getter('params') contactsParams!: RestParams;
  @contactsView.Mutation('setParams') setContactsParams!: any;

  @global.Mutation addNotification!: Function;

  @store.Getter error!: ErrorResponse;
  @store.Getter form!: Form;
  @store.Getter initialItem!: string;
  @store.Getter item!: PersistedSmsCampaign;
  @store.Getter('loading') loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @store.Action send!: (payload: { id: number; amount?: number }) => void;

  @sent_sms.Getter('data') sentSms!: Array<PersistedSentSms>;
  @sent_sms.Getter('filtersDefinition') sentSmsFiltersDefinition!: FiltersDefinition;
  @sent_sms.Getter('loaded') sentSmsLoaded!: boolean;
  @sent_sms.Getter('loading') sentSmsLoading!: boolean;
  @sent_sms.Getter('total') sentSmsTotal!: number;
  @sent_sms.Getter('queuedTotal') sentSmsQueuedTotal!: number;

  @sentSmsView.Getter('options') sentSmsOptions!: any;
  @sentSmsView.Getter('params') sentSmsParams!: RestParams;
  @sentSmsView.Mutation('setOptions') setSentSmsOptions!: any;
  @sentSmsView.Mutation('setParams') setSentSmsParams!: any;

  updateContactsParams = this.buildSetParam('sent_sms', this.setSentSmsParams);
  updateSentSmsParams = this.buildSetParam('sent_sms', this.setSentSmsParams);

  @Prop({ type: [String, Number], required: true }) id!: string | number;

  contactsHeaders = [
    { text: 'No. de membre', value: 'v1_contact_id', sortable: false },
    { text: 'Contact', value: 'full_name', sortable: false },
    { text: 'Téléphone', value: 'home_phone', sortable: false },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Appels', value: 'contact_exchanges', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  sendDialog: DialogProps = {
    callback: () => this.send({ id: this.item.id, amount: 99 }),
    color: 'warning',
    loading: false,
    message: 'Cette action est irréversible.',
    title: 'Êtes-vous certain·e de vouloir envoyer?',
    value: false,
  }

  sentSmsHeaders = [
    { text: 'No. de membre', value: 'contact.v1_contact_id', sortable: false },
    { text: 'Contact', value: 'contact.full_name', sortable: false },
    { text: 'Numéro', value: 'to', sortable: false },
    { text: 'Statut', value: 'status', sortable: false },
    {
      text: 'Réponses non-lues', value: 'unread_replies_count', sortable: true, align: 'right',
    },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'sms_campaigns.retrieve': {
        fields: [
          'name',
          'status',
          'instance.name',
          'start_at',
          'end_at',
          'initial_message',
          'preformatted_replies',
          'filter',
          'contacts_count',
          'sent_sms_count',
          'unsent_sms_contacts_count',
        ].join(','),
      },
      'sent_sms.index': {
        ...ListMixin.buildListState(this.sentSmsOptions, this.sentSmsParams),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getDataTableItemClass(item: PersistedContact): string[] {
    if (['RET', 'DEC', 'DBL'].includes(item.status)) {
      return ['call-campaign-form__contacts__item--inactive'];
    }

    return [];
  }

  setActions() {
    const actions: Array<ButtonProps> = [];

    if (this.userHas('SMS_CAMPAIGNS_UPDATE')) {
      actions.push({
        onClick: this.submit,
        color: 'primary',
        disabled: !this.hasChanged || this.loading,
        icon: '$qs-save',
        tooltip: 'Enregistrer',
      });
    }

    if (this.userHas('SMS_CAMPAIGNS_UPDATE') && this.userHas('SMS_CAMPAIGNS_DESTROY')) {
      actions.push({
        onClick: () => { this.sendDialog.value = true; },
        color: 'success',
        disabled: !this.item || this.loading || this.item.status !== 'active' || this.sentSmsQueuedTotal > 5,
        progress: this.sentSmsQueuedTotal > 0 ? 100 - this.sentSmsQueuedTotal : null,
        icon: 'mdi-send',
        tooltip: 'Envoyer',
      });
    }

    if (this.userHas('SMS_REPLY')) {
      actions.push({
        onClick: () => this.$router.push(`/sms/${this.item.id}/conversations`),
        color: 'info',
        icon: 'mdi-comment',
        tooltip: 'Conversations',
      });
    }

    this.$store.commit('global/actions', actions);
  }

  setGlobalSubtitle() {
    this.$store.commit('global/title', 'SMS');
    if (this.itemReady) {
      this.$store.commit('global/subtitle', this.item?.name);
    }
    this.$emit('updateHead');
  }

  @Watch('hasChanged')
  onHasChangedChanged() {
    this.setActions();
  }

  @Watch('itemReady')
  onItemReadyChanged(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(loaded: boolean) {
    if (loaded) {
      this.setGlobalSubtitle();
      this.setActions();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
    this.setActions();
  }
}
