var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "new-contacts-imports-form",
        dark: false,
        height: _vm.fileId ? "80vh" : undefined,
        "max-width": "700",
        value: true
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "ContactsImportsList" })
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Créer une importation ")]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _vm.file && !_vm.fileId
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        tile: "",
                        loading: _vm.fileLoading
                      },
                      on: { click: _vm.uploadFile }
                    },
                    [_vm._v(" Téléverser ")]
                  )
                : _vm._e(),
              _vm.fileId
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        tile: "",
                        loading: _vm.importsLoading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(" Créer ")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.fileId
        ? _c(
            "v-alert",
            {
              staticClass: "body-2 text-center",
              attrs: {
                color: _vm.file ? "success" : "qs-light-grey",
                icon: _vm.file ? "mdi-check" : "mdi-file-upload",
                outlined: !!_vm.file,
                tile: ""
              }
            },
            [
              _vm._v(
                " Téléverser un fichier pour commencer " +
                  _vm._s(_vm.file ? "🚀" : "👇") +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c("v-file-input", {
        attrs: { accept: "text/csv", disabled: _vm.fileLoading },
        model: {
          value: _vm.file,
          callback: function($$v) {
            _vm.file = $$v
          },
          expression: "file"
        }
      }),
      _vm.fileId
        ? [
            _c("v-divider"),
            _vm.itemReady
              ? _c("qs-form-builder", {
                  attrs: {
                    "model-name": _vm.slug,
                    form: _vm.importsForm,
                    item: _vm.item,
                    error: _vm.importsError,
                    order: _vm.formOrder
                  },
                  on: { input: _vm.syncImport }
                })
              : _vm._e(),
            _vm.itemReady && _vm.fileMeta
              ? _c("fields-matcher", {
                  attrs: {
                    "available-fields": _vm.availableFields,
                    data: _vm.fileData,
                    "model-name": "contacts",
                    "parsed-fields": _vm.fileMeta.fields
                  },
                  model: {
                    value: _vm.item.fields_index,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "fields_index", $$v)
                    },
                    expression: "item.fields_index"
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }