var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "admin-popover",
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.item.title))]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "info", small: "" } },
                  [_vm._v("mdi-information")]
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.item.description) }
                })
              ],
              1
            ),
            _vm.item.location
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "info", small: "" }
                      },
                      [_vm._v("mdi-map-marker")]
                    ),
                    _vm._v(" " + _vm._s(_vm.item.location_name)),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.item.location.google_place_address) + " "
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }