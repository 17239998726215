










































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Messenger extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: false, type: Array, default: () => [] }) messages!: any[];
  @Prop({ required: false, type: String, default: '' }) value!: string;

  // eslint-disable-next-line class-methods-use-this
  abbreviatedName(name: string) {
    return name.split('').map((l) => (l === l.toLowerCase() ? '' : l)).join('');
  }

  // eslint-disable-next-line class-methods-use-this
  dateFormat(date: string) {
    const options = {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
    };

    return new Intl.DateTimeFormat('fr-CA', (options as any)).format(new Date(date));
  }

  // eslint-disable-next-line class-methods-use-this
  isSameDayAsPrevious(currentIndex: number) {
    if (currentIndex < 1) {
      return false;
    }

    const currentDate = new Intl.DateTimeFormat('fr-CA').format(new Date(this.messages[currentIndex].created_at));
    const previousDate = new Intl.DateTimeFormat('fr-CA').format(new Date(this.messages[currentIndex - 1].created_at));
    return currentDate === previousDate;
  }

  isSameUserAsPrevious(currentIndex: number) {
    if (currentIndex < 1) {
      return false;
    }

    return this.messages[currentIndex].from === this.messages[currentIndex - 1].from;
  }

  isSameUserAsNext(currentIndex: number) {
    if (currentIndex === this.messages.length - 1) {
      return false;
    }

    return this.messages[currentIndex].from === this.messages[currentIndex + 1].from;
  }

  emitReply() {
    this.$emit('reply', this.value);
    this.$emit('input', '');
  }
}
