var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("qs-layout-navigation", {
        attrs: {
          "admin-menu": _vm.adminMenuItems,
          "primary-menu": _vm.primaryMenuItems,
          "show-notifications": !!_vm.user,
          theme: "labase",
          "version-link":
            "https://docs.google.com/document/d/1-alIGPg0CEgLxB9xAhoTtrFy0E8ggRX0-j6vg290WC4/edit?usp=sharing",
          "version-number": "1.0.0"
        },
        on: { login: _vm.loadUserOrRedirect, logout: _vm.redirectToHome }
      }),
      _c(
        "v-main",
        [
          _c("qs-layout-notifications"),
          _c("router-view"),
          _c(
            "qs-confirmation-modal",
            _vm._b(
              {
                on: {
                  "click:confirm": _vm.runConfirmationDialogCallback,
                  "click:cancel": _vm.resetConfirmationDialog
                }
              },
              "qs-confirmation-modal",
              _vm.confirmationDialog,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }