import Vue from 'vue';
import vClickOutside from 'vuetify/lib/directives/click-outside';

import setupSentry from 'qs_vuetify/src/plugins/sentry';
import 'qs_vuetify/src/plugins/vMask';
import 'qs_vuetify/src/plugins/vueCurrencyField';
import 'qs_vuetify/src/plugins/vueHead';
import 'qs_vuetify/src/plugins/vueScrollTo';
import dayjs from 'qs_vuetify/src/plugins/dayjs';
import filters from 'qs_vuetify/src/plugins/filters';
import helpers from 'qs_vuetify/src/plugins/helpers';
import i18n from 'qs_vuetify/src/plugins/i18n';
import vuetify from 'qs_vuetify/src/plugins/vuetify';
import { RestParams } from 'qs_vuetify/src/types/states';

import App from './App.vue';
import router from './router';
import store from './store';

import './styles/main.scss';

Vue.config.productionTip = true;

declare module 'vue/types/vue' {
  interface Vue { // eslint-disable-line no-shadow
    viewParams?: { [slug: string]: RestParams };
    $dayjs: any;
    $filters: any;
    $helpers: any;
  }
}

setupSentry(router);

Object.keys(filters).forEach((f) => Vue.filter(f, filters[f]));

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$filters = filters;
Vue.prototype.$helpers = helpers;

Vue.directive('click-outside', vClickOutside);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
