import { ActionTree, Module } from 'vuex';
import { District } from 'qs_vuetify/src/types/models';
import { RootState } from 'qs_vuetify/src/types/states';

import { DistrictsRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import axios from 'qs_vuetify/src/plugins/axios';

export const state: DistrictsRestState = {
  ...buildRestState<District>('districts', {}),
  postalCodeSearchResult: [],
};

export const getters = {
  ...buildRestGetters<District, DistrictsRestState>(),
  // eslint-disable-next-line no-shadow
  postalCodeSearchResult(state: any) {
    return state.postalCodeSearchResult;
  },
};

// export const actions = buildRestActions<District, DistrictsRestState>();
export const actions: ActionTree<DistrictsRestState, RootState> = {
  ...buildRestActions<District, DistrictsRestState>(),
  async searchByPostalCode({ commit }, postalCode) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.get('districts', {
        params: {
          postal_code: postalCode.toUpperCase().replace(/ /g, ''),
        },
      });

      commit('ajax', ajax);

      const { data: { data } } = await ajax;

      commit('postalCodeSearchResult', data);
    } catch (e) {
      if (e.response.data) {
        commit('error', e.response.data);
      }

      throw e;
    } finally {
      commit('loaded', true);
    }
  },
};

export const mutations = {
  ...buildRestMutations<District, DistrictsRestState>(),
  // eslint-disable-next-line no-shadow
  postalCodeSearchResult(state: any, postalCodeSearchResult: Array<District>) {
    state.postalCodeSearchResult = postalCodeSearchResult;
  },
};

export const districts: Module<DistrictsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
