




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ItemNavigation from '@/components/ItemNavigation.vue';

import { Model } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    ItemNavigation,
  },
})
export default class BaseLayout extends Vue {
  @Prop({ required: false, type: String, default: '' }) contentClass!: string;
  @Prop({ required: false, type: Object, default: undefined }) item?: Model;
  @Prop({ required: false, type: Array, default: undefined }) items?: Model[];
  @Prop({ required: false, type: String, default: undefined }) modelName?: string;
}
