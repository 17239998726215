




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import SmsCampaign from '@/views/Sms/SmsCampaign.vue';

@Component
export default class SentSmsList extends Vue {
  @Prop({ required: false, type: Array, default: () => [] }) items!: any[];
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean;
  @Prop({ required: false, default: null }) markAsReadLoading!: number | boolean;
  @Prop({ required: false, default: null }) context!: SmsCampaign | null;

  getLinkForItem(id: number) {
    if (this.context) {
      return `/sms/${this.context.id}/conversations/${id}`;
    }

    return `/sms/conversations/${id}`;
  }
}
