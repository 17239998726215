import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import {
  buildRestRoutes,
} from 'qs_vuetify/src/store/buildBaseRoute';

import { capitalize } from 'qs_vuetify/src/plugins/filters';
import i18n from 'qs_vuetify/src/plugins/i18n';

const AutomatedEmailTemplate = () => import('@/views/AutomatedEmailTemplates/AutomatedEmailTemplate.vue');
const AutomatedEmailTemplates = () => import('@/views/AutomatedEmailTemplates/AutomatedEmailTemplates.vue');
const AutomatedEmailTemplateDialog = () => import('@/views/AutomatedEmailTemplates/AutomatedEmailTemplateDialog.vue');
const EmailDialog = () => import('@/views/Emails/EmailDialog.vue');
const Emails = () => import('@/views/Emails/Emails.vue');
const FormDefinitionTemplatesForm = () => import('@/views/Models/FormDefinitionTemplates/FormDefinitionTemplatesForm.vue');
const FormDefinitionTemplatesList = () => import('@/views/Models/FormDefinitionTemplates/FormDefinitionTemplatesList.vue');
const PhonesForm = () => import('@/views/Models/Phones/PhonesForm.vue');
const PhonesList = () => import('@/views/Models/Phones/PhonesList.vue');
const PostalCodesForm = () => import('@/views/Models/PostalCodes/PostalCodesForm.vue');
const PostalCodesList = () => import('@/views/Models/PostalCodes/PostalCodesList.vue');
const TagDialog = () => import('@/views/Models/Tags/TagDialog.vue');
const Tags = () => import('@/views/Models/Tags/Tags.vue');
const SentEmailsList = () => import('@/views/SentEmails/SentEmailsList.vue');
const SentEmailsForm = () => import('@/views/SentEmails/SentEmailsForm.vue');

const otherRoutes = [
  ...buildRestRoutes({
    dataActions: {
      params: {
        fields: 'code,districts.name',
      },
    },
    genericForm: PostalCodesForm,
    list: PostalCodesList,
    menus: ['others'],
    slug: 'postal_codes',
  }),
  {
    path: '/emails',
    name: 'Emails',
    component: Emails,
    meta: {
      auth: true,
      data: {
        emails: {
          index: {
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.emailsView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.emails.name', 2)),
      menus: ['others'],
      requires: ['EMAILS_RETRIEVE'],
    },
    children: [{
      path: ':id',
      name: 'EmailDialog',
      component: EmailDialog,
      props: true,
      meta: {
        data: {
          emails: {
            loadEmpty: {
              conditional(vm: Vue) {
                return vm.$route.params.id === 'new';
              },
            },
            index: {
              conditional(vm: Vue) {
                return vm.$store.state.emails.lastLoadedAt === null;
              },
              contextual(vm: Vue) {
                const { options, params } = vm.$store.state.emailsView;
                return {
                  ...ListMixin.buildListState(options, params),
                };
              },
            },
            retrieve: {
              conditional(vm: Vue) {
                return vm.$route.params.id !== 'new';
              },
              contextual(vm: Vue) {
                return {
                  id: vm.$route.params.id,
                };
              },
            },
          },
        },
        title: capitalize(i18n.tc('models.emails.name', 1)),
        menus: [],
        requires: ['EMAILS_RETRIEVE'],
      },
    }],
  },
  ...buildRestRoutes({
    dataActions: {
      index: {
        params: {
          fields: 'contact_id,email,external_id,mailable_class,mailer,sent_at',
        },
      },
      retrieve: {
        params: {
          fields: [
            'contact.full_name',
            'contact.v1_contact_id',
            'email',
            'mailable_class',
            'sent_at',
            'trackable_sent_email.bounced',
            'trackable_sent_email.clicked',
            'trackable_sent_email.opened',
            'trackable_sent_email.mail_campaign.body',
            'trackable_sent_email.mail_campaign.created_by_user.contact_name',
            'trackable_sent_email.mail_campaign.instance.email',
            'trackable_sent_email.mail_campaign.instance.name',
            'trackable_sent_email.mail_campaign.name',
            'trackable_sent_email.mail_campaign.stats',
            'trackable_sent_email.mail_campaign.subject',
          ].join(','),
        },
      },
    },
    genericForm: SentEmailsForm,
    list: SentEmailsList,
    menus: ['others'],
    slug: 'sent_emails',
  }),
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: {
      auth: true,
      data: {
        instances: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.instances.lastLoadedAt;
            },
            params: {
              fields: 'id,name',
              order: 'name',
              per_page: '*',
            },
          },
        },
        tags: {
          index: {
            contextual(vm: Vue) {
              const { params } = vm.$store.state.tagsView;
              return {
                ...ListMixin.buildListState(params),
              };
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.tags.name', 2)),
      menus: ['others'],
      requires: ['TAGS_RETRIEVE'],
    },
    children: [{
      path: ':id',
      name: 'TagDialog',
      component: TagDialog,
      props: true,
      meta: {
        data: {
          instances: {
            index: {
              conditional(vm: Vue) {
                return !vm.$store.state.instances.lastLoadedAt;
              },
              params: {
                fields: 'id,name',
                order: 'name',
                per_page: '*',
              },
            },
          },
          tags: {
            loadEmpty: {
              conditional(vm: Vue) {
                return vm.$route.params.id === 'new';
              },
            },
            index: {
              conditional(vm: Vue) {
                return vm.$store.state.tags.lastLoadedAt === null;
              },
              contextual(vm: Vue) {
                const { params } = vm.$store.state.tagsView;
                return {
                  ...ListMixin.buildListState(params),
                };
              },
              params: {
                fields: [
                  '*',
                  'instance.name',
                  'parent.name',
                  'stats',
                ].join(','),
              },
            },
            retrieve: {
              conditional(vm: Vue) {
                return vm.$route.params.id !== 'new';
              },
              contextual(vm: Vue) {
                return {
                  id: vm.$route.params.id,
                };
              },
            },
          },
        },
        title: capitalize(i18n.tc('models.tags.name', 1)),
        menus: [],
        requires: ['TAGS_RETRIEVE'],
      },
    }],
  },
  {
    path: '/automated_email_templates',
    name: 'AutomatedEmailTemplates',
    component: AutomatedEmailTemplates,
    meta: {
      auth: true,
      data: {
        automated_email_templates: {
          index: {
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.automatedEmailTemplatesView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
            params: {
              fields: 'id,name,subject',
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.automated_email_templates.name', 2)),
      menus: ['others'],
      requires: ['AUTOMATED_EMAIL_TEMPLATES_RETRIEVE'],
    },
    children: [{
      path: 'new',
      name: 'AutomatedEmailTemplateDialog',
      component: AutomatedEmailTemplateDialog,
      props: true,
      meta: {
        data: {
          automated_email_templates: {
            loadEmpty: {},
            index: {
              conditional(vm: Vue) {
                return vm.$store.state.automated_email_templates.lastLoadedAt === null;
              },
              contextual(vm: Vue) {
                const { options, params } = vm.$store.state.automatedEmailTemplatesView;
                return {
                  ...ListMixin.buildListState(options, params),
                };
              },
            },
          },
        },
        title: capitalize(i18n.tc('models.automated_email_templates.name', 1)),
        menus: [],
        requires: ['AUTOMATED_EMAIL_TEMPLATES_CREATE'],
      },
    }],
  },
  {
    path: '/automated_email_templates/:id',
    name: 'AutomatedEmailTemplate',
    component: AutomatedEmailTemplate,
    props: true,
    meta: {
      data: {
        automated_email_templates: {
          index: {
            conditional(vm: Vue) {
              return vm.$store.state.automated_email_templates.lastLoadedAt === null;
            },
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.automatedEmailTemplatesView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
          },
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.automated_email_templates.name', 1)),
      menus: [],
      requires: ['AUTOMATED_EMAIL_TEMPLATES_RETRIEVE'],
    },
  },
  ...buildRestRoutes({
    genericForm: FormDefinitionTemplatesForm,
    list: FormDefinitionTemplatesList,
    menus: ['others'],
    slug: 'form_definition_templates',
  }),
  ...buildRestRoutes({
    genericForm: PhonesForm,
    list: PhonesList,
    menus: ['others'],
    slug: 'phones',
  }),
];

export default otherRoutes;
