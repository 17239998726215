





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { Duplicate, User } from 'qs_vuetify/src/types/models';

interface Interaction {
  author: User | null;
  color: string;
  date: string;
  icon: string;
  title: string;
}

@Component({
  filters: {
    customDate(date: string) {
      return dayjs(date).format('D MMM YYYY à H[h]mm');
    },
  },
})
export default class DuplicatesInteractions extends Vue {
  @Prop({ required: true, type: Object }) item!: Duplicate;

  get interactions(): Interaction[] {
    const changedBys = [
      {
        author: this.item.created_by_user
          ? this.item.created_by_user.contact_name
          : null,
        color: 'qs-green',
        date: this.item.created_at || '',
        icon: 'mdi-plus',
        title: 'Consignation',
      },
    ];

    if (this.item.merged_at) {
      changedBys.push({
        author: this.item.merged_by_user
          ? this.item.merged_by_user.contact_name
          : null,
        color: 'qs-red',
        date: this.item.merged_at || '',
        icon: 'mdi-call-merge',
        title: 'Fusion',
      });
    }

    if (this.item.moderated_at) {
      changedBys.push({
        author: this.item.moderated_by_user
          ? this.item.moderated_by_user.contact_name
          : null,
        color: 'qs-orange',
        date: this.item.moderated_at || '',
        icon: 'mdi-check',
        title: 'Modération',
      });
    }

    const comments = this.item.comments.map((c: any) => ({
      author: c.created_by_user
        ? c.created_by_user.contact_name
        : null,
      color: 'qs-blue',
      date: c.created_at || '',
      icon: 'mdi-message-outline',
      title: c.text,
    }));

    return [...changedBys, ...comments]
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }
}
