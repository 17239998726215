












































































































































































































import Component, { mixins } from 'vue-class-component';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { RestParams } from 'qs_vuetify/src/types/states';

import Importable from '@/mixins/Importable';

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('imports/item', null);
    next();
  },
  components: {
    QsFormBuilder,
    QsButton,
    QsProgress,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, '');
      if (subtitle) {
        inner = `${inner} | ${subtitle}`;
      }
      return { inner: title || inner };
    },
  },
})
export default class ContributionsImportsForm extends mixins(Importable) {
  filtersOrder = [
    'is_confirmed',
    'contribution_status',
    'flags_count',
    'flags',
    'has_membership',
    'is_dpa',
    'with_deleted',
    'status',
  ];

  formOrder = [
    'name',
    'meta',
  ];

  importablesHeaders = [
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Traité', value: 'data.is_confirmed', sortable: false },
    { text: 'No. de contribution', value: 'data.contribution_number', sortable: false },
    { text: 'Prénom', value: 'data.first_name', sortable: false },
    { text: 'Nom', value: 'data.last_name', sortable: false },
    { text: 'Montant de la contribution', value: 'data.contribution_amount', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  parentRouteName = 'ContributionsImportsList';

  mounted() {
    this.setup();
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      'imports.retrieve': {
        fields: '*',
        with_deleted: true,
      },
      'importables.index': {
        ...ListMixin.buildListState(this.importablesOptions, this.importablesParams),
        order: 'data->last_name,data->first_name,id',
      },
    };
  }

  importablesTablePatch(id: number | string, field: string, value: any) {
    this.patch(
      'importables',
      id,
      { [field]: value },
      () => { this.reloadDataRoutesData(['importables']); },
    );
  }
}
