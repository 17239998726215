




































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import Importable from '@/mixins/Importable';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBinaryPrompt from 'qs_vuetify/src/components/Dialog/QsBinaryPrompt.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsModerationForm from 'qs_vuetify/src/components/QsModerationForm.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsWarningIndicator from 'qs_vuetify/src/components/Indicators/QsWarningIndicator.vue';

import { Form } from 'qs_vuetify/src/types/components';
import {
  PersistedContact,
  PersistedImportable,
} from 'qs_vuetify/src/types/models';
import { RelationQueryDefinition } from 'qs_vuetify/src/types/states';

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('importables/item', null);
    next();
  },
  components: {
    QsActionModal,
    QsBinaryPrompt,
    QsButton,
    QsContactListItem,
    QsModerationForm,
    QsRelationField,
    QsWarningIndicator,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactsImportablesForm extends mixins(Importable) {
  showMissingFields: boolean = false;

  destroyed() {
    this.syncContact(null);
  }

  contactImportablesForm: Form = {
    first_name: { type: 'text' },
    last_name: { type: 'text' },
    gender: {
      nb: 1,
      type: 'enum',
      values: [null, 'female', 'male', 'other'],
    },
    birthdate: {
      description: 'AAAA-MM-JJ',
      mask: '####-##-##',
      type: 'text',
    },
    email: { transform: 'uppercase', type: 'email' },
    home_phone: { type: 'text' },
    apartment: { type: 'text' },
    address: { type: 'text' },
    city: { type: 'text' },
    postal_code: {
      mask: 'NNN NNN',
      transform: 'uppercase',
      type: 'text',
    },
  };

  contactQueryDefinition: RelationQueryDefinition & { key: string } = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      fields: [
        'age',
        'birthdate',
        'district.name',
        'email',
        'full_name',
        'postal_code',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
      with_excluded: true,
    },
  }

  tentativeContact: PersistedContact | null = null;

  // eslint-disable-next-line class-methods-use-this
  get viewParams() {
    return {
      contacts: {
        fields: [
          'address',
          'age',
          'apartment',
          'birthdate',
          'city',
          'district.name',
          'email',
          'emails.email',
          'emails.weight',
          'full_name',
          'first_name',
          'gender',
          'home_phone',
          'last_name',
          'postal_code',
          'searchable_text',
          'status',
          'v1_contact_id',
        ].join(','),
        with_excluded: true,
      },
      'imports.retrieve': {
        fields: [
          '*',
          'source.name',
          'stats',
          'tags.name',
        ].join(','),
        with_deleted: true,
      },
    };
  }

  closeModal() {
    this.$router.push({
      name: 'ContactsImportsForm',
      params: { id: this.$route.params.id },
    });
  }

  @Watch('importablesItem', { deep: true })
  async onImportableChanged(newItem: PersistedImportable) {
    if (newItem.data?.id) {
      await this.getContact();

      this.tentativeContact = { ...this.contact };
    } else {
      this.tentativeContact = null;
      await this.$store.dispatch('contacts/loadEmpty');
    }
  }

  removeFlag(flag: string) {
    const index = this.importablesItem.data.flags.indexOf(flag);

    if (index > -1) {
      this.importablesItem.data.flags.splice(index, 1);
      this.saveImportable();
    }
  }

  updateData(field: string, value: any) {
    if (field in this.importablesItem.data) {
      if (field === 'id' && value === null) {
        this.$store.dispatch('contacts/loadEmpty');
      }

      this.importablesItem.data[field] = value;
      this.saveImportable();
    }
  }
}
