var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    staticClass: "tags",
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.partialFiltersDefinition,
                flat: "",
                "model-name": _vm.slug,
                theme: "labase"
              },
              on: { input: _vm.updateFilters }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-list", {
              attrs: {
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                "model-name": _vm.slug,
                selection: _vm.selectedItems,
                theme: "labase",
                total: _vm.total
              },
              on: { load: _vm.loadNextPage, select: _vm.selectAll },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-tags-card", {
                        attrs: {
                          item: item,
                          selected: _vm.isSelected(item),
                          to: { name: "TagDialog", params: { id: item.id } }
                        },
                        on: {
                          check: function($event) {
                            return _vm.updateSelection($event, item)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { disabled: !_vm.selectedItems.length },
                              on: {
                                click: function($event) {
                                  _vm.setParentTagModal = true
                                }
                              }
                            },
                            [
                              _c("v-list-item-content", [
                                _vm._v(
                                  " Définir l'étiquette parent pour la sélection "
                                )
                              ]),
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-tag-arrow-up")])],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { disabled: !_vm.selectedItems.length },
                              on: {
                                click: function($event) {
                                  _vm.mergeTagsModal = true
                                }
                              }
                            },
                            [
                              _c("v-list-item-content", [
                                _vm._v(" Fusionner la sélection ")
                              ]),
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-call-merge")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _c(
              "qs-action-modal",
              {
                attrs: { color: "white", dark: false },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Définir l'étiquette parent ")]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              depressed: "",
                              loading: _vm.setParentTagLoading,
                              tile: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.setParentTag(
                                  _vm.selectedItems,
                                  _vm.parentTag
                                )
                              }
                            }
                          },
                          [_vm._v(" Enregistrer ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.setParentTagModal,
                  callback: function($$v) {
                    _vm.setParentTagModal = $$v
                  },
                  expression: "setParentTagModal"
                }
              },
              [
                _c("qs-relation-field", {
                  attrs: {
                    item: { parent: _vm.parentTag },
                    label: "Étiquette parent",
                    name: "parent_id",
                    query: _vm.tagQueryDef
                  },
                  model: {
                    value: _vm.parentTag,
                    callback: function($$v) {
                      _vm.parentTag = $$v
                    },
                    expression: "parentTag"
                  }
                })
              ],
              1
            ),
            _c(
              "qs-action-modal",
              {
                attrs: { color: "white", dark: false },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Fusionner les étiquettes ")]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              depressed: "",
                              loading: _vm.mergeTagsLoading,
                              tile: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirmThenMerge(
                                  _vm.selectedItems,
                                  _vm.targetTag
                                )
                              }
                            }
                          },
                          [_vm._v(" Fusionner ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.mergeTagsModal,
                  callback: function($$v) {
                    _vm.mergeTagsModal = $$v
                  },
                  expression: "mergeTagsModal"
                }
              },
              [
                _c("v-select", {
                  attrs: {
                    hint: "L'étiquette cible sera la seule conservée",
                    items: _vm.selectedItemsWithInstanceName,
                    "item-text": "name",
                    label: "Sélectionner l'étiquette cible",
                    "return-object": ""
                  },
                  model: {
                    value: _vm.targetTag,
                    callback: function($$v) {
                      _vm.targetTag = $$v
                    },
                    expression: "targetTag"
                  }
                })
              ],
              1
            ),
            _c(
              "qs-confirmation-modal",
              _vm._b(
                {
                  on: {
                    "click:cancel": function($event) {
                      _vm.listDialog.value = false
                    },
                    "click:confirm": _vm.listDialog.callback
                  }
                },
                "qs-confirmation-modal",
                _vm.listDialog,
                false
              )
            ),
            _c("router-view")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }