var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-popover" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            "close-on-content-click": false,
            "offset-y": ""
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _vm._t("activator", [
                      _c(
                        "v-icon",
                        _vm._g(
                          { attrs: { color: "qs-light-blue", small: "" } },
                          on
                        ),
                        [_vm._v(" mdi-information ")]
                      )
                    ])
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [_c("v-card", [_vm._t("default")], 2)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }