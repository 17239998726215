import {
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';

import { User } from 'qs_vuetify/src/types/models';
import { ActionLinkResponse } from 'qs_vuetify/src/types/responses';
import { RootState } from 'qs_vuetify/src/types/states';

import { UsersRestState } from '@/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import axios from 'qs_vuetify/src/plugins/axios';

export const usersState: UsersRestState = {
  ...buildRestState<User>('users', {}),
  actionLinkResponse: null,
};

export const getters: GetterTree<UsersRestState, RootState> = {
  ...buildRestGetters<User, UsersRestState>(),
  actionLinkResponse(state) {
    return state.actionLinkResponse;
  },
};

export const actions: ActionTree<UsersRestState, RootState> = {
  ...buildRestActions<User, UsersRestState>(),
  async generateActionLink({ commit }, { id, action }) {
    commit('error', null);

    try {
      const ajax = axios.post(`users/${id}/generate_action_link`, {
        action,
      });

      commit('ajax', ajax);

      const { data } = await ajax;

      commit('actionLinkResponse', data);
    } catch (e) {
      if (e.response.data) {
        commit('error', e.response.data);
      }

      throw e;
    } finally {
      commit('ajax', null);
    }
  },
  async sendActionLink({ commit }, { id, action }) {
    commit('error', null);

    try {
      const ajax = axios.post(`users/${id}/send_action_link`, {
        action,
      });

      commit('ajax', ajax);

      await ajax;
    } catch (e) {
      if (e.response.data) {
        commit('error', e.response.data);
      }

      throw e;
    } finally {
      commit('ajax', null);
    }
  },
};

export const mutations: MutationTree<UsersRestState> = {
  ...buildRestMutations<User, UsersRestState>(),
  // eslint-disable-next-line no-shadow
  actionLinkResponse(state, actionLinkResponse: ActionLinkResponse) {
    state.actionLinkResponse = actionLinkResponse;
  },
};

export const users: Module<UsersRestState, RootState> = {
  namespaced: true,
  state: usersState,
  getters,
  actions,
  mutations,
};
