var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "tag-dialog",
        dark: false,
        value: true
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "Tags" })
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.loading
                ? _c("span", [_vm._v("Chargement...")])
                : _vm.isNew
                ? _c("span", [_vm._v("Nouvelle étiquette")])
                : _c("span", [_vm._v("Modifier l'étiquette")])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: { large: "", theme: "labase" },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.itemReady && _vm.routeDataLoaded
        ? _c("qs-tags-form", { attrs: { "show-parent": "" } })
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "30vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }