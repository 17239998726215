import { ActionTree, Module } from 'vuex';

import { Import, Importable, Persisted } from 'qs_vuetify/src/types/models';
import { ImportsRestState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';

import axios from 'qs_vuetify/src/plugins/axios';

export const importsState: ImportsRestState = {
  ...buildRestState<Import>('imports', {}),
  availableFields: [],
  findContactLoading: false,
  syncLoading: false,
  syncLoadingForImportableId: null,
};

export const getters = {
  ...buildRestGetters<Import, ImportsRestState>(),
  availableFields(state: any) {
    return state.availableFields;
  },
  findContactLoading(state: any) {
    return state.findContactLoading;
  },
  syncLoading(state: any) {
    return state.syncLoading;
  },
  syncLoadingForImportableId(state: any) {
    return state.syncLoadingForImportableId;
  },
};

export const actions: ActionTree<ImportsRestState, RootState> = {
  ...buildRestActions<Import, ImportsRestState>(),
  async putImportImportable({ state, commit }, importable: Persisted<Importable>) {
    if (state.item && state.item.id) {
      commit('setSyncLoadingForImportableId', importable.id);
      try {
        await axios.put(`/imports/${state.item.id}/importables/${importable.id}/import`);
      } catch (e) {
        commit('global/addNotification', {
          color: 'error',
          message: 'Erreur',
        }, { root: true });
      }
      commit('setSyncLoadingForImportableId', null);
    }
  },
};

export const mutations = {
  ...buildRestMutations<Import, ImportsRestState>(),
  setAvailableFields(state: any, payload: string[]) {
    state.availableFields = [...payload];
  },
  setFindContactLoading(state: any, payload: boolean) {
    state.findContactLoading = payload;
  },
  setSyncLoading(state: any, payload: boolean) {
    state.syncLoading = payload;
  },
  setSyncLoadingForImportableId(state: any, payload: number | null) {
    state.syncLoadingForImportableId = payload;
  },
};

export const imports: Module<ImportsRestState, RootState> = {
  namespaced: true,
  state: importsState,
  getters,
  actions,
  mutations,
};
