var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "", to: _vm.to } },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-start" },
        [
          _c(
            "div",
            { staticClass: "flex-grow" },
            [
              _c("h2", { staticClass: "text-h2" }, [
                _vm._v(_vm._s(_vm.item.name))
              ]),
              _vm.item.updated_by_user
                ? [
                    _c("p", { staticClass: "mb-0 body-2" }, [
                      _vm._v(
                        " Mis à jour par " +
                          _vm._s(_vm.item.updated_by_user.contact_name) +
                          " "
                      )
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(
                        " Le " +
                          _vm._s(_vm._f("datetime")(_vm.item.updated_at)) +
                          " "
                      )
                    ])
                  ]
                : _vm.item.created_by_user
                ? [
                    _c("p", { staticClass: "mb-0 body-2" }, [
                      _vm._v(
                        " Créé par " +
                          _vm._s(_vm.item.created_by_user.contact_name) +
                          " "
                      )
                    ]),
                    _c("p", { staticClass: "body-2" }, [
                      _vm._v(
                        " Le " +
                          _vm._s(_vm._f("datetime")(_vm.item.created_at)) +
                          " "
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("v-spacer"),
          _c("div", { staticClass: "flex-shrink text-h6" }, [
            _c("h4", { staticClass: "text-h5" }, [
              _vm._v(
                _vm._s(
                  _vm.i18nalizeValue(
                    "importable_type",
                    _vm.item.importable_type,
                    2
                  )
                )
              )
            ])
          ])
        ],
        1
      ),
      _vm.item && _vm.item.stats
        ? _c(
            "v-card-text",
            [
              _c(
                "qs-progress",
                {
                  attrs: {
                    "model-name": "imports",
                    values: [
                      {
                        color: "green",
                        key: "completed",
                        value: _vm.item.stats.completed
                      },
                      {
                        color: "red",
                        key: "failed",
                        value: _vm.item.stats.failed
                      },
                      {
                        color: "light-blue",
                        key: "pending",
                        value: _vm.item.stats.pending
                      }
                    ]
                  }
                },
                [
                  _vm._v(
                    " Progression " +
                      _vm._s(
                        _vm._f("number")(
                          ((_vm.item.stats.completed + _vm.item.stats.failed) /
                            _vm.item.stats.total) *
                            100,
                          0
                        )
                      ) +
                      "% "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        _vm._l(_vm.item.tags, function(tag) {
          return _c(
            "v-chip",
            {
              key: tag.id,
              staticClass: "mb-1 mr-1",
              attrs: { label: "", small: "" }
            },
            [_vm._v(" " + _vm._s(tag.name) + " ")]
          )
        }),
        1
      ),
      _vm.showAlerts
        ? _c(
            "div",
            { staticClass: "base-item-card__alerts" },
            [
              !_vm.item.stats.total
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "error" } },
                    [_vm._v("Aucun item à importer")]
                  )
                : _vm._e(),
              _vm.item.stats.failed
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "warning" } },
                    [_vm._v(" L'importation de certains items a échoué ")]
                  )
                : _vm._e(),
              !_vm.item.stats.failed &&
              !_vm.item.stats.pending &&
              _vm.item.stats.total
                ? _c(
                    "v-alert",
                    { attrs: { outlined: "", dense: "", type: "success" } },
                    [_vm._v(" L'importation est terminée ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }