var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "context" }, [
    _c("div", { staticClass: "context__contact" }, [
      _c(
        "div",
        { staticClass: "context__contact__inner" },
        [
          _vm.contact
            ? [
                _vm.contact.first_name || _vm.contact.last_name
                  ? _c(
                      "h2",
                      { staticClass: "text-h2 mb-0 font-weight-medium d-flex" },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.contact.first_name) + " "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.contact.last_name))
                          ])
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "info",
                              icon: "",
                              to: "/contacts/" + _vm.contact.id
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-magnify")])],
                          1
                        )
                      ],
                      1
                    )
                  : _vm.contact.email
                  ? _c(
                      "h1",
                      { staticClass: "text-h5 mb-0 d-flex" },
                      [
                        _vm._v(" " + _vm._s(_vm.contact.email) + " "),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "info",
                              icon: "",
                              to: "/contacts/" + _vm.contact.id
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-magnify")])],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.contact.first_name ||
                _vm.contact.last_name ||
                _vm.contact.email
                  ? _c(
                      "p",
                      { staticClass: "subtitle mb-0 font-weight-medium" },
                      [_vm._v(" #" + _vm._s(_vm.contact.v1_contact_id) + " ")]
                    )
                  : _vm._e(),
                _c("p", { staticClass: "body-2 mb-0 pb-0" }, [
                  _vm._v(
                    _vm._s(
                      _vm.contact.district ? _vm.contact.district.name : "-"
                    )
                  )
                ]),
                _vm._l(_vm.contact.adopted_instances, function(instance) {
                  return _c(
                    "p",
                    {
                      key: instance.id,
                      staticClass: "body-2 font-italic mt-0 pt-0"
                    },
                    [_vm._v(" Adopté·e par " + _vm._s(instance.name) + " ")]
                  )
                }),
                _c("qs-editable-text-field", {
                  attrs: {
                    name: "address",
                    placeholder: "Adresse manquante",
                    value: _vm.contact.address
                  },
                  on: {
                    input: function($event) {
                      return _vm.patch("address", $event)
                    }
                  }
                }),
                _c("qs-editable-text-field", {
                  attrs: {
                    name: "apartment",
                    placeholder: "Pas d'appartement",
                    value: _vm.contact.apartment
                  },
                  on: {
                    input: function($event) {
                      return _vm.patch("apartment", $event)
                    }
                  }
                }),
                _c("qs-editable-text-field", {
                  attrs: {
                    name: "city",
                    placeholder: "Municipalité manquante",
                    value: _vm.contact.city
                  },
                  on: {
                    input: function($event) {
                      return _vm.patch("city", $event)
                    }
                  }
                }),
                _c("qs-editable-text-field", {
                  attrs: {
                    params: {
                      mask: "A#A #A#",
                      transform: "uppercase"
                    },
                    modal: "",
                    name: "postal_code",
                    placeholder: "Code postal manquant",
                    value: _vm.$filters.postalCode(_vm.contact.postal_code)
                  },
                  on: {
                    input: function($event) {
                      return _vm.patch("postal_code", $event)
                    }
                  }
                })
              ]
            : [_vm._v(" no-contact ")]
        ],
        2
      )
    ]),
    _c("div", { staticClass: "context__campaign" }, [
      _c(
        "div",
        { staticClass: "context__campaign__inner" },
        [
          _vm.campaign
            ? [
                _c(
                  "h3",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "info", small: "" }
                                      },
                                      on
                                    ),
                                    [_vm._v("mdi-information")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2506649267
                        )
                      },
                      [_vm._v(" " + _vm._s(_vm.campaign.initial_message) + " ")]
                    ),
                    _vm._v(" " + _vm._s(_vm.campaign.name) + " ")
                  ],
                  1
                ),
                _c("p", [_vm._v("Réponses pré-enregistrées")]),
                _c(
                  "v-expansion-panels",
                  { attrs: { flat: "" } },
                  _vm._l(_vm.campaign.preformatted_replies, function(
                    ref,
                    index
                  ) {
                    var name = ref.name
                    var message = ref.message
                    return _c(
                      "v-expansion-panel",
                      { key: "preformatted_reply__" + index },
                      [
                        _c("v-expansion-panel-header", [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    color: "success",
                                    icon: "",
                                    "x-small": ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$emit("click:reply", message)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-plus")
                                  ])
                                ],
                                1
                              ),
                              _c("span", { staticClass: "pb-1" }, [
                                _vm._v(_vm._s(name))
                              ])
                            ],
                            1
                          )
                        ]),
                        _c("v-expansion-panel-content", [
                          _vm._v(" " + _vm._s(message) + " ")
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ]
            : [_vm._v(" no-campaign ")]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }