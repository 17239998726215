var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { value: true },
      on: {
        "click:close": function($event) {
          return _vm.$router.push("/events")
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Modifier l'événement ")]
            },
            proxy: true
          },
          _vm.item
            ? {
                key: "subtitle",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled:
                        (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                      tile: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitForm()
                      }
                    }
                  },
                  [_vm._v(" Enregistrer ")]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.item
        ? _c(
            "v-expansion-panels",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(" Titre et description ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: ["title", "description"]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Lieu ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: ["location_name", "location"]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Accessibilité ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: ["accessibility", "accessibility_notes"]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Date et heure ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: ["start_at", "duration"]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v(" Organisé par ")]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              item: _vm.item,
                              "model-name": _vm.slug,
                              order: ["instances.id", "organized_by_user_id"]
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }