import { render, staticRenderFns } from "./Duplicates.vue?vue&type=template&id=194d6976&"
import script from "./Duplicates.vue?vue&type=script&lang=ts&"
export * from "./Duplicates.vue?vue&type=script&lang=ts&"
import style0 from "./Duplicates.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCol,VContainer,VIcon,VMenu,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('194d6976')) {
      api.createRecord('194d6976', component.options)
    } else {
      api.reload('194d6976', component.options)
    }
    module.hot.accept("./Duplicates.vue?vue&type=template&id=194d6976&", function () {
      api.rerender('194d6976', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Duplicates/Duplicates.vue"
export default component.exports