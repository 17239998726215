var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        "content-class": "postal-codes-form",
        value: true,
        "max-width": "1000"
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          _vm.item
            ? {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(
                      " [" +
                        _vm._s(_vm.item.target_type) +
                        " #" +
                        _vm._s(_vm.item.target_id) +
                        "] "
                    )
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm._v(" Révision #"),
                _vm.item ? _c("span", [_vm._v(_vm._s(_vm.item.id))]) : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _vm.item
                  ? _c(
                      "qs-button",
                      {
                        attrs: {
                          theme: "labase",
                          to: _vm.targetSlug + "/" + _vm.item.target_id
                        }
                      },
                      [_vm._v(" Voir le modèle ")]
                    )
                  : _vm._e(),
                _vm.item
                  ? _c(
                      "qs-button",
                      {
                        attrs: { theme: "labase" },
                        on: {
                          click: function($event) {
                            return _vm.$store.dispatch("revisions/destroy", {
                              id: _vm.item.id,
                              parallel: false
                            })
                          }
                        }
                      },
                      [_vm._v(" Renverser la révision ")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.item
        ? _c(
            "div",
            { staticClass: "d-flex mb-4", staticStyle: { width: "100%" } },
            [
              _c(
                "div",
                { staticStyle: { width: "50%" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { "x-small": "", color: "qs-light-blue" } },
                    [_vm._v("mdi-send")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.campaign
                          ? _vm.item.campaign.name
                          : "Aucune campagne liée"
                      )
                  ),
                  _c("br"),
                  _c(
                    "v-icon",
                    { attrs: { "x-small": "", color: "qs-light-blue" } },
                    [_vm._v("mdi-crosshairs")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.item.instance
                          ? _vm.item.instance.name
                          : "Aucune instance liée"
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c("div", { staticStyle: { width: "50%" } }, [
                _vm._v(
                  " Créé par " + _vm._s(_vm.item.created_by_user.contact_name)
                ),
                _c("br"),
                _vm._v(
                  " le " + _vm._s(_vm._f("datetime")(_vm.item.created_at)) + " "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.item
        ? _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticStyle: { width: "50%" } }, [
              _c("pre", [_vm._v(_vm._s(_vm.item.before))])
            ]),
            _c("div", { staticStyle: { width: "50%" } }, [
              _c("pre", [_vm._v(_vm._s(_vm.item.after))])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }