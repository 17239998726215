var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-export-menu", {
              on: {
                export: function($event) {
                  return _vm.exportWithWarningAs($event)
                }
              }
            }),
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                order: _vm.filtersOrder,
                theme: "labase"
              },
              on: { input: _vm.setListParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("postal_codes", $event)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item.districts",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.districts
                              .map(function(d) {
                                return d.name
                              })
                              .join(", ")
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-view")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }