

























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class QsStatsCard extends Vue {
  @Prop({ type: String, required: false, default: 'grey' }) color!: string;
  @Prop({ type: String, required: false, default: 'mdi-account' }) icon!: string;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Number, required: true }) stat!: number;
  @Prop({ type: String, required: false, default: 'number' }) type!: string;
}
