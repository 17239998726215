var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "sms-campaign", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-3" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    color: "primary",
                    icon: "",
                    link: "",
                    tile: "",
                    to: "/sms"
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-menu-left")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "text-h6" }, [
                _vm._v("Retour à la liste")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.itemReady
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _c(
                    "qs-card",
                    { staticStyle: { "min-height": "100%" } },
                    [
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              order: [
                                "name",
                                "status",
                                "instance.id",
                                "start_at",
                                "end_at"
                              ],
                              item: _vm.item,
                              "model-name": _vm.slug
                            },
                            on: { input: _vm.syncItem }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "qs-card",
                    { staticStyle: { "min-height": "100%" } },
                    [
                      _vm.itemReady
                        ? _c("p", [
                            _vm._v(" contacts: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.item.contacts_count))
                            ]),
                            _vm._v(" / messages envoyés: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.item.sent_sms_count))
                            ]),
                            _vm._v(" / messages restants: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.item.unsent_sms_contacts_count))
                            ])
                          ])
                        : _vm._e(),
                      _vm.itemReady
                        ? _c("qs-form-builder", {
                            attrs: {
                              error: _vm.error,
                              form: _vm.form,
                              order: [
                                "initial_message",
                                "preformatted_replies"
                              ],
                              item: _vm.item,
                              "model-name": _vm.slug
                            },
                            on: { input: _vm.syncItem },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "preformatted_replies",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("qs-repeater-field", {
                                        attrs: {
                                          form: {
                                            name: {
                                              type: "text",
                                              rules: { required: true }
                                            },
                                            message: {
                                              type: "text",
                                              rules: { required: true }
                                            }
                                          },
                                          label: "Réponses",
                                          name: "preformatted_replies",
                                          order: ["name", "message"]
                                        },
                                        model: {
                                          value: item.preformatted_replies,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "preformatted_replies",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.preformatted_replies"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2850081800
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3" } },
                [
                  _vm.itemReady
                    ? _c("qs-form-builder", {
                        attrs: {
                          error: _vm.error,
                          form: _vm.form,
                          order: ["filter"],
                          item: _vm.item,
                          "model-name": _vm.slug
                        },
                        on: { input: _vm.syncItem },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "filter",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("qs-filters", {
                                    attrs: {
                                      "active-filters": item.filter,
                                      "filters-definition":
                                        _vm.contactsFiltersDefinition,
                                      flat: "",
                                      "model-name": "contacts",
                                      "with-search": false
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3229281221
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  staticStyle: { height: "40vh" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.contactsParams,
                  "filters-definition": _vm.contactsFiltersDefinition,
                  flat: "",
                  "model-name": "contacts"
                },
                on: { input: _vm.updateContactsParams }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c("qs-card", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Destinataires "), _c("v-spacer")]
                    },
                    proxy: true
                  },
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          [
                            _c("qs-data-table", {
                              attrs: {
                                actions: ["view"],
                                headers: _vm.contactsHeaders,
                                "item-class": _vm.getDataTableItemClass,
                                items: _vm.contacts,
                                loading:
                                  !_vm.routeDataLoaded || _vm.contactsLoading,
                                options: {},
                                "set-options": function() {},
                                total: _vm.contactsTotal || 0
                              },
                              on: {
                                view: function($event) {
                                  return _vm.$router.push(
                                    "/contacts/" + $event.id
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.sentSmsParams,
                  "filters-definition": _vm.sentSmsFiltersDefinition,
                  flat: "",
                  "model-name": "sent_sms"
                },
                on: { input: _vm.updateSentSmsParams }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c(
                "qs-card",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [_vm._v(" Échanges ")]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    [
                      _c("qs-data-table", {
                        attrs: {
                          headers: _vm.sentSmsHeaders,
                          items: _vm.sentSms,
                          loading: !_vm.routeDataLoaded || _vm.contactsLoading,
                          options: _vm.sentSmsOptions,
                          "set-options": _vm.setSentSmsOptions,
                          total: _vm.sentSmsTotal || 0
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.actions",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "info",
                                      icon: "",
                                      to:
                                        "/sms/" +
                                        _vm.$route.params.id +
                                        "/conversations/" +
                                        item.id
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-message")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("qs-confirmation-modal", {
        attrs: {
          value: _vm.sendDialog.value,
          color: _vm.sendDialog.color,
          icon: _vm.sendDialog.icon,
          loading: _vm.loading || _vm.sendDialog.loading,
          message: _vm.sendDialog.message,
          title: _vm.sendDialog.title
        },
        on: {
          "click:cancel": function() {
            _vm.sendDialog.value = false
          },
          "click:confirm": _vm.sendDialog.callback
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }