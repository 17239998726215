import { Module } from 'vuex';

import { MembershipsViewsState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const membershipsViews: Module<MembershipsViewsState, RootState> = {
  namespaced: true,
  state: {
    detailModal: false,
    options: {
      sortBy: ['transaction_id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    params: {
    },
  },
  getters: {
    detailModal(state) {
      return state.detailModal;
    },
    options(state) {
      return state.options;
    },
    params(state) {
      return state.params;
    },
  },
  mutations: {
    detailModal(state, value) {
      state.detailModal = value;
    },
    options(state, options) {
      state.options = options;
    },
    params(state, params) {
      state.params = params;
    },
  },
};

export default membershipsViews;
