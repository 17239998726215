import { Module } from 'vuex';

import { ContactsViewsState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const contactsView: Module<ContactsViewsState, RootState> = {
  namespaced: true,
  state: {
    params: {
      with_excluded: true,
      fields: [
        'address',
        'adopted_instances.name',
        'apartment',
        'birthdate',
        'city',
        'contribution_current_year',
        'contribution_last_year',
        'district.name',
        'dpa',
        'email',
        'first_name',
        'full_name',
        'gender',
        'home_phone',
        'last_contact_exchange.called_at',
        'last_contribution.date',
        'last_name',
        'postal_code',
        'status',
        'v1_contact_id',
      ].join(','),
      page: 1,
      per_page: 20,
    },
    selectedItems: [],
    showAlerts: true,
    showExtraInformation: true,
  },
  getters: {
    params(state) {
      return state.params;
    },
    selectedItems(state) {
      return state.selectedItems;
    },
    showAlerts(state) {
      return state.showAlerts;
    },
    showExtraInformation(state) {
      return state.showExtraInformation;
    },
  },
  mutations: {
    addSelectedItem(state, item) {
      if (!state.selectedItems.find((i) => i.id === item.id)) {
        state.selectedItems.push({
          ...item,
        });
      }
    },
    clearSelectedItems(state) {
      state.selectedItems.splice(0, state.selectedItems.length);
    },
    removeSelectedItem(state, item) {
      const index = state.selectedItems.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        state.selectedItems.splice(index, 1);
      }
    },
    setParams(state, params) {
      state.params = params;
    },
    showAlerts(state, showAlerts) {
      state.showAlerts = showAlerts;
    },
    showExtraInformation(state, showExtraInformation) {
      state.showExtraInformation = showExtraInformation;
    },
    setSelectedItems(state, items) {
      state.selectedItems.splice(
        0,
        state.selectedItems.length,
        ...items,
      );
    },
  },
};

export default contactsView;
