




































































































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactForm from '@/components/contacts/QsContactForm.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';
import QsTextField from 'qs_vuetify/src/components/Fields/QsTextField.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { Contact, PersistedContact } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import { ManualMembership } from '@/types/models';
import TransactionsForm from './TransactionsForm.vue';

const contacts: any = namespace('contacts');
const view: any = namespace('transactionsView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsContactForm,
    QsDateField,
    QsContactListItem,
    QsFormBuilder,
    QsRelationField,
    QsSplitButton,
    QsTextField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewTransactionsForm extends TransactionsForm {
  @contacts.Action('loadEmpty') contactsLoadEmpty!: any;
  @contacts.Getter('data') contactsData!: Contact[];
  @contacts.Getter('error') contactError!: ErrorResponse;
  @contacts.Getter('form') contactForm!: Form;
  @contacts.Getter('item') contactItem!: Contact;
  @contacts.Getter('loading') contactLoading!: boolean;

  @view.Getter transactionContactStatus!: string;
  @view.Mutation setTransactionContactStatus!: any;

  // contact data
  contactId = null;
  contactFields = [
    'address',
    'apartment',
    'birthdate',
    'city',
    'district.id',
    'district.name',
    'email',
    'emails',
    'first_name',
    'full_name',
    'gender',
    'home_phone',
    'last_name',
    'status',
    'postal_code',
    'searchable_text',
    'v1_contact_id',
  ];

  contactMethod = ''
  contactQuery = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      fields: [
        'age',
        'birthdate',
        'district.name',
        'email',
        'full_name',
        'postal_code',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
    },
  };

  step = 0;

  destroyed() {
    this.setTransactionContactStatus('existing');
    this.step = 0;
  }

  mounted() {
    this.resetMembership();
  }

  get canContinueToNextStep(): boolean {
    if (this.contactItem && 'id' in this.contactItem) {
      return true;
    }

    if (this.contactItem?.first_name
      && this.contactItem?.last_name
      && this.contactItem?.postal_code) {
      return true;
    }

    return false;
  }

  setGlobalTitle() {
    this.$store.commit('global/title', 'Nouvelle transaction');
  }

  async onQueriedContactInput(contact: PersistedContact | null) {
    if (contact) {
      await this.retrieveContact(contact.id);
      this.membership.contact_id = contact.id;
    } else {
      this.membership.contact_id = null;
    }
  }

  toggleContactStatusMode() {
    this.contactsLoadEmpty();
    this.resetMembership();
    if (this.transactionContactStatus === 'existing') {
      this.setTransactionContactStatus('new');
      return;
    }

    this.setTransactionContactStatus('existing');
  }

  @Watch('contactItem', { deep: true })
  onContactItemChanged(newContactItem: Contact) {
    if (newContactItem) {
      const contactData = (({
        address,
        apartment,
        birthdate,
        city,
        district,
        emails,
        first_name,
        gender,
        home_phone,
        last_name,
        postal_code,
      }) => ({
        address,
        apartment,
        birthdate,
        city,
        district,
        emails,
        first_name,
        gender,
        home_phone,
        last_name,
        postal_code,
      }))(newContactItem);
      this.$store.commit(`${this.slug}/membership`, {
        ...this.membership,
        ...contactData,
      });
    }
  }

  @Watch('membership', { deep: true })
  onMembershipChanged(membership: ManualMembership) {
    this.$store.commit(`${this.slug}/membership`, membership);
  }

  @Watch('routeDataLoaded')
  onNewTransactionsFormsRouteDataLoadedChanged() {
    setTimeout(this.setupForm, 500);
  }

  resetMembership() {
    this.$store.commit('transactions/membership', {
      // contact
      address: '',
      apartment: '',
      birthdate: null,
      city: '',
      contact_id: null,
      district: null,
      emails: [],
      first_name: '',
      gender: '',
      home_phone: '',
      last_name: '',
      postal_code: '',
      // membership
      duration: '1',
      renewal: 'manual',
      // transaction
      amount_in_cad: null,
      executed_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      external_id: null,
      payment_method: 'cash',
    });
  }

  async retrieveContact(id: string | number) {
    await this.$store.dispatch('contacts/retrieve', {
      id,
      fields: this.contactFields.join(','),
    });
  }

  setupForm() {
    const useSelected = this.$store.getters['transactionsView/useSelected'];
    if (useSelected) {
      this.$store.commit('transactionsView/setUseSelected', false);

      const contact = this.$store.getters['contactsView/selectedContacts'][0];
      this.$store.commit('contacts/item', contact);
    }
  }

  async submitMembership() {
    try {
      await this.$store.dispatch(`${this.slug}/membership`);

      this.$store.commit('global/addNotification', {
        color: 'success',
        message: 'Adhésion enregistrée.',
      });

      this.$router.push({ name: 'Transactions' });
    } catch (e) {
      if (e.status === 422) {
        this.$store.commit('global/addNotification', {
          color: 'error',
          message: 'Le formulaire est invalide. Veuillez valider les données.',
        });
        return;
      }

      this.$store.commit('global/addNotification', {
        color: 'error',
        message: this.$store.state.transactions.error?.message || "Une erreur s'est produite.",
      });
    }
  }
}
