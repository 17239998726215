































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsModerationForm from 'qs_vuetify/src/components/QsModerationForm.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';
import QsWarningIndicator from 'qs_vuetify/src/components/Indicators/QsWarningIndicator.vue';

import { Form } from 'qs_vuetify/src/types/components';
import {
  Contact,
  PersistedContact,
  PersistedImportable,
} from 'qs_vuetify/src/types/models';
import { RelationQueryDefinition } from 'qs_vuetify/src/types/states';

import Importable from '@/mixins/Importable';

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('importables/item', null);
    next();
  },
  components: {
    QsActionModal,
    QsButton,
    QsContactListItem,
    QsModerationForm,
    QsRelationField,
    QsSplitButton,
    QsWarningIndicator,
  },
  filters: {
    dgeqStatusColor(status: string) {
      switch (status) {
        case 'CFM':
          return 'green--darker';
        case 'VER':
          return 'warning';

        default:
          return 'error';
      }
    },
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContributionsImportablesForm extends mixins(Importable) {
  contactImportablesForm: Form = {
    first_name: { type: 'text' },
    last_name: { type: 'text' },
    gender: {
      nb: 1,
      type: 'enum',
      values: [null, 'female', 'male', 'other'],
    },
    birthdate: {
      description: 'AAAA-MM-JJ',
      mask: '####-##-##',
      type: 'text',
    },
    email: { type: 'email' },
    home_phone: { type: 'text' },
    apartment: { type: 'text' },
    address: { type: 'text' },
    city: { type: 'text' },
    postal_code: {
      mask: 'NNN NNN',
      transform: 'uppercase',
      type: 'text',
    },
  };

  contactQueryDefinition: RelationQueryDefinition & { key: string } = {
    key: 'data',
    slug: 'contacts',
    text: 'searchable_text',
    value: 'id',
    params: {
      fields: [
        'age',
        'birthdate',
        'district.name',
        'email',
        'full_name',
        'postal_code',
        'searchable_text',
        'status',
        'v1_contact_id',
      ].join(','),
      with_excluded: true,
    },
  }

  initialImportablesItem: PersistedContact | null = null;
  tentativeContact: PersistedContact | null = null;

  // eslint-disable-next-line class-methods-use-this
  get viewParams() {
    return {
      contacts: {
        fields: [
          'address',
          'age',
          'apartment',
          'birthdate',
          'city',
          'district.name',
          'email',
          'emails.email',
          'emails.weight',
          'full_name',
          'first_name',
          'gender',
          'home_phone',
          'last_name',
          'postal_code',
          'searchable_text',
          'status',
          'v1_contact_id',
        ].join(','),
        with_excluded: true,
      },
      'importables.index': {
        ...ListMixin.buildListState(this.importablesOptions, this.importablesParams),
        order: 'data->last_name,data->first_name,id',
      },
    };
  }

  destroyed() {
    this.syncContact(null);
  }

  buildContactFromDgeq(data: { [key: string]: any }): Contact {
    const {
      apartment_number: apartment,
      birthdate,
      civic_number,
      email,
      first_name,
      gender: rawGender,
      last_name,
      municipality: city,
      phone: home_phone,
      postal_code,
      street,
    } = data;

    const gender = this.dgeqToDbGender(rawGender);

    const addressPrefix = apartment ? `${apartment}-` : '';
    const address = `${addressPrefix}${civic_number}, ${street}`;

    return {
      id: this.importablesItem.id ? this.importablesItem.id : null,
      first_name,
      last_name,
      gender,
      birthdate,
      email,
      home_phone,
      apartment,
      address,
      city,
      postal_code,
    };
  }

  closeModal() {
    this.$router.push({
      name: 'ContributionsImportsForm',
      params: { id: this.$route.params.id },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  dgeqToDbGender(gender: string) {
    switch (gender) {
      case 'F':
        return 'female';

      case 'M':
        return 'male';

      default:
        return null;
    }
  }

  async syncIsConfirmedAndSave(value: boolean) {
    this.syncImportable({
      ...this.importablesItem,
      data: {
        ...this.importablesItem.data,
        is_confirmed: value,
      },
    });

    await this.saveImportable();
  }

  @Watch('importablesItem', { deep: true })
  async onImportableChanged(newItem: PersistedImportable) {
    if (newItem.data?.id) {
      await this.getContact();

      this.tentativeContact = { ...this.contact };
      if (!this.initialImportablesItem) {
        this.initialImportablesItem = { ...newItem.data };
      }
    } else {
      this.tentativeContact = null;
      await this.$store.dispatch('contacts/loadEmpty');
    }
  }
}
