

























































































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import axios from 'qs_vuetify/src/plugins/axios';
import dayjs from 'qs_vuetify/src/plugins/dayjs';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsModerationForm from 'qs_vuetify/src/components/QsModerationForm.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { Duplicate } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

import DuplicatesInteractions from '@/components/DuplicatesInteractions.vue';

import { duplicatesFields } from '@/store/views/index';

const comments: any = namespace('comments');
const duplicates: any = namespace('duplicates');
const global: any = namespace('global');
const view: any = namespace('duplicatesView');

@Component({
  components: {
    DuplicatesInteractions,
    QsActionModal,
    QsButton,
    QsConfirmationModal,
    QsContactListItem,
    QsFormBuilder,
    QsModerationForm,
  },
  filters: {
    customDate(date: string) {
      return dayjs(date).format('D MMM YYYY à H[h]mm');
    },
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class DuplicatesDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ErrorMixin,
  FormMixin,
  ListMixin,
) {
  @comments.Getter('loading') commentsLoading!: boolean;

  @global.Mutation addNotification!: Function;

  @duplicates.Getter error!: ErrorResponse;
  @duplicates.Getter form!: Form;
  @duplicates.Getter item!: Duplicate;
  @duplicates.Getter loading!: boolean;
  @duplicates.Getter slug!: string;
  @duplicates.Mutation('item') syncItem!: any;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  comment: string = '';

  contactDuplicatesDialog: Form = {
    first_name: { type: 'text' },
    last_name: { type: 'text' },
    gender: {
      nb: 1,
      type: 'enum',
      values: [null, 'female', 'male', 'other'],
    },
    birthdate: {
      description: 'AAAA-MM-JJ',
      mask: '####-##-##',
      type: 'text',
    },
    // email: { transform: 'uppercase', type: 'email' },
    home_phone: { type: 'text' },
    apartment: { type: 'text' },
    address: { type: 'text' },
    city: { type: 'text' },
    postal_code: {
      mask: 'NNN NNN',
      transform: 'uppercase',
      type: 'text',
    },
  };

  moderating = false;

  updateFormOrder = [
    'is_duplicate',
    'comment',
  ];

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'duplicates.index': {
        ...ListMixin.buildListState(this.options, this.params),
        fields: duplicatesFields,
      },
      duplicates: {
        fields: duplicatesFields,
      },
    };
  }

  mounted() {
    this.onRouteChanged();
    this.setAction();
  }

  afterSave() {
    this.addNotification({
      color: 'success',
      message: 'Doublon enregistré.',
      timeout: 2500,
    });
  }

  confirmAndMergeDuplicates(duplicateId: number | null) {
    this.confirm(
      '',
      'Cette action réattribue plusieurs relations du doublon au contact original.'
       + ' Elle est irréversible.',
      async () => {
        await this.moderateDuplicate(true);
        await this.$store.dispatch('duplicates/mergeDuplicates', {
          params: { id: duplicateId },
        });
        this.reloadDataRoutesData(['duplicates.retrieve']);
      },
      'warning',
      'mdi-alert',
    );
  }

  async moderateDuplicate(is_duplicate: boolean) {
    this.moderating = true;

    try {
      await axios.put(`/duplicates/${this.item.id}/moderate`, {
        is_duplicate,
      });

      this.addNotification({
        color: 'success',
        message: 'Modération consignée.',
        timeout: 2500,
      });
      this.reloadDataRoutesData(['duplicates.retrieve']);
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: 'Erreur lors de la modération.',
        timeout: 2500,
      });
    } finally {
      this.moderating = false;
    }
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
      this.setGlobalTitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.$store.commit('global/subtitle', 'Duplicates');
  }

  async postComment(text: string) {
    await this.$store.dispatch('comments/create', {
      data: { text },
      params: {
        prefix: `/duplicates/${this.item.id}`,
      },
    });
    this.reloadDataRoutesData(['duplicates.retrieve']);

    this.comment = '';
  }

  setAction() {
    if (this.userHas('DUPLICATES_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/duplicates/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalTitle() {
    if (this.isNew) {
      this.$store.commit('global/title', 'Nouveau doublon');
    } else {
      this.$store.commit('global/title', `Doublon #${this.item.id}`);
    }
  }
}
