






























































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { Contribution } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

const global: any = namespace('global');
const store: any = namespace('contributions');
const view: any = namespace('contributionsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contributions/item', null);
    next();
  },
  components: {
    QsActionModal,
    QsButton,
    QsContactListItem,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContributionModal extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Mutation addNotification!: Function;

  @store.Getter error!: ErrorResponse;
  @store.Getter item!: Contribution;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  afterSave() {
    this.addNotification({
      message: 'Contribution enregistrée avec succès.',
      color: 'success',
    });
  }

  formOrder = [
    'contact_id',
    'date',
    'district_id',
    'affected_to_instance',
    'instance_id',
    'contribution_type_id',
  ];

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'contributions.index': {
        ...ListMixin.buildListState(this.options, this.params),
      },
      contributions: {
        fields: ContributionModal.FIELDS,
      },
    };
  }

  static FIELDS = [
    'affected_to_instance',
    'amount_in_cad',
    'contact.age',
    'contact.birthdate',
    'contact.district.name',
    'contact.email',
    'contact.full_name',
    'contact.home_phone',
    'contact.postal_code',
    'contact.status',
    'contact.v1_contact_id',
    'contact_full_name',
    'contact_v1_contact_id',
    'contribution_type.name',
    'contributino_type_id',
    'date',
    'deposit_date',
    'dgeq_instance_name',
    'district.name',
    'instance_id',
    'instance.name',
    'transaction.executed_at',
    'transaction.external_id',
    'transaction.status',
    'transaction.type',
    'transaction_id',
    'transaction_id',
  ].join(',');
}
