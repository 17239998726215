var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-export-menu", {
              on: {
                export: function($event) {
                  return _vm.exportWithWarningAs($event)
                }
              }
            }),
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                order: _vm.filtersOrder,
                theme: "labase"
              },
              on: { input: _vm.setListParam }
            }),
            _c("qs-stats-card", {
              staticClass: "mt-3 mb-3",
              attrs: {
                color: "blue",
                label: "Correspondant au filtre",
                icon: "mdi-sigma",
                stat: _vm.total || 0
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("phones", $event)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("status")(item.status, "phones")) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.type",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.type
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("enumVal")(item.type, "phones", "type")
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.contacts_count",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.contacts
                        ? [_vm._v(_vm._s(item.contacts.length))]
                        : [_vm._v("0")]
                    ]
                  }
                }
              ])
            }),
            _c("router-view")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }