var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    attrs: { "content-class": "sandbox" },
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c(
              "qs-card",
              {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Test icônes ")]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center py-2" },
                  [
                    _c(
                      "v-icon",
                      { attrs: { color: "qs-dark-blue", large: "" } },
                      [_vm._v(_vm._s(_vm.icon))]
                    )
                  ],
                  1
                ),
                _c("v-text-field", {
                  attrs: { label: "Test icône" },
                  model: {
                    value: _vm.icon,
                    callback: function($$v) {
                      _vm.icon = $$v
                    },
                    expression: "icon"
                  }
                })
              ],
              1
            ),
            _c(
              "qs-card",
              {
                staticClass: "mt-1",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" Étiquette ")]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center py-2" },
                  [
                    _c(
                      "v-chip",
                      { attrs: { color: "qs-light-blue", label: "" } },
                      [
                        _c("v-icon", { attrs: { color: "qs-dark-blue" } }, [
                          _vm._v("mdi-tag")
                        ]),
                        _vm.tag
                          ? _c("span", [_vm._v(_vm._s(_vm.tag.name))])
                          : _c("span", [_vm._v("Choisir une étiquette")])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("qs-relation-field", {
                  attrs: {
                    item: { tag: _vm.tag },
                    label: "Choisir une étiquette",
                    name: "tag_id",
                    query: _vm.tagsQueryDef
                  },
                  on: {
                    input: function($event) {
                      _vm.tag = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [_c("data-entry-table", { attrs: { tag: _vm.tag } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }