var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { loading: _vm.loading, flat: "", tile: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "flex-grow-1 text-h2 text-uppercase" },
        [
          _c(
            "v-icon",
            { staticClass: "qs-dark-blue--text mr-2", attrs: { large: "" } },
            [_vm._v("mdi-crosshairs")]
          ),
          _vm._v(" Instances "),
          _c("v-spacer"),
          _c(
            "qs-button",
            { attrs: { theme: "labase" }, on: { click: _vm.updateInstances } },
            [_c("v-icon", [_vm._v("mdi-refresh")])],
            1
          )
        ],
        1
      ),
      _c("v-card-subtitle", [_vm._v(" Listes où le contact est visible ")]),
      _c("v-treeview", {
        attrs: {
          "expand-icon": "",
          items: _vm.instances,
          "open-all": "",
          "open-on-click": ""
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.name) + " "),
                item.children && item.children.length
                  ? _c(
                      "v-chip",
                      { attrs: { color: "qs-dark-blue", dark: "", small: "" } },
                      [_vm._v(" +" + _vm._s(item.children.length) + " ")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }