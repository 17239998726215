var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "contributions-importables-form",
        height: "80vh",
        "max-width": "950",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm.importablesItem
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mr-1 mb-0 mt-1",
                                        attrs: {
                                          color: _vm._f("statusColor")(
                                            _vm.importablesItem.status
                                          )
                                        }
                                      },
                                      on
                                    ),
                                    [_vm._v(" mdi-radiobox-marked ")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3514430474
                        )
                      },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("status")(
                                  _vm.importablesItem.status,
                                  "importables"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" Modifier l'importable ")
              ]
            },
            proxy: true
          },
          _vm.importablesItem && _vm.importablesItem.data
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm._v(
                      " Contribution # " +
                        _vm._s(_vm.importablesItem.data.contribution_number) +
                        " "
                    )
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _vm.contact && _vm.importablesItem
                  ? _c("div", { staticClass: "d-flex flex-grow-1" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center flex-grow-1" },
                        [
                          _c(
                            "qs-button",
                            {
                              attrs: {
                                loading:
                                  _vm.$store.getters["importables/loading"],
                                theme: "labase"
                              },
                              on: { click: _vm.saveImportable }
                            },
                            [_vm._v(" Enregistrer l'importable ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center flex-grow-1" },
                        [
                          _vm.contact && _vm.contact.id
                            ? _c(
                                "qs-split-button",
                                {
                                  attrs: {
                                    loading:
                                      _vm.$store.getters["contacts/loading"],
                                    "menu-disabled": !_vm.canContactBeCreated(
                                      _vm.contact
                                    ),
                                    theme: "labase"
                                  },
                                  on: { "click:main": _vm.saveContact },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "main-button",
                                        fn: function() {
                                          return [
                                            _vm._v(" Enregistrer le contact ")
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2180678132
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        { on: { click: _vm.createContact } },
                                        [_vm._v(" Créer un nouveau contact ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "qs-button",
                                {
                                  attrs: {
                                    disabled: !_vm.canContactBeCreated(
                                      _vm.contact
                                    ),
                                    loading:
                                      _vm.$store.getters["contacts/loading"],
                                    theme: "labase"
                                  },
                                  on: { click: _vm.createContact }
                                },
                                [_vm._v(" Créer le contact ")]
                              )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.contact && _vm.importablesItem
        ? _c(
            "div",
            { staticClass: "contributions-importables-form__content" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "contributions-importables-form__content__confirmed my-3 d-flex flex-row justify-space-around"
                },
                [
                  _c("v-switch", {
                    attrs: {
                      label: "Traité",
                      "hide-details": "",
                      "input-value": _vm.importablesItem.data.is_confirmed
                    },
                    on: { change: _vm.syncIsConfirmedAndSave }
                  }),
                  _c(
                    "qs-button",
                    {
                      staticClass: "align-self-center",
                      attrs: {
                        color: "warning",
                        disabled:
                          !_vm.importablesItem.data.id ||
                          _vm.$store.getters["importables/loading"],
                        loading: _vm.importImportableLoading,
                        theme: "labase"
                      },
                      on: {
                        click: function($event) {
                          return _vm.putImportImportable(_vm.importablesItem)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-refresh")]),
                      _vm._v(" Importer la contribution ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "title mb-3" }, [
                        _vm._v("Données sources")
                      ]),
                      _c(
                        "qs-warning-indicator",
                        {
                          attrs: {
                            color: _vm._f("dgeqStatusColor")(
                              _vm.importablesItem.data.contribution_status
                            ),
                            icon:
                              _vm.importablesItem.data.contribution_status ===
                              "CFM"
                                ? "mdi-check"
                                : "mdi-alert"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("enumVal")(
                                  _vm.importablesItem.data.contribution_status,
                                  "importables",
                                  "contribution_status"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _vm.importablesItem.data.recurring_payment === "O"
                        ? _c(
                            "qs-warning-indicator",
                            {
                              attrs: {
                                color: "green--darker",
                                icon: "mdi-credit-card-clock"
                              }
                            },
                            [
                              _vm._v(
                                " Débit pré-autorisé (" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.importablesItem.data.contribution_amount.replace(
                                        /,/,
                                        "."
                                      )
                                    )
                                  ) +
                                  ") depuis le " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.importablesItem.data.debit_start_date
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "qs-warning-indicator",
                            {
                              attrs: {
                                color: "green--darker",
                                icon: "mdi-currency-usd"
                              }
                            },
                            [
                              _vm._v(
                                " Contribution unique (" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.importablesItem.data.contribution_amount.replace(
                                        /,/,
                                        "."
                                      )
                                    )
                                  ) +
                                  ") "
                              )
                            ]
                          ),
                      _vm.importablesItem.data
                        .membership_or_political_activity_deposited === "O"
                        ? _c(
                            "qs-warning-indicator",
                            { attrs: { color: "green--darker" } },
                            [
                              _vm._v(
                                " Adhésion (" +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.importablesItem.data.membership_amount.replace(
                                        /,/,
                                        "."
                                      )
                                    )
                                  ) +
                                  ") "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.importablesItem.data.flags
                        ? _vm._l(_vm.importablesItem.data.flags, function(
                            flag
                          ) {
                            return _c(
                              "qs-warning-indicator",
                              { key: flag, attrs: { color: "error" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("enumVal")(
                                        flag,
                                        "importables",
                                        "flags"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("div", { staticClass: "title mb-3" }, [
                        _vm._v("Correspondance")
                      ]),
                      _c("qs-relation-field", {
                        attrs: {
                          item: { contact: _vm.tentativeContact },
                          label: "Contact",
                          name: "contact_id",
                          value: _vm.tentativeContact,
                          query: _vm.contactQueryDefinition
                        },
                        on: { input: _vm.updateContact },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("qs-contact-list-item", {
                                    attrs: { item: item }
                                  })
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("qs-contact-list-item", {
                                    attrs: { item: item }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4100176732
                        )
                      }),
                      _vm.importablesItem &&
                      _vm.initialImportablesItem &&
                      _vm.initialImportablesItem.id ===
                        _vm.importablesItem.data.id &&
                      _vm.importablesItem.data.matching_method &&
                      _vm.importablesItem.status !== "completed"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "body-2 mb-0",
                              attrs: {
                                border: "left",
                                type: "info",
                                outlined: "",
                                tile: ""
                              }
                            },
                            [
                              _vm._v(
                                " Contact " +
                                  _vm._s(
                                    _vm.importablesItem.data.id
                                      ? "trouvé"
                                      : "cherché"
                                  ) +
                                  " par "
                              ),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("enumVal")(
                                        _vm.importablesItem.data
                                          .matching_method,
                                        "importables",
                                        "matching_method"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.importablesItem.data.id
                                      ? ""
                                      : "sans succès"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.importablesItem
                        ? _c("qs-moderation-form", {
                            attrs: {
                              form: _vm.contactImportablesForm,
                              "left-value": _vm.buildContactFromDgeq(
                                _vm.importablesItem.data
                              ),
                              "model-name": "contacts",
                              "right-value": _vm.contact,
                              "show-missing-fields": ""
                            },
                            on: { "update:right-value": _vm.syncContact }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: "qs-light-blue darken-3",
                                          small: ""
                                        }
                                      },
                                      [_vm._v("mdi-code-json")]
                                    ),
                                    _vm._v(
                                      " Données brutes aux fins de déboguage "
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("v-expansion-panel-content", [
                                _c("pre", [
                                  _vm._v(_vm._s(_vm.importablesItem.data))
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  staticStyle: { height: "60vh" },
                  attrs: { cols: "12" }
                },
                [
                  _c("p", [_vm._v("Récupération des infos du contact")]),
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }