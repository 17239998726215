



































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Model, Email } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const emails: any = namespace('emails');
const view: any = namespace('emailsView');

@Component({
  components: {
    QsContactListItem,
    QsDataTable,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
class Emails extends mixins(
    AuthenticationMixin,
    DataRouteGuards,
    ListMixin,
  ) {
  @emails.Getter data!: Array<Email>;
  @emails.Getter filtersDefinition!: FiltersDefinition;
  @emails.Getter loading!: boolean;
  @emails.Getter total!: number;
  @emails.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setParams!: any;

  get viewParams(): { [key: string]: RestParams } {
    return {
      'emails.index': ListMixin.buildListState(this.options, this.params),
    };
  }

  setEmailsParam = this.buildSetParam('emails', this.setParams);

  headers = [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Courriel', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
}

interface Emails extends AuthenticationMixin, DataRouteGuards, ListMixin<Model> {}

export default Emails;
