









































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { AutomatedEmailTemplate } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('automated_email_templates');
const view: any = namespace('automatedEmailTemplatesView');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class AutomatedEmailTemplateDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  InteractsWithNavbar,
  FormMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: AutomatedEmailTemplate;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  destroyed() {
    this.syncItem(null);
    this.commitGlobalSubtitle('');
  }

  mounted() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      'automated_email_templates.index': ListMixin.buildListState(this.options, this.params),
    };
  }

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Gabarit de courriel automatisé enregistré.',
    });

    this.reloadDataRoutesData(['automated_email_templates.index'], true);

    this.closeModal();
  }


  closeModal() {
    this.$router.push({ name: 'AutomatedEmailTemplates' });
  }

  setGlobalActions() {
    if (this.userHas('AUTOMATED_EMAIL_TEMPLATES_CREATE')) {
      this.commitGlobalActions([
        {
          onClick: () => {
            this.$router.push('/phones/new');
          },
          color: 'primary',
          disabled: true,
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalSubtitle() {
    if (this.isNew) {
      this.commitGlobalSubtitle('Nouveau');
    } else if (!this.itemReady) {
      this.commitGlobalSubtitle('Chargement...');
    } else {
      this.commitGlobalSubtitle(this.item?.name);
    }
    this.$emit('updateHead');
  }

  @Watch('$route', { deep: true })
  onAutomatedEmailTemplateDialogRouteChanged() {
    this.setGlobalActions();
    this.setGlobalSubtitle();
  }
}
