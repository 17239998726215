











































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import { FiltersDefinition, RestParam, RestParams } from 'qs_vuetify/src/types/states';
import { Instance } from 'qs_vuetify/src/types/models';
import { DataTableOptions } from 'qs_vuetify/src/types/components';

import BaseLayout from '@/components/Layout/BaseLayout.vue';
import { Watch } from 'vue-property-decorator';

const store: any = namespace('instances');
const view: any = namespace('instancesViews');

@Component({
  components: {
    BaseLayout,
    QsBooleanIndicator,
    QsExportMenu,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class DelegationReport extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  ListMixin,
) {
  @store.Getter data!: Instance[];
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setParams!: any;

  filtersOrder = [
    'instance_type.id',
    'region.id',
    'is_active',
  ];

  reportType: string = 'congress';

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get tableHeaders() {
    return [
      { text: 'Active', value: 'is_active' },
      { text: 'Instance', value: 'name' },
      { text: 'Membres', value: 'delegation_stats.members_count' },
      { text: 'Membres délégables', value: 'delegation_stats.delegatable_members_count' },
      {
        text: 'Délégué·es',
        value: `delegation_stats.${this.reportType}_delegates`,
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      instances: ListMixin.buildListState(this.options, this.params),
    };
  }

  mounted() {
    this.$store.commit('instances/exportFields', [
      'name',
      'region.name',
      'instance_type.name',
      'is_active',
      'members_count',
      `${this.reportType}_delegates`,
    ]);
  }

  updateFilters(name: string, value: RestParam): void {
    const { params } = this;
    const newParams: RestParams = { ...params };
    if (value) {
      newParams[name] = value;
    } else {
      delete newParams[name];
    }
    newParams.page = 1;

    this.setParams(newParams);
    this.reloadDataRoutesData(['instances']);
  }

  @Watch('reportType')
  onReportTypeChanged() {
    this.$store.commit('instances/exportFields', [
      'id',
      'name',
      'region.name',
      'instance_type.name',
      'is_active',
      'members_count',
      `${this.reportType}_delegates`,
    ]);
  }
}
