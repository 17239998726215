var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        height: "80vh",
        "max-width": "800",
        value: true
      },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "SentEmailsList" })
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              !_vm.item
                ? _c("span", [_vm._v(" Envoi courriel ")])
                : _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "models.sent_emails.enums.mailable_class." +
                              _vm.clean(_vm.item.mailable_class)
                          )
                        ) +
                        " "
                    )
                  ])
            ]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              !_vm.mailCampaign
                ? [
                    _vm._v(" Envoyé à "),
                    _vm.item.email
                      ? _c("strong", { staticClass: "mx-1" }, [
                          _vm._v(_vm._s(_vm.item.email))
                        ])
                      : _c("strong", { staticClass: "mx-1" }, [
                          _vm._v("courriel introuvable")
                        ]),
                    _vm._v(
                      " le " +
                        _vm._s(_vm._f("datetime")(_vm.item.sent_at)) +
                        " "
                    )
                  ]
                : [_vm._v(" " + _vm._s(_vm.mailCampaign.name) + " ")]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.item.trackable_sent_email
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c("qs-warning-indicator", {
                attrs: {
                  color: _vm.isBounced ? "error" : "success",
                  icon: _vm.isBounced
                    ? "mdi-alert-circle-outline"
                    : "mdi-check-circle-outline",
                  text: _vm.isBounced ? "Rebond" : "Courriel livré"
                }
              }),
              _c("qs-warning-indicator", {
                attrs: {
                  color: _vm.isOpened ? "success" : "warning",
                  icon: _vm.isOpened
                    ? "mdi-email-open-outline"
                    : "mdi-email-outline",
                  text: _vm.isOpened ? "Ouvert" : "Pas ouvert"
                }
              }),
              _c("qs-warning-indicator", {
                attrs: {
                  color: _vm.isClicked ? "success" : "warning",
                  icon: _vm.isClicked
                    ? "mdi-cursor-default-click-outline"
                    : "mdi-cursor-default-outline",
                  text: _vm.isClicked ? "Cliqué" : "Pas cliqué"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.mailCampaign
        ? _c(
            "div",
            { staticClass: "mail-content" },
            [
              _c(
                "v-list",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "d-flex font-weight-bold" },
                            [
                              _vm.mailCampaign.subject
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.mailCampaign.subject))
                                  ])
                                : _c(
                                    "span",
                                    { staticClass: "mail-content__subject" },
                                    [_vm._v("Objet vide")]
                                  ),
                              _c("v-spacer"),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("datetime")(_vm.item.sent_at)
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "qs-dark-blue--text" },
                            [
                              _vm._v(" De : "),
                              _c(
                                "span",
                                { staticClass: "mail-content__from" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.mailCampaign.instance.name)
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    " (" +
                                      _vm._s(_vm.mailCampaign.instance.email) +
                                      ") "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "qs-dark-blue--text" },
                            [
                              _vm._v(" À : "),
                              _c("span", { staticClass: "mail-content__to" }, [
                                _vm.item.contact
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.item.contact.full_name)
                                          )
                                        ]
                                      ),
                                      _vm._v(" ( ")
                                    ])
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.item.email) + " "),
                                _vm.item.contact
                                  ? _c("span", [_vm._v(")")])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticClass: "mail-content__body body-2",
                domProps: { innerHTML: _vm._s(_vm.mailCampaign.body) }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }