






















































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactForm from '@/components/contacts/QsContactForm.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsLargeChoice from '@/components/QsLargeChoice.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';
import QsSplitButton from 'qs_vuetify/src/components/Buttons/QsSplitButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { Transaction } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

import { ManualMembership } from '@/types/models';

const contacts: any = namespace('contacts');
const global: any = namespace('global');
const transactions: any = namespace('transactions');
const transactionsView: any = namespace('transactionsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('transactions/item', null);
    this.$store.commit('transactions/lastLoadedAt', null);
    next();
  },
  components: {
    QsActionModal,
    QsButton,
    QsContactForm,
    QsDateField,
    QsContactListItem,
    QsFormBuilder,
    QsLargeChoice,
    QsRelationField,
    QsSplitButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class TransactionsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ErrorMixin,
  FormMixin,
  I18nMixin,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @contacts.Action addMemberships!: (arg: {
    contactId: number;
    duration: number;
    transactionId: number;
    periodStart: string;
  }) => Promise<any>;

  @transactions.Getter error!: ErrorResponse;
  @transactions.Getter initialItem!: string;
  @transactions.Getter item!: Transaction;
  @transactions.Getter form!: Form;
  @transactions.Getter loading!: boolean;
  @transactions.Getter membership!: ManualMembership;
  @transactions.Getter slug!: string;
  @transactions.Mutation('item') syncItem!: any;
  @transactions.Mutation('loading') setLoading!: (arg: boolean) => void;

  @transactionsView.Getter options!: DataTableOptions;
  @transactionsView.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  formOrder = [
    'status',
    'contact_id',
    'amount_in_cad',
    'external_id',
    'type',
    'comments',
  ];

  mounted() {
    this.onRouteChanged();
    this.setAction();
    this.$store.commit('contacts/item', null);
  }

  get addMembershipButtonLabel(): string {
    if (!this.item?.contact) {
      return 'Associez un contact avant de poursuivre';
    }

    if (this.hasChanged) {
      return 'Sauvegardez avant de continuer';
    }

    return "S'ajoute à la date d'exécution de la transaction ou après la dernière adhésion active du contact";
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'transactions.index': {
        ...ListMixin.buildListState(this.options, this.params),
      },
      transactions: {
        fields: [
          '*',
          'contact.full_name',
          'contact.district.name',
          'contact.email',
          'contact.status',
          'contact.v1_contact_id',
          'contributions.*',
          'memberships.*',
        ].join(','),
      },
    };
  }

  afterSave() {
    this.$store.commit('transactions/lastLoadedAt', null);
    this.reloadDataRoutesData(['transactions.index']);

    this.addNotification({
      message: 'Transaction enregistrée avec succès',
      color: 'success',
    });
  }

  closeModal() {
    this.$router.push({ name: 'Transactions' });
  }

  async destroyMembership(id: number) {
    await this.$store.dispatch('memberships/destroy', { id });
    this.reloadDataRoutesData(['transactions.retrieve'], true);
  }

  async dispatchAddMemberships(duration: number) {
    if (!this.item?.contact?.id) {
      return;
    }

    if (!this.item?.id) {
      return;
    }

    if (!this.item?.executed_at) {
      return;
    }

    this.setLoading(true);
    await this.addMemberships({
      contactId: this.item.contact.id,
      duration,
      transactionId: this.item.id,
      periodStart: this.item.executed_at,
    });
    this.setLoading(false);
    this.reloadDataRoutesData(['transactions.retrieve'], true);
  }

  setAction() {
    if (this.userHas('TRANSACTIONS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/transactions/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setGlobalTitle() {
    this.$store.commit('global/title', `Transaction #${this.item.id}`);
  }

  async submitForm() {
    const {
      contact, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...transactionExceptContact
    } = this.item;

    await this.$store.dispatch(`${this.slug}/update`, {
      id: this.item.id,
      data: transactionExceptContact,
      params: {
        ...this.params,
        ...((this.viewParams && this.viewParams[this.slug]) || {}),
      },
    });
  }

  @Watch('item', { deep: true })
  onItemChangedOverride(newItem: Transaction, oldItem: Transaction) {
    if (JSON.stringify(newItem) !== JSON.stringify(oldItem)) {
      let item = newItem;
      if (item.memberships) {
        const memberships = item.memberships.map((m) => ({
          ...m,
          contact_id: item.contact_id,
        }));

        item = {
          ...item,
          memberships,
        };
      }
      this.$store.commit(`${this.slug}/item`, item);
    }
  }

  @Watch('routeDataLoaded')
  onTransactionsFormRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
      this.setGlobalTitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.$store.commit('global/subtitle', 'Transactions');
  }
}
