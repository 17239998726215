var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false, value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _vm.isNew
                  ? _c("span", [
                      _vm._v("Créer un gabarit de courriel automatisé")
                    ])
                  : _c("span", [
                      _vm._v("Modifier le gabarit de courriel automatisé")
                    ])
              ]
            },
            proxy: true
          },
          _vm.item
            ? {
                key: "subtitle",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      disabled:
                        (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                      theme: "labase"
                    },
                    on: { click: _vm.submit }
                  },
                  [
                    _vm.isNew
                      ? _c("span", [_vm._v("Créer")])
                      : _c("span", [_vm._v("Enregistrer")])
                  ]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady
        ? _c("qs-form-builder", {
            attrs: {
              error: _vm.error,
              form: _vm.form,
              item: _vm.item,
              "model-name": _vm.slug,
              order: ["name", "subject", "description", "template"]
            },
            on: { input: _vm.syncItem }
          })
        : _c(
            "div",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }