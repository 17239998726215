














































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsUserChip from 'qs_vuetify/src/components/QsUserChip.vue';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { PersistedRevision } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import BaseLayout from '../../components/Layout/BaseLayout.vue';

import InteractsWithNavbar from '../../mixins/InteractsWithNavbar';

const global: any = namespace('global');
const revisions: any = namespace('revisions');
const view: any = namespace('revisionsViews');

@Component({
  components: {
    BaseLayout,
    QsContactListItem,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    QsUserChip,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RevisionsList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  InteractsWithNavbar,
  ListMixin,
) {
  @global.Mutation addNotification!: Function;
  @global.Mutation removeNotification!: Function;

  @revisions.Getter data!: PersistedRevision[];
  @revisions.Getter exportUrl!: string;
  @revisions.Getter filtersDefinition!: FiltersDefinition;
  @revisions.Getter loading!: boolean;
  @revisions.Getter total!: number;
  @revisions.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: PersistedRevision[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  setListParams = this.buildSetParam('revisions', this.setParams);

  filtersOrder = [
    'should_moderate',
    'target_type',
    'target_id',
    'campaign.id',
    'instance.id',
    'created_by_user.id',
    'updated_by_user.id',
    'type',
  ];

  headers = [
    { text: 'ID', value: 'id', sortable: true },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Modèle', value: 'target_type', sortable: false },
    { text: 'Généré par', value: 'created_by_user', sortable: false },
    { text: 'Horodatage', value: 'created_at', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  get viewParams() {
    return {
      revisions: {
        ...ListMixin.buildListState(this.options, this.params),
        fields: [
          'created_by_user.contact_name',
          'created_at',
          'reverted_at',
          'target_id',
          'target_type',
          'type',
        ].join(','),
        order: '-id',
      },
    };
  }

  mounted() {
    this.$store.commit('revisions/exportFields', [
      'id',
      'before',
      'after',
    ]);
  }

  i18nalizeValue(name: string, key: string) {
    const cleanKey = key.replace(/\.|\\/g, '_').toLowerCase();
    const target = `models.${this.slug}.enums.${name}.${cleanKey}`;

    if (this.$i18n.te(target)) {
      return this.$i18n.tc(target, 1);
    }

    return key;
  }

  // eslint-disable-next-line class-methods-use-this
  targetSlug(item: PersistedRevision) {
    if (!item) {
      return null;
    }

    const parts = item.target_type.split('\\');

    return `${parts[parts.length - 1].toLowerCase()}s`;
  }
}
