import Component, { mixins } from 'vue-class-component';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { RestParams } from 'qs_vuetify/src/types/states';
import { Watch } from 'vue-property-decorator';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

@Component
export default class OptionsLazyLoadable extends mixins(DataRouteGuards, ListMixin) {
  options!: DataTableOptions;
  params!: RestParams
  setOptions!: any;
  slug!: string;

  loadFirstPage() {
    this.resetData();
    this.setOptions({
      ...this.options,
      page: 1,
    });

    this.reloadDataRoutesData([this.slug]);
  }

  loadNextPage() {
    this.setOptions({
      ...this.options,
      page: this.options.page + 1,
    });

    this.$store.dispatch(`${this.slug}/loadPage`, ListMixin.buildListState(this.options, this.params));
  }

  @Watch('params', { deep: true })
  reloadFirstPage() {
    this.loadFirstPage();
  }

  resetData(): void {
    this.$store.commit(`${this.slug}/data`, []);
    this.$store.commit(`${this.slug}/loaded`, false);
    this.$store.commit(`${this.slug}/lastLoadedAt`, null);
    this.$store.commit(`${this.slug}/total`, null);
  }
}
