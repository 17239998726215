


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import axios from 'qs_vuetify/src/plugins/axios';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import { Instance, PersistedInstance } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    QsButton,
  },
})
export default class QsContactInstancesSection extends Vue {
  @Prop({ required: true }) id!: number | string;

  instances: PersistedInstance[] = [];
  loading: boolean = false;

  mounted() {
    this.fetchInstances();
  }

  @Watch('id')
  async fetchInstances() {
    if (this.id) {
      this.loading = true;

      try {
        const ajax = axios.get(`/contacts/${this.id}`, {
          params: {
            fields: 'instances.name,instances.parent.name,instances.parent.parent.name',
            per_page: -1,
          },
        });

        const { data: { instances } } = await ajax;
        // this.instances = [...instances];

        const accumulator: any[] = [];
        const sorted = instances.reduce((acc: any[], item: Instance) => {
          if (item.parent) {
            if (!acc.find((i) => i.id === item.parent.id)) {
              const parent = {
                id: item.parent.id,
                children: instances
                  .filter((i: Instance) => i.parent && i.parent.id === item.parent.id)
                  .map((i: Instance) => ({ id: i.id, children: [], name: i.name })),
                name: item.parent.name,
              };

              acc.push(parent);
            }
          } else {
            acc.push({
              id: item.id,
              children: instances
                .filter((i: Instance) => i.parent && i.parent.id === item.id)
                .map((i: Instance) => ({ id: i.id, children: [], name: i.name })),
              name: item.name,
            });
          }

          return acc;
        }, accumulator);

        this.instances = [...sorted];
      } catch (e) {
        this.$emit('error', e);
      } finally {
        this.loading = false;
      }
    }
  }

  async updateInstances() {
    await this.$store.dispatch('contacts/updateInstanceContactsJoinTable', {
      id: this.id,
    });

    this.fetchInstances();
  }
}
