var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contacts-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3" } },
            [
              _c("qs-export-menu", {
                on: {
                  export: function($event) {
                    return _vm.exportWithWarningAs($event)
                  }
                }
              }),
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  flat: "",
                  "model-name": _vm.slug,
                  theme: "labase",
                  "with-search": false
                },
                on: { input: _vm.setContactsParam },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function(ref) {
                      var updateFilter = ref.updateFilter
                      return [
                        _c(
                          "v-card-text",
                          [
                            _c("qs-text-field", {
                              attrs: {
                                clearable: "",
                                filled: "",
                                "hide-details": "",
                                label: "Prénom",
                                name: "first_name",
                                value:
                                  "first_name" in _vm.params
                                    ? _vm.params.first_name
                                    : ""
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("first_name", $event)
                                }
                              }
                            }),
                            _c("qs-text-field", {
                              attrs: {
                                clearable: "",
                                filled: "",
                                "hide-details": "",
                                label: "Nom",
                                name: "last_name",
                                value:
                                  "last_name" in _vm.params
                                    ? _vm.params.last_name
                                    : ""
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("last_name", $event)
                                }
                              }
                            }),
                            _c("qs-text-field", {
                              attrs: {
                                clearable: "",
                                filled: "",
                                "hide-details": "",
                                label: "Courriel",
                                name: "emails.email",
                                value:
                                  "emails.email" in _vm.params
                                    ? _vm.params["emails.email"]
                                    : ""
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("emails.email", $event)
                                }
                              }
                            }),
                            _c("qs-text-field", {
                              attrs: {
                                clearable: "",
                                filled: "",
                                "hide-details": "",
                                label: "Numéro de membre",
                                name: "v1_contact_id",
                                value:
                                  "v1_contact_id" in _vm.params
                                    ? _vm.params.v1_contact_id
                                    : ""
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("v1_contact_id", $event)
                                }
                              }
                            }),
                            _c("qs-text-field", {
                              attrs: {
                                clearable: "",
                                filled: "",
                                "hide-details": "",
                                label: "Code postal",
                                name: "postal_code",
                                value:
                                  "postal_code" in _vm.params
                                    ? _vm.params.postal_code
                                    : ""
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("postal_code", $event)
                                }
                              }
                            }),
                            _c("qs-date-field", {
                              attrs: {
                                filled: "",
                                label: "Date de naissance",
                                name: "birthdate",
                                value: _vm.params.birthdate
                              },
                              on: {
                                input: function($event) {
                                  return updateFilter("birthdate", $event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", lg: "9" } },
            [
              _c("qs-list", {
                attrs: {
                  items: _vm.data,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  "model-name": "contacts",
                  selection: _vm.selectedItems,
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage, select: _vm.selectAll },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-card", {
                          attrs: {
                            item: item,
                            selected: _vm.isSelected(item),
                            "show-text": _vm.showExtraInformation,
                            "show-alerts": _vm.showAlerts,
                            to: {
                              name: "ContactsForm",
                              params: { id: item.id }
                            }
                          },
                          on: {
                            check: function($event) {
                              return _vm.updateSelection($event, item)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-switch", {
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les informations étendues"
                                  },
                                  model: {
                                    value: _vm.showExtraInformation,
                                    callback: function($$v) {
                                      _vm.showExtraInformation = $$v
                                    },
                                    expression: "showExtraInformation"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              [
                                _c("v-switch", {
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les alertes"
                                  },
                                  model: {
                                    value: _vm.showAlerts,
                                    callback: function($$v) {
                                      _vm.showAlerts = $$v
                                    },
                                    expression: "showAlerts"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          _vm._l(_vm.listActions, function(ref, index) {
                            var callback = ref.callback
                            var disabled = ref.disabled
                            var icon = ref.icon
                            var label = ref.label
                            return _c(
                              "v-list-item",
                              {
                                key: "qs-list__action-" + index,
                                attrs: { disabled: disabled },
                                on: { click: callback }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  { staticClass: "mr-2" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "qs-light-blue" } },
                                      [_vm._v(_vm._s(icon))]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-list-item-content", [
                                  _vm._v(" " + _vm._s(label) + " ")
                                ])
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.params.id
        ? _c("router-view", { attrs: { id: _vm.$route.params.id } })
        : _vm._e(),
      _c(
        "qs-new-duplicate-modal",
        _vm._b(
          {
            attrs: { items: _vm.selectedItems },
            on: {
              "click:close": function($event) {
                _vm.newDuplicateAction.value = false
              },
              "input:comment": function($event) {
                _vm.newDuplicateAction.comment = $event
              },
              "input:target": function($event) {
                _vm.newDuplicateAction.target = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "qs-split-button",
                      {
                        attrs: {
                          disabled: !_vm.newDuplicateAction.target,
                          large: "",
                          loading: _vm.newDuplicateAction.loading,
                          theme: "labase"
                        },
                        on: {
                          "click:main": _vm.saveDuplicateAndRedirectToModeration
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "main-button",
                            fn: function() {
                              return [
                                _vm._v(" Enregistrer et aller à la modération ")
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item",
                              { on: { click: _vm.saveDuplicate } },
                              [_vm._v(" Enregistrer et fermer ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          "qs-new-duplicate-modal",
          _vm.newDuplicateAction,
          false
        )
      ),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.listDialog.value = false
              },
              "click:confirm": _vm.listDialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.listDialog,
          false
        )
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }