
































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsModerationForm from 'qs_vuetify/src/components/QsModerationForm.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { PersistedRevision } from 'qs_vuetify/src/types/models';

import InteractsWithNavbar from '../../mixins/InteractsWithNavbar';

const global: any = namespace('global');
const store: any = namespace('revisions');
const view: any = namespace('revisionsViews');

@Component({
  components: {
    QsActionModal,
    QsButton,
    QsFormBuilder,
    QsModerationForm,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class RevisionsForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  InteractsWithNavbar,
) {
  @global.Mutation addNotification!: any;

  @store.Getter error!: ErrorResponse;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter item!: PersistedRevision;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.setGlobalSubtitle();
  }

  get targetForm(): Form | null {
    if (!this.item) {
      return null;
    }

    return this.$store.getters[`${this.targetSlug}/form`];
  }

  get targetSlug(): string | null {
    if (!this.item) {
      return null;
    }

    const parts = this.item.target_type.split('\\');

    return `${parts[parts.length - 1].toLowerCase()}s`;
  }

  get viewParams() {
    return {
      'revisions.index': {
        ...ListMixin.buildListState(this.options, this.params),
        fields: [
          'created_by_user.contact_name',
          'created_at',
          'reverted_at',
          'target_id',
          'target_type',
          'type',
        ].join(','),
        order: '-id',
      },
      'revisions.retrieve': {
        fields: [
          'after',
          'before',
          'campaign.name',
          'created_by_user.contact_name',
          'created_at',
          'instance.name',
          'should_moderate',
          'target_id',
          'target_type',
          'type',
          'updated_by_user.contact_name',
          'update_at',
        ].join(','),
      },
    };
  }

  closeModal() {
    this.$router.push({ name: 'RevisionsList' });
  }

  @Watch('routeDataLoaded')
  onInteractisWithNavbarRouteDataLoadedChanged() {
    this.setGlobalActions();
    this.setGlobalSubtitle();

    if (!this.targetForm) {
      this.$store.dispatch(`${this.targetSlug}/options`);
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.commitGlobalSubtitle('Chargement...');
    } else {
      this.commitGlobalSubtitle(`[${this.item?.target_type} #${this.item?.target_id}]`);
    }
    this.$emit('updateHead');
  }
}
