















































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import OptionsLazyLoadable from '@/mixins/OptionsLazyLoadable';

import ImportCard from '@/components/imports/ImportCard.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsList from 'qs_vuetify/src/components/QsList.vue';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { PersistedImport } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('imports');
const view: any = namespace('importsView');

@Component({
  components: {
    ImportCard,
    QsFilters,
    QsList,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactsImportsList extends mixins(
  AuthenticationMixin,
  ListMixin,
  OptionsLazyLoadable,
) {
  @store.Getter data!: PersistedImport[];
  @store.Getter filtersDefinition!: FiltersDefinition[];
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;

  setImportsParams = this.buildSetParam('imports', this.setParams);

  filtersOrder = [
    'created_at',
    'created_by_user.id',
    'is_deleted',
  ];

  mounted() {
    this.setup();
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      imports: {
        ...ListMixin.buildListState(this.options, this.params),
        importable_type: 'App\\Models\\Contact',
      },
    };
  }

  get showAlerts() {
    return this.$store.state.importsView.showAlerts;
  }

  set showAlerts(val: boolean) {
    this.$store.commit('importsView/showAlerts', val);
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setup();
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.setActions();
    }
  }

  setActions() {
    if (this.userHas('IMPORTS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/imports/contacts/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }

  setup() {
    this.setActions();
    this.setParams({
      ...this.params,
      importable_type: 'App\\Models\\Contact',
    });
  }
}
