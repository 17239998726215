















































































































































































































import Component, { mixins } from 'vue-class-component';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { RestParams } from 'qs_vuetify/src/types/states';

import Importable from '@/mixins/Importable';

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('imports/item', null);
    next();
  },
  components: {
    QsButton,
    QsConfirmationModal,
    QsFormBuilder,
    QsProgress,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class ContactsImportsForm extends mixins(Importable) {
  filtersOrder = [
    'flags_count',
    'flags',
    'with_deleted',
    'status',
  ];

  formOrder = [
    'name',
    'meta',
    'source_id',
  ];

  parentRouteName = 'ContactsImportsList';

  get importablesHeaders() {
    if (!this.item) {
      return [];
    }

    const fieldsdata = Object.keys(this.item.fields_index).map((f) => ({
      text: this.$i18n.t(`models.contacts.labels.${f}`),
      value: `data.${f}`,
      sortable: false,
    }));

    return [
      { text: 'Statut', value: 'status' },
      ...fieldsdata,
      { text: '', value: 'actions', sortable: false },
    ];
  }

  mounted() {
    this.setup();
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      'imports.retrieve': {
        fields: [
          '*',
          'source.name',
          'stats',
          'tags.name',
        ].join(','),
        with_deleted: true,
      },
      'importables.index': {
        ...ListMixin.buildListState(this.importablesOptions, this.importablesParams),
        order: 'data->last_name',
      },
    };
  }
}
