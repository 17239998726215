var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contacts-form", attrs: { fluid: "" } },
    [
      _c("item-navigation", {
        attrs: {
          item: _vm.item,
          items: _vm.data,
          loading: _vm.loading,
          "model-name": _vm.slug,
          total: _vm.total
        }
      }),
      _c(
        "v-row",
        {
          staticClass: "flex-grow-1 contact-form__main",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column contact-form__main__info",
              staticStyle: { "row-gap": "1px" },
              attrs: { cols: "12", lg: "9" }
            },
            [
              _c("qs-contact-form-section", {
                ref: "QsContactFormSection",
                attrs: { id: _vm.id },
                on: {
                  change: function($event) {
                    return _vm.$refs.QsContactHistory.fetchRevisions()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _vm.userIsSuperadmin
                              ? _c(
                                  "qs-split-button",
                                  {
                                    attrs: {
                                      bottom: "",
                                      color: "info",
                                      loading: _vm.loginLinkLoading,
                                      small: "",
                                      theme: "labase"
                                    },
                                    on: { "click:main": _vm.getLoginLink },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "main-button",
                                          fn: function() {
                                            return [
                                              _vm._v(
                                                " Obtenir un lien de connexion "
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2961059017
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-list-item",
                                          { on: { click: _vm.sendLoginLink } },
                                          [
                                            _vm._v(
                                              " Envoyer un lien de connexion par courriel "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("qs-contact-status-section", {
                ref: "QsContactStatusSection",
                staticClass: "contact-form__main__status",
                attrs: { id: _vm.id },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c("qs-button", {
                          staticClass: "ml-2",
                          attrs: {
                            color: "qs-pink",
                            disabled: !_vm.itemReady || !_vm.routeDataLoaded,
                            icon: "mdi-form-select",
                            label: "Télécharger la fiche de contribution",
                            theme: "labase"
                          },
                          on: { click: _vm.downloadContributionForm }
                        }),
                        _c("qs-button", {
                          staticClass: "ml-2",
                          attrs: {
                            color: "success",
                            disabled: true,
                            icon: "mdi-credit-card-clock",
                            label: "Gérer les DPA",
                            theme: "labase"
                          }
                        }),
                        _vm.item && _vm.item.withdrawn
                          ? _c("qs-button", {
                              staticClass: "ml-2",
                              attrs: {
                                color: "warning",
                                disabled:
                                  !_vm.itemReady || !_vm.routeDataLoaded,
                                icon: "mdi-lock-open",
                                label: "Mettre fin au retrait",
                                theme: "labase"
                              },
                              on: { click: _vm.confirmThenEndWithdrawal }
                            })
                          : _c("qs-button", {
                              staticClass: "ml-2",
                              attrs: {
                                color: "error",
                                disabled: !_vm.itemReady,
                                icon: "mdi-lock",
                                label: "Retirer le contact",
                                theme: "labase"
                              },
                              on: {
                                click: function($event) {
                                  _vm.withdrawalModal = true
                                }
                              }
                            }),
                        _c("qs-button", {
                          staticClass: "ml-2",
                          attrs: {
                            color: "info",
                            disabled: !_vm.itemReady,
                            icon: "mdi-minus-circle",
                            label: "Consigner le décès",
                            theme: "labase"
                          },
                          on: { click: _vm.confirmThenSetDeceased }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "subtitle",
                    fn: function() {
                      return [
                        _vm.item && _vm.item.withdrawn
                          ? _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "py-0 contact-form__main__status__ret-details"
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "contact-form__main__status__ret-details__text"
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " Retrait consigné "
                                                      ),
                                                      _vm.item.active_withdrawal
                                                        .created_by_user_id
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " par " +
                                                                _vm._s(
                                                                  _vm.item
                                                                    .active_withdrawal
                                                                    .created_by_user
                                                                    .contact_name
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " le " +
                                                          _vm._s(
                                                            _vm._f("datetime")(
                                                              _vm.item
                                                                .active_withdrawal
                                                                .created_at
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          4092510968
                                        )
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.item.active_withdrawal.comment
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("qs-contact-events-section", {
                        attrs: { id: _vm.id }
                      }),
                      _c("qs-contact-instances", {
                        staticStyle: { "margin-top": "2px" },
                        attrs: { id: _vm.id }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [_c("qs-contact-tags-section", { attrs: { id: _vm.id } })],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-col", { attrs: { cols: "6" } }, [_vm._v(" rôles ")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-start contact-form__main__history",
              attrs: { cols: "12", lg: "3" }
            },
            [
              _c(
                "qs-contact-history",
                {
                  ref: "QsContactHistory",
                  attrs: { item: _vm.item, id: _vm.id }
                },
                [
                  _c(
                    "qs-button",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        color: "qs-orange",
                        disabled: !_vm.itemReady,
                        theme: "labase",
                        width: "100%"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setCommentsModal(true)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-plus")]),
                      _vm._v(" Ajouter un commentaire ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "qs-action-modal",
        {
          attrs: { color: "white", dark: false, value: _vm.withdrawalModal },
          on: {
            "click:close": function($event) {
              _vm.withdrawalModal = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [_vm._v(" Retirer le contact ")]
              },
              proxy: true
            },
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "qs-button",
                    {
                      attrs: {
                        disabled: !_vm.withdrawalComment,
                        large: "",
                        loading: _vm.withdrawalLoading,
                        theme: "labase"
                      },
                      on: {
                        click: function($event) {
                          return _vm.addWithdrawal(_vm.withdrawalComment)
                        }
                      }
                    },
                    [_vm._v(" Confirmer le retrait ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-textarea", {
            attrs: {
              hint: "Ex. la raison du retrait",
              label: "Note",
              outlined: "",
              readonly: _vm.withdrawalLoading
            },
            model: {
              value: _vm.withdrawalComment,
              callback: function($$v) {
                _vm.withdrawalComment = $$v
              },
              expression: "withdrawalComment"
            }
          })
        ],
        1
      ),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.dialog.value = false
              },
              "click:confirm": _vm.dialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.dialog,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }