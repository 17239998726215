var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messenger" }, [
    _c("div", { staticClass: "messenger__messages" }, [
      _c(
        "div",
        { staticClass: "messenger__messages__inner" },
        [
          _vm.messages.length < 1
            ? _c(
                "div",
                {
                  staticClass:
                    "messages-empty-state d-flex flex-column align-center justify-center py-12 font-italic mt-6",
                  staticStyle: { color: "#808080" }
                },
                [
                  _c("v-icon", [_vm._v("mdi-comment-off-outline")]),
                  _vm._v(" Aucun message ")
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.messages, function(ref, index) {
            var created_at = ref.created_at
            var from = ref.from
            var id = ref.id
            var body = ref.body
            return _c("div", { key: id, staticClass: "message" }, [
              !_vm.isSameDayAsPrevious(index)
                ? _c(
                    "div",
                    { class: ["message__timestamp", { "mt-0": index === 0 }] },
                    [_vm._v(" " + _vm._s(_vm.dateFormat(created_at)) + " ")]
                  )
                : _vm._e(),
              !_vm.isSameUserAsPrevious(index)
                ? _c("div", {
                    class: [
                      "message__user",
                      { own_message: from === "+15147004904" }
                    ]
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  class: [
                    "message__text",
                    { own_message: from === "+15147004904" },
                    { "mb-0": _vm.isSameUserAsNext(index) },
                    {
                      "message__text--grouped":
                        _vm.isSameDayAsPrevious(index) &&
                        _vm.isSameUserAsPrevious(index)
                    },
                    { "message__text--loading": _vm.loading }
                  ]
                },
                [
                  _c("div", { staticClass: "message__text__content" }, [
                    _vm._v(_vm._s(body))
                  ])
                ]
              )
            ])
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "messenger__new" }, [
      _c(
        "div",
        { staticClass: "messenger__new__inner" },
        [
          _c("textarea", {
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { text: "", tile: "" }, on: { click: _vm.emitReply } },
            [_c("v-icon", [_vm._v("mdi-send")])],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }