
















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Model, SentEmail } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('sent_emails');
const view: any = namespace('sentEmailsViews');

@Component({
  components: {
    QsContactListItem,
    QsDataTable,
    QsExportMenu,
    QsFilters,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
class SentEmailsList
  extends mixins(
    AuthenticationMixin,
    DataRouteGuards,
    ExportMixin,
    ListMixin,
  ) {
  @store.Getter data!: Array<SentEmail>;
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('options') setOptions!: any;
  @view.Mutation('params') setParams!: any;

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      sent_emails: ListMixin.buildListState(this.options, this.params),
    };
  }

  setSentEmailsParam = this.buildSetParam('sent_emails', this.setParams);

  headers = [
    { text: 'Id', value: 'id' },
    { text: 'Destinataire', value: 'email' },
    { text: 'Date d\'envoi', value: 'sent_at' },
    { text: 'Type d\'envoi', value: 'mailable_class' },
    { text: '', value: 'actions' },
  ];

  mounted() {
    this.$store.commit('sent_emails/exportFields', [
      'email',
      'external_id',
      'mailable_class',
      'mailer',
      'sent_at',
    ]);
  }

  i18nalizeValue(name: string, key: string) {
    const cleanKey = key.replace(/\.|\\/g, '_').toLowerCase();
    const target = `models.${this.slug}.enums.${name}.${cleanKey}`;

    if (this.$i18n.te(target)) {
      return this.$i18n.tc(target, 1);
    }

    return key;
  }
}

interface SentEmailsList extends AuthenticationMixin, DataRouteGuards, ListMixin<Model> {}

export default SentEmailsList;
