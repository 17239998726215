var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contributions-imports-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", lg: "3", xl: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  flat: "",
                  "model-name": _vm.slug,
                  order: _vm.filtersOrder,
                  theme: "labase"
                },
                on: { input: _vm.setImportsParams }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "7", lg: "9", xl: "9" } },
            [
              _c("qs-list", {
                attrs: {
                  items: _vm.data,
                  "hide-selection": "",
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  "model-name": "imports",
                  theme: "labase",
                  total: _vm.total
                },
                on: { load: _vm.loadNextPage },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("import-card", {
                          attrs: {
                            item: item,
                            "show-alerts": _vm.showAlerts,
                            to: {
                              name: "ContributionsImportsForm",
                              params: { importId: item.id }
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "settings",
                    fn: function() {
                      return [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    "hide-details": "",
                                    label: "Afficher les alertes"
                                  },
                                  model: {
                                    value: _vm.showAlerts,
                                    callback: function($$v) {
                                      _vm.showAlerts = $$v
                                    },
                                    expression: "showAlerts"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }