import Vue from 'vue';
import Component from 'vue-class-component';

import { Model, Persisted } from 'qs_vuetify/src/types/models';

@Component
export default class Selectable<M extends Model> extends Vue {
  data!: Persisted<M>[];
  selectedItems!: Persisted<M>[];
  addSelectedItem!: any;
  setSelectedItems!: any;
  removeSelectedItem!: any;

  emptySelection() {
    this.setSelectedItems([]);
  }

  isSelected(item: Persisted<M>): boolean {
    return !!this.selectedItems.find((i) => i.id === item.id);
  }

  selectAll(select: boolean) {
    if (select) {
      this.setSelectedItems(this.data);
    } else {
      this.emptySelection();
    }
  }

  updateSelection(state: boolean, item: Persisted<M>) {
    if (state) {
      this.addSelectedItem(item);
    } else {
      this.removeSelectedItem(item);
    }
  }
}
