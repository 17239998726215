var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-export-menu", {
              on: {
                export: function($event) {
                  return _vm.exportWithWarningAs($event)
                }
              }
            }),
            _vm.slug
              ? _c("qs-filters", {
                  attrs: {
                    "active-filters": _vm.params,
                    "filters-definition": _vm.filtersDefinition,
                    "model-name": _vm.slug,
                    order: _vm.filtersOrder,
                    theme: "labase"
                  },
                  on: { input: _vm.updateFilters }
                })
              : _vm._e(),
            _c(
              "v-card",
              { attrs: { flat: "", tile: "" } },
              [
                _c(
                  "v-card-text",
                  [
                    _c("v-select", {
                      attrs: {
                        items: [
                          { text: "Congrès", value: "congress" },
                          {
                            text: "Conseil national",
                            value: "national_council"
                          }
                        ],
                        label: "Type d'instance nationale"
                      },
                      model: {
                        value: _vm.reportType,
                        callback: function($$v) {
                          _vm.reportType = $$v
                        },
                        expression: "reportType"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("v-data-table", {
              staticClass: "delegation-report_data-table",
              attrs: {
                headers: _vm.tableHeaders,
                items: _vm.data,
                "items-per-page": -1,
                loading: _vm.loading
              },
              scopedSlots: _vm._u([
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c("div", _vm._g({}, on), [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "body-1 font-weight-bold"
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c("br"),
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(_vm._s(item.instance_type.name))
                                      ])
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          item.delegation_stats.delegatable_instances
                            ? _c("span", [
                                _c("strong", [_vm._v("Instances délégables")]),
                                _c("br"),
                                _c(
                                  "ul",
                                  _vm._l(
                                    item.delegation_stats.delegatable_instances.split(
                                      ", "
                                    ),
                                    function(i) {
                                      return _c("li", { key: i }, [
                                        _vm._v(" " + _vm._s(i) + " ")
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" " + _vm._s() + " ")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.is_active",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: item.is_active
                                              ? "qs-green"
                                              : "qs-red"
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(" mdi-radiobox-marked ")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.is_active ? "Active" : "Inactive")
                            )
                          ])
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }