var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { class: "base-layout " + _vm.contentClass, attrs: { fluid: "" } },
    [
      _vm.item && _vm.items && _vm.modelName
        ? _c(
            "v-row",
            [
              _c("item-navigation", {
                attrs: {
                  item: _vm.item,
                  items: _vm.items,
                  "model-name": _vm.modelName
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [_vm._t("sidebar")],
            2
          ),
          _c("v-col", { attrs: { cols: "12", md: "9" } }, [_vm._t("main")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }