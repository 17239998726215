const ContactsImportablesForm = () => import('@/views/Imports/Contacts/ContactsImportablesForm.vue');
const ContactsImportsForm = () => import('@/views/Imports/Contacts/ContactsImportsForm.vue');
const ContactsImportsList = () => import('@/views/Imports/Contacts/ContactsImportsList.vue');
const ContributionsImportsForm = () => import('@/views/Imports/Contributions/ContributionsImportsForm.vue');
const ContributionsImportsList = () => import('@/views/Imports/Contributions/ContributionsImportsList.vue');
const NewContactsImportsForm = () => import('@/views/Imports/Contacts/NewContactsImportsForm.vue');
const ContributionsImportablesForm = () => import('@/views/Imports/Contributions/ContributionsImportablesForm.vue');
const NewContributionsImportsForm = () => import('@/views/Imports/Contributions/NewContributionsImportsForm.vue');

const importRoutes = [
  {
    path: '/imports/contacts',
    name: 'ContactsImportsList',
    component: ContactsImportsList,
    meta: {
      data: {
        imports: {
          index: {
            params: {
              fields: [
                '*',
                'created_by_user.contact_name',
                'source.name',
                'tags.name',
                'updated_by_user.contact_name',
              ].join(','),
              importable_type: 'App\\Models\\Contact',
            },
          },
        },
        sources: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.sources.lastLoadedAt;
            },
            params: {
              per_page: '*',
              prefix: '',
              order: 'name',
            },
          },
        },
      },
      title: 'Contacts',
      menus: ['imports'],
      submenu: true,
      requires: ['IMPORTS_RETRIEVE'],
    },
    children: [
      {
        path: 'new',
        name: 'NewContactsImportsForm',
        component: NewContactsImportsForm,
        props() {
          return {
            importId: 'new',
          };
        },
        meta: {
          data: {
            imports: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.imports.lastLoadedAt;
                },
                params: {
                  fields: [
                    '*',
                    'created_by_user.contact_name',
                    'source.name',
                    'tags.name',
                    'updated_by_user.contact_name',
                  ].join(','),
                  importable_type: 'App\\Models\\Contact',
                  order: '-id',
                },
              },
              loadEmpty: {},
            },
            sources: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.sources.lastLoadedAt;
                },
                params: {
                  per_page: '*',
                  prefix: '',
                  order: 'name',
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    path: '/imports/contacts/:importId',
    name: 'ContactsImportsForm',
    component: ContactsImportsForm,
    props: true,
    meta: {
      data: {
        imports: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.importId,
              };
            },
            conditional(vm: Vue) {
              return vm.$route.params.importId !== 'new';
            },
            params: {
              fields: [
                '*',
                'source.name',
                'stats',
                'tags.name',
              ].join(','),
            },
          },
        },
        importables: {
          index: {
            params: {
              fields: [
                '*',
              ].join(','),
            },
            prefix(vm: Vue) {
              return `/imports/${vm.$route.params.importId}`;
            },
          },
        },
        sources: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.sources.lastLoadedAt;
            },
            params: {
              per_page: '*',
              prefix: '',
              order: 'name',
            },
          },
        },
        tags: {
          index: {
            conditional(vm: Vue) {
              return vm.$store.state.tags.lastLoadedAt === null;
            },
            params: {
              fields: [
                'name',
                'instance_name',
              ].join(','),
              order: 'name',
              per_page: '*',
            },
          },
        },
      },
    },
    children: [
      {
        path: ':importableId',
        name: 'ContactsImportablesForm',
        component: ContactsImportablesForm,
        props: true,
        meta: {
          data: {
            imports: {
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.importId,
                  };
                },
                params: {
                  fields: [
                    '*',
                    'source.name',
                    'stats',
                  ].join(','),
                },
              },
            },
            importables: {
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.importableId,
                  };
                },
                params: {
                  fields: [
                    '*',
                  ].join(','),
                },
                prefix(vm: Vue) {
                  return `/imports/${vm.$route.params.importId}`;
                },
              },
            },
            sources: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.sources.lastLoadedAt;
                },
                params: {
                  per_page: '*',
                  prefix: '',
                  order: 'name',
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    path: '/imports/contributions',
    name: 'ContributionsImportsList',
    component: ContributionsImportsList,
    meta: {
      data: {
        imports: {
          index: {
            params: {
              fields: [
                '*',
                'created_by_user.contact_name',
                'source.name',
                'tags.name',
                'updated_by_user.contact_name',
              ].join(','),
              importable_type: 'App\\Models\\Contribution',
            },
          },
        },
        sources: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.sources.lastLoadedAt;
            },
            params: {
              per_page: '*',
              prefix: '',
              order: 'name',
            },
          },
        },
      },
      title: 'Contributions',
      menus: ['imports'],
      submenu: true,
      requires: ['IMPORTS_RETRIEVE'],
    },
    children: [
      {
        path: 'new',
        name: 'NewContributionsImportsForm',
        component: NewContributionsImportsForm,
        props() {
          return {
            importId: 'new',
          };
        },
        meta: {
          data: {
            imports: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.imports.lastLoadedAt;
                },
                params: {
                  fields: [
                    '*',
                    'created_by_user.contact_name',
                    'source.name',
                    'tags.name',
                    'updated_by_user.contact_name',
                  ].join(','),
                  importable_type: 'App\\Models\\Contribution',
                  order: '-id',
                },
              },
              loadEmpty: {},
            },
            sources: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.sources.lastLoadedAt;
                },
                params: {
                  per_page: '*',
                  prefix: '',
                  order: 'name',
                },
              },
            },
          },
        },
      },
    ],
  },
  {
    path: '/imports/contributions/:importId',
    name: 'ContributionsImportsForm',
    component: ContributionsImportsForm,
    props: true,
    meta: {
      data: {
        imports: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.importId,
              };
            },
            conditional(vm: Vue) {
              return vm.$route.params.importId !== 'new';
            },
            params: {
              fields: [
                '*',
                'source.name',
                'stats',
                'tags.name',
              ].join(','),
            },
          },
        },
        importables: {
          index: {
            params: {
              fields: [
                '*',
              ].join(','),
            },
            prefix(vm: Vue) {
              return `/imports/${vm.$route.params.importId}`;
            },
          },
        },
        sources: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.sources.lastLoadedAt;
            },
            params: {
              per_page: '*',
              prefix: '',
              order: 'name',
            },
          },
        },
        tags: {
          index: {
            conditional(vm: Vue) {
              return vm.$store.state.tags.lastLoadedAt === null;
            },
            params: {
              fields: [
                'name',
                'instance_name',
              ].join(','),
              order: 'name',
              per_page: '*',
            },
          },
        },
      },
    },
    children: [
      {
        path: ':importableId',
        name: 'ContributionsImportablesForm',
        component: ContributionsImportablesForm,
        props: true,
        meta: {
          data: {
            imports: {
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.importId,
                  };
                },
                params: {
                  fields: [
                    '*',
                    'source.name',
                    'stats',
                  ].join(','),
                },
              },
            },
            importables: {
              index: {
                params: {
                  fields: [
                    '*',
                  ].join(','),
                  order: 'data->last_name,data->first_name,id',
                },
                prefix(vm: Vue) {
                  return `/imports/${vm.$route.params.importId}`;
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.importableId,
                  };
                },
                params: {
                  fields: [
                    '*',
                  ].join(','),
                },
                prefix(vm: Vue) {
                  return `/imports/${vm.$route.params.importId}`;
                },
              },
            },
            sources: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.sources.lastLoadedAt;
                },
                params: {
                  per_page: '*',
                  prefix: '',
                  order: 'name',
                },
              },
            },
          },
        },
      },
    ],
  },
];

export default importRoutes;
