





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { PersistedEvent } from 'qs_vuetify/src/types/models';

import AdminPopover from './AdminPopover.vue';

@Component({
  components: {
    AdminPopover,
  },
})
export default class ContactAdminPopover extends Vue {
  @Prop({ required: true, type: Object }) item!: PersistedEvent;

  get isSameDayEvent(): boolean {
    if (this.item) {
      const start = new Date(this.item.start_at);
      const end = new Date(this.item.end_at);

      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);

      return start.getTime() === end.getTime();
    }

    return false;
  }
}
