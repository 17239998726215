var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        height: "70vh",
        loading: _vm.loading,
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Modifier la transaction ")]
            },
            proxy: true
          },
          _vm.item && _vm.item.contact
            ? {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm._v(
                      " Exécuté le " +
                        _vm._s(_vm._f("datetime")(_vm.item.executed_at)) +
                        " | " +
                        _vm._s(
                          _vm.$tc(
                            "models.transactions.enums.status." +
                              _vm.item.status,
                            1
                          )
                        ) +
                        " | " +
                        _vm._s(
                          _vm.$tc(
                            "models.transactions.enums.external_type." +
                              _vm.item.external_type,
                            1
                          )
                        ) +
                        " "
                    )
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "qs-button",
                  {
                    attrs: {
                      disabled:
                        !_vm.hasChanged || !_vm.routeDataLoaded || _vm.loading,
                      loading: _vm.loading
                    },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" Enregistrer ")]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady
        ? _c("qs-form-builder", {
            attrs: {
              "model-name": _vm.slug,
              form: _vm.form,
              item: _vm.item,
              error: _vm.error,
              order: _vm.formOrder
            },
            on: { input: _vm.syncItem },
            scopedSlots: _vm._u(
              [
                {
                  key: "contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              2143680124
            )
          })
        : _c(
            "div",
            {
              staticClass: "d-flex flex-column justify-center align-center",
              staticStyle: { "min-height": "200px" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          ),
      _vm.itemReady
        ? _c(
            "v-expansion-panels",
            {
              staticClass: "transactions-form__related mb-3",
              attrs: { flat: "", tile: "" }
            },
            [
              _vm.item && _vm.item.external_type === "membership"
                ? _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [_vm._v(" Adhésions ")]),
                      _c("v-expansion-panel-content", [
                        _c("div", [
                          _vm.item && _vm.item.memberships.length > 0
                            ? _c(
                                "ul",
                                _vm._l(_vm.item.memberships, function(
                                  membership
                                ) {
                                  return _c("li", { key: membership.id }, [
                                    _vm._v(
                                      " À partir du " +
                                        _vm._s(
                                          _vm._f("date")(
                                            membership.period_start
                                          )
                                        ) +
                                        " pour " +
                                        _vm._s(
                                          _vm._f("duration")(
                                            membership.duration
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return _vm.destroyMembership(
                                              membership.id
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Supprimer")]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _c("div", [
                                _c("em", [
                                  _vm._v(
                                    "Aucune adhésion liée à cette transaction"
                                  )
                                ])
                              ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "qs-split-button",
                              {
                                staticClass: "ml-3",
                                attrs: {
                                  color: "warning",
                                  disabled:
                                    !_vm.routeDataLoaded ||
                                    _vm.hasChanged ||
                                    _vm.loading,
                                  loading: _vm.loading,
                                  label: _vm.addMembershipButtonLabel,
                                  small: ""
                                },
                                on: {
                                  "click:main": function($event) {
                                    return _vm.dispatchAddMemberships(1)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "main-button",
                                      fn: function() {
                                        return [
                                          _vm._v(" Ajouter une adhésion ")
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3810055102
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.dispatchAddMemberships(2)
                                          }
                                        }
                                      },
                                      [_vm._v(" Deux adhésions ")]
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.dispatchAddMemberships(3)
                                          }
                                        }
                                      },
                                      [_vm._v(" Trois adhésions ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.item && _vm.item.external_type === "contribution"
                ? _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Contributions ")
                      ]),
                      _c("v-expansion-panel-content", [
                        _c("div", [
                          _vm.item && _vm.item.contributions.length > 0
                            ? _c(
                                "ul",
                                _vm._l(_vm.item.contributions, function(
                                  contribution
                                ) {
                                  return _c("li", { key: contribution.id }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            contribution.amount_in_cad
                                          )
                                        ) +
                                        " "
                                    ),
                                    contribution.instance_name
                                      ? _c("span", [
                                          _vm._v(
                                            "pour " +
                                              _vm._s(contribution.instance_name)
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            : _c("div", [
                                _c("em", [
                                  _vm._v(
                                    "Aucune contribution liée à cette transaction"
                                  )
                                ])
                              ])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }