var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { height: "70vh", value: true },
      on: {
        "click:close": function($event) {
          return _vm.$router.push({ name: "Contributions" })
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Contribution ")]
            },
            proxy: true
          },
          _vm.userHas("CONTRIBUTIONS_UPDATE")
            ? {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "qs-button",
                      {
                        attrs: {
                          loading: _vm.loading || !_vm.itemReady,
                          large: "",
                          theme: "labase"
                        },
                        on: { click: _vm.submit }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.item
        ? _c("qs-form-builder", {
            attrs: {
              error: _vm.error,
              form: _vm.form,
              item: _vm.item,
              "model-name": _vm.slug,
              order: _vm.formOrder
            },
            on: { input: _vm.syncItem },
            scopedSlots: _vm._u(
              [
                {
                  key: "contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              2143680124
            )
          })
        : _vm._e(),
      _vm.item
        ? _c(
            "v-card",
            { attrs: { outlined: "", tile: "" } },
            [
              _c(
                "v-card-subtitle",
                { staticClass: "d-flex" },
                [
                  _vm._v(" Détails de la transaction "),
                  _c("v-spacer"),
                  _c("qs-button", {
                    attrs: {
                      color: "info",
                      icon: "mdi-open-in-new",
                      label: "Ouvrir la transaction",
                      theme: "labase",
                      to: {
                        name: "TransactionsForm",
                        params: { id: _vm.item.transaction_id }
                      }
                    }
                  })
                ],
                1
              ),
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("datetime")(_vm.item.transaction.executed_at))
                  )
                ])
              ])
            ],
            1
          )
        : [
            _c(
              "div",
              {
                staticClass: "d-flex justify-center align-center",
                staticStyle: { height: "30vh" }
              },
              [
                _c("v-progress-circular", {
                  attrs: { color: "primary", indeterminate: "" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }