import { render, staticRenderFns } from "./ContactsImportsList.vue?vue&type=template&id=22bcdb50&"
import script from "./ContactsImportsList.vue?vue&type=script&lang=ts&"
export * from "./ContactsImportsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VCol,VContainer,VList,VListItem,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22bcdb50')) {
      api.createRecord('22bcdb50', component.options)
    } else {
      api.reload('22bcdb50', component.options)
    }
    module.hot.accept("./ContactsImportsList.vue?vue&type=template&id=22bcdb50&", function () {
      api.rerender('22bcdb50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Imports/Contacts/ContactsImportsList.vue"
export default component.exports