



























































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsExportMenu from 'qs_vuetify/src/components/QsExportMenu.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Membership } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import ContactAdminPopover from '@/components/ContactAdminPopover.vue';
import TransactionAdminPopover from '@/components/TransactionAdminPopover.vue';

import ContributionModal from './ContributionModal.vue';

const store: any = namespace('contributions');
const view: any = namespace('contributionsView');

@Component({
  components: {
    ContactAdminPopover,
    QsBooleanIndicator,
    QsButton,
    QsContactListItem,
    QsDataTable,
    QsExportMenu,
    QsFilters,
    TransactionAdminPopover,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Contributions extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  ListMixin,
) {
  @store.Getter data!: Array<Membership>;
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation('setParams') setViewParams!: any;

  setContributionsParam = this.buildSetParam('contributions.index', this.setParams);

  get actionParams(): RestParams {
    return this.params;
  }

  get exportParams(): RestParams {
    return this.actionParams;
  }

  // eslint-disable-next-line class-methods-use-this
  get headers() {
    return [
      { text: 'Transaction', value: 'transaction_id', align: 'center' },
      {
        align: 'center',
        sortable: false,
        text: 'Date de dépôt',
        value: 'deposit_date',
      },
      { text: 'Contact', value: 'contact_full_name', sortable: false },
      { text: 'Circonscription du Contact', value: 'district_name', align: 'left' },
      { text: "Affecté à l'instance", value: 'affected_to_instance', align: 'center' },
      {
        align: 'left',
        sortable: true,
        text: 'Instance',
        value: 'instance.name',
      },
      {
        align: 'right',
        sortable: false,
        text: 'Montant',
        value: 'amount_in_cad',
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ];
  }

  get transactionFilters(): FiltersDefinition {
    const filters: FiltersDefinition = this.$store.getters['transactions/filtersDefinition'];
    const object: FiltersDefinition = {};

    return Object.entries(filters)
      .reduce((acc, [key, val]) => {
        if (['contact.id', 'external_type', 'import.id'].includes(key)) {
          return acc;
        }

        acc[`transaction.${key}`] = val;

        return acc;
      }, object);
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      'contributions.index': ListMixin.buildListState(this.options, this.params),
      contributions: {
        fields: ContributionModal.FIELDS,
      },
    };
  }

  mounted() {
    this.$store.commit('contributions/exportFields', [
      'id',
      'amount_in_cad',
      'contact_full_name',
      'contact_v1_contact_id',
      'contact_is_adopted',
      'dgeq_instance_name',
      'deposit_date',
      'transaction_executed_at',
      'transaction_external_id',
      'district_name',
      'instance_name',
    ]);
  }

  setParams(params: RestParams) {
    this.setOptions({
      ...this.options,
      page: 1,
    });
    this.setViewParams(params);
  }

  resetViewParams() {
    this.setOptions({
      sortBy: ['transaction_id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    });
    this.setParams({
      page: 1,
      order: 'transaction_id',
    });
  }

  @Watch('$route.fullPath')
  onContributionsRouteFullPathChanged() {
    this.reloadDataRoutesData();
  }
}
