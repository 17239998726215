var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "contacts-imports-form", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    color: "primary",
                    icon: "",
                    link: "",
                    tile: "",
                    to: { name: "ContactsImportsList" }
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("mdi-menu-left")
                  ])
                ],
                1
              ),
              _c("span", { staticClass: "text-h6" }, [
                _vm._v("Retour à la liste")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-2", attrs: { flat: "", tile: "" } },
                [
                  _vm.item
                    ? _c(
                        "v-card-text",
                        [
                          _c("qs-form-builder", {
                            attrs: {
                              disabled:
                                _vm.importIsCompleted || _vm.jobIsLoading,
                              "model-name": _vm.slug,
                              form: _vm.importsForm,
                              item: _vm.item,
                              error: _vm.importsError,
                              order: _vm.formOrder
                            },
                            on: { input: _vm.syncImport },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "meta",
                                  fn: function(ref) {
                                    var field = ref.field
                                    var item = ref.item
                                    var update = ref.update
                                    return [
                                      !!item
                                        ? _c("qs-form-builder", {
                                            attrs: {
                                              disabled:
                                                _vm.importIsCompleted ||
                                                _vm.jobIsLoading,
                                              "model-name": _vm.slug,
                                              form: _vm.metaFormDefinition,
                                              item: item.meta,
                                              error: _vm.importsError
                                            },
                                            on: {
                                              input: function($event) {
                                                return update(
                                                  field.name,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              695593107
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            _vm._l(_vm.item.tags, function(tag) {
                              return _c("qs-tag-chip", {
                                key: tag.id,
                                attrs: {
                                  close: !_vm.deleteImportsTagLoading,
                                  disabled: _vm.deletingImportsTagId === tag.id,
                                  name: tag.name
                                },
                                on: {
                                  "click:close": function($event) {
                                    return _vm.deleteImportsTag(tag)
                                  }
                                }
                              })
                            }),
                            1
                          ),
                          _c("qs-tags-autocomplete", {
                            attrs: {
                              "allow-create": "",
                              disabled: _vm.importIsCompleted,
                              filled: "",
                              loading:
                                _vm.tagsLoading || _vm.putImportsTagLoading
                            },
                            on: {
                              created: function($event) {
                                return _vm.reloadDataRoutesData(["tags"], true)
                              },
                              input: _vm.putImportsTag
                            }
                          })
                        ],
                        1
                      )
                    : _c("v-card-text", [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex justify-center align-center",
                            staticStyle: { height: "20vh" }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { color: "primary", indeterminate: "" }
                            })
                          ],
                          1
                        )
                      ])
                ],
                1
              ),
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.importablesParams,
                  "filters-definition": _vm.importablesFiltersDefinition,
                  "model-name": "importables",
                  order: _vm.filtersOrder,
                  theme: "labase"
                },
                on: { input: _vm.setImportableParam }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-2", attrs: { flat: "", tile: "" } },
                [
                  _vm.item && _vm.item.stats
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "qs-progress",
                            {
                              attrs: {
                                "model-name": "imports",
                                values: [
                                  {
                                    color: "green",
                                    key: "completed",
                                    value: _vm.item.stats.completed
                                  },
                                  {
                                    color: "red",
                                    key: "failed",
                                    value: _vm.item.stats.failed
                                  },
                                  {
                                    color: "light-blue",
                                    key: "pending",
                                    value: _vm.item.stats.pending
                                  }
                                ]
                              }
                            },
                            [
                              _vm._v(
                                " Progression " +
                                  _vm._s(
                                    _vm._f("number")(
                                      ((_vm.item.stats.completed +
                                        _vm.item.stats.failed) /
                                        _vm.item.stats.total) *
                                        100,
                                      0
                                    )
                                  ) +
                                  "% "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("qs-data-table", {
                attrs: {
                  actions: ["edit", "delete"],
                  flat: "",
                  headers: _vm.importablesHeaders,
                  items: _vm.importablesData,
                  loading: _vm.importablesLoading,
                  options: _vm.importablesOptions,
                  "set-options": _vm.setImportablesOptions,
                  tile: "",
                  total: _vm.importablesTotal || 0
                },
                on: {
                  delete: function($event) {
                    return _vm.confirmAndDeleteImportable($event)
                  },
                  edit: function($event) {
                    return _vm.goToImportable($event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.data.flags && item.data.flags.length > 0
                          ? _c(
                              "v-badge",
                              { attrs: { content: item.data.flags.length } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: _vm._f(
                                                        "statusColor"
                                                      )(item.status)
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " mdi-radiobox-marked "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("status")(
                                                item.status,
                                                "importables"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    item.data.flags
                                      ? [
                                          _c("br"),
                                          _c(
                                            "ul",
                                            _vm._l(item.data.flags, function(
                                              flag
                                            ) {
                                              return _c(
                                                "li",
                                                { key: item.id + "--" + flag },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("enumVal")(
                                                          flag,
                                                          "importables",
                                                          "flags"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: _vm._f("statusColor")(
                                                    item.status
                                                  )
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v(" mdi-radiobox-marked ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("status")(
                                            item.status,
                                            "importables"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("qs-button", {
                              staticClass: "mr-2",
                              attrs: {
                                color: "info",
                                icon: "mdi-pencil",
                                label: "Modifier",
                                theme: "labase",
                                to: {
                                  name: "ContactsImportablesForm",
                                  params: { importableId: item.id }
                                },
                                small: ""
                              }
                            }),
                            _c("qs-button", {
                              staticClass: "mr-2",
                              attrs: {
                                color: "warning",
                                disabled:
                                  item.status === "completed" ||
                                  !!item.deleted_at ||
                                  item.data.flags.length > 0,
                                icon: "mdi-refresh",
                                label: "Importer",
                                small: "",
                                theme: "labase"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.putImportImportable(item)
                                }
                              }
                            }),
                            !item.deleted_at
                              ? _c("qs-button", {
                                  attrs: {
                                    color: "error",
                                    disabled:
                                      item.status === "completed" ||
                                      !!item.deleted_at,
                                    icon: "mdi-archive",
                                    label: "Archiver",
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmAndDeleteImportable(
                                        item
                                      )
                                    }
                                  }
                                })
                              : _c("qs-button", {
                                  attrs: {
                                    color: "success",
                                    icon: "mdi-archive-refresh",
                                    label: "Restaurer",
                                    small: "",
                                    theme: "labase"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.restoreImportable(item)
                                    }
                                  }
                                })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view"),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.dialog.value = false
              },
              "click:confirm": _vm.dialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.dialog,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }