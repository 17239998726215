import { ActionTree, Module } from 'vuex';

import { AutomatedEmailTemplate } from 'qs_vuetify/src/types/models';
import { AutomatedEmailTemplatesRestState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';
import axios from 'qs_vuetify/src/plugins/axios';

export const state: AutomatedEmailTemplatesRestState = buildRestState<AutomatedEmailTemplate>('automated_email_templates', {});

export const getters = buildRestGetters<AutomatedEmailTemplate, AutomatedEmailTemplatesRestState>();

export const actions: ActionTree<AutomatedEmailTemplatesRestState, RootState> = {
  ...buildRestActions<AutomatedEmailTemplate, AutomatedEmailTemplatesRestState>(),
  async sendTest({ commit }, id) {
    commit('loaded', false);
    commit('error', null);

    try {
      const ajax = axios.post(`/automated_email_templates/${id}/send_test`);

      commit('ajax', ajax);

      await ajax;
    } catch (err) {
      if (err.response && err.response.data) {
        commit('error', err.response.data);
      }

      throw err;
    } finally {
      commit('ajax', null);
    }

    commit('loaded', true);
  },
};

export const mutations = buildRestMutations<AutomatedEmailTemplate, AutomatedEmailTemplatesRestState>();

export const automatedEmailTemplates: Module<AutomatedEmailTemplatesRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
