var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                class: [
                  "d-flex",
                  "flex-column",
                  "justify-center",
                  "align-center",
                  "pa-12",
                  "qs-large-choice",
                  "qs-" + (hover ? "orange--hover" : _vm.color)
                ],
                attrs: { dark: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("click")
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white", "x-large": "" } }, [
                  _vm._v(_vm._s(_vm.icon))
                ]),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-uppercase font-weight-bold qs-large-choice__label"
                  },
                  [_vm._v(" " + _vm._s(_vm.text) + " ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }