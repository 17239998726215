

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {
  @Prop({ required: false, type: String, default: 'mdi-warning' }) icon!: string;
}
