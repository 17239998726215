var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "home", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h2", { staticClass: "subtitle font-weight-bold" }, [
              _vm._v("👋 Bienvenue sur Admin")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          !_vm.loading && !_vm.userIsConnected
            ? _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "home__login",
                      attrs: {
                        border: "left",
                        color: "qs-beige",
                        icon: "mdi-account-key",
                        tile: ""
                      }
                    },
                    [
                      _c("p", { staticClass: "body-1" }, [
                        _vm._v(
                          "Vous devez être connecté pour utiliser la plateforme."
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info", outlined: "", tile: "" },
                          on: {
                            click: function($event) {
                              return _vm.$store.commit(
                                "global/loginDialog",
                                true
                              )
                            }
                          }
                        },
                        [_vm._v(" Connexion ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.user
            ? _c(
                "v-col",
                [
                  _c(
                    "qs-card",
                    {
                      staticClass: "home__reports",
                      attrs: { flat: "", tile: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v(" Rapports ")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("v-treeview", {
                        attrs: {
                          items: _vm.reports,
                          "open-all": "",
                          "open-on-click": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function(ref) {
                              var item = ref.item
                              var open = ref.open
                              return [
                                item.children
                                  ? _c("v-icon", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            open
                                              ? "mdi-folder-open"
                                              : "mdi-folder"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("v-icon", [_vm._v(" mdi-file-chart ")])
                              ]
                            }
                          },
                          {
                            key: "label",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.openReport(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }