

















































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import { Event } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('events');
const view: any = namespace('eventsViews');

@Component({
  components: {
    QsActionModal,
    QsFormBuilder,
    QsRelationField,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class EventsDialog extends mixins(
  DataRouteGuards,
  FormMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: Event;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  formOrder: string[] = [
    'title',
    'description',
    'instance_id',
    'organized_by_user',
    'start_at',
    'accessibility',
    'accessibility_notes',
    'duration',
    'location_name',
    'visibility',
  ];


  mounted() {
    this.onRouteChanged();
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      events: ListMixin.buildListState(this.options, this.params),
    };
  }

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Événement enregistré.',
    });

    this.reloadDataRoutesData(['events.index']);

    this.$router.push('/events');
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.$store.commit('global/subtitle', 'Événements');
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
      this.setGlobalTitle();
    }
  }

  setGlobalTitle() {
    if (this.isNew) {
      this.$store.commit('global/title', 'Nouvel événement');
    } else {
      this.$store.commit('global/title', this.item?.title || `Événement #${this.item.id}`);
    }
  }

  submitForm() {
    this.submit();
  }
}
