
















































































import Component from 'vue-class-component';

import QsProgress from 'qs_vuetify/src/components/QsProgress.vue';

import { Import } from 'qs_vuetify/src/types/models';

import BaseItemCard from '../BaseItemCard.vue';

@Component({
  components: {
    QsProgress,
  },
})
export default class ImportCard extends BaseItemCard<Import> {
  i18nalizeValue(name: string, key: string, qty: number = 1) {
    const cleanKey = key.replace(/\.|\\/g, '_').toLowerCase();
    const target = `models.imports.enums.${name}.${cleanKey}`;

    if (this.$i18n.te(target)) {
      return this.$i18n.tc(target, qty);
    }

    return key;
  }
}
