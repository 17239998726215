





































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsDateField from 'qs_vuetify/src/components/Fields/QsDateField.vue';
import QsDistrictForm from 'qs_vuetify/src/components/Forms/QsDistrictForm.vue';
import QsEmailField from 'qs_vuetify/src/components/Fields/QsEmailField.vue';
import QsSelectField from 'qs_vuetify/src/components/Fields/QsSelectField.vue';
import QsTextField from 'qs_vuetify/src/components/Fields/QsTextField.vue';

import ErrorMixin from 'qs_vuetify/src/mixins/ErrorMixin';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import { Contact, District, User } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

const auth: any = namespace('auth');
const store: any = namespace('contacts');

@Component({
  components: {
    QsDateField,
    QsDistrictForm,
    QsEmailField,
    QsSelectField,
    QsTextField,
  },
})
export default class QsContactForm extends mixins(
  ErrorMixin,
  FormMixin,
  I18nMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: Contact;
  @store.Getter slug!: string;
  @auth.Getter user!: User;

  @Prop([String, Number]) id!: string | number;

  updateDistrict(val: District): void {
    this.item.district = val;
  }
}
