import { Module } from 'vuex';

import { TransactionsViewsState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const transactionsView: Module<TransactionsViewsState, RootState> = {
  namespaced: true,
  state: {
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 10,
    },
    params: {
      fields: [
        'amount_in_cad',
        'contact_id',
        'comments',
        'executed_at',
        'external_id',
        'external_type',
        'status',
        'type',
        'contact.age',
        'contact.birthdate',
        'contact.district.name',
        'contact.email',
        'contact.full_name',
        'contact.home_phone',
        'contact.postal_code',
        'contact.status',
        'contact.v1_contact_id',
        'contributions.id',
        'memberships.id',
        'v1_contact_id',
      ].join(','),
    },
    transactionContactStatus: 'existing',
    useSelected: false,
  },
  getters: {
    transactionContactStatus(state) {
      return state.transactionContactStatus;
    },
    options(state) {
      return state.options;
    },
    params(state) {
      return state.params;
    },
    useSelected(state) {
      return state.useSelected;
    },
  },
  mutations: {
    setTransactionContactStatus(state, value) {
      state.transactionContactStatus = value;
    },
    setUseSelected(state, value) {
      state.useSelected = value;
    },
    options(state, options) {
      state.options = options;
    },
    params(state, params) {
      state.params = params;
    },
  },
};

export default transactionsView;
