






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class QsLargeChoice extends Vue {
  @Prop({ required: false, type: String, default: 'grey' }) color?: string;
  @Prop({ required: false, type: String, default: 'mdi-plus' }) icon?: string;
  @Prop({ required: false, type: String, default: 'Nouveau' }) text?: string;
}
