import { render, staticRenderFns } from "./Tags.vue?vue&type=template&id=3acc8a24&"
import script from "./Tags.vue?vue&type=script&lang=ts&"
export * from "./Tags.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3acc8a24')) {
      api.createRecord('3acc8a24', component.options)
    } else {
      api.reload('3acc8a24', component.options)
    }
    module.hot.accept("./Tags.vue?vue&type=template&id=3acc8a24&", function () {
      api.rerender('3acc8a24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Models/Tags/Tags.vue"
export default component.exports