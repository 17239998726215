
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Model } from 'qs_vuetify/src/types/models';
import { Route } from 'vue-router';

@Component
export default class BaseItemCard<M extends Model> extends Vue {
  @Prop({ required: true, type: Object }) item!: M;
  @Prop({ required: false, type: Boolean, default: false }) selected!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) showAlerts!: boolean;
  @Prop({ required: false, type: Boolean, default: true }) showChecked!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) showText!: boolean;
  @Prop({ required: false, type: [Object, String], default: null }) to!: Route;
}
