var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sent-sms-list" },
    [
      _c(
        "div",
        { staticClass: "sent-sms-list__search" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              loading: _vm.loading,
              placeholder: "Recherche",
              rounded: "",
              solo: ""
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [_c("v-icon", [_vm._v("mdi-magnify")])]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-list",
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-list-item",
            { key: item.id, attrs: { to: _vm.getLinkForItem(item.id) } },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(item.contact.first_name) + " "),
                    _c("strong", [_vm._v(_vm._s(item.contact.last_name))])
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(item.to || _vm.phone) + " ")
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(item.body) + " ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: item.unread_replies_count < 1,
                        icon: "",
                        loading:
                          _vm.markAsReadLoading === parseInt(item.id, 10),
                        small: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.$emit("click:read", item.id)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "success", small: "" } }, [
                        _vm._v("mdi-check-all")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-action-text", [
                    _vm._v(
                      " " + _vm._s(item.unread_replies_count) + " en attente "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }