



















































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsWarningIndicator from 'qs_vuetify/src/components/Indicators/QsWarningIndicator.vue';

import { PersistedMailCampaign, PersistedSentEmail } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';
import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';
import { Prop } from 'vue-property-decorator';

const global: any = namespace('global');
const store: any = namespace('sent_emails');
const view: any = namespace('sentEmailsViews');

@Component({
  components: {
    QsActionModal,
    QsWarningIndicator,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class SentEmailsForm extends mixins(DataRouteGuards, I18nMixin) {
  @Prop({ required: false, type: String, default: 'sent_emails' }) modelName!: string;
  @global.Mutation addNotification!: any;

  @store.Getter item!: PersistedSentEmail;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any;

  @view.Getter params!: RestParams;
  @view.Mutation setParams!: any;

  get isBounced(): boolean {
    if (this.item?.trackable_sent_email?.bounced) {
      return true;
    }

    return false;
  }

  get isClicked(): boolean {
    if (this.item?.trackable_sent_email?.clicked) {
      return true;
    }

    return false;
  }

  get isOpened(): boolean {
    if (this.item?.trackable_sent_email?.opened) {
      return true;
    }

    return false;
  }

  get mailCampaign(): PersistedMailCampaign | null {
    if (this.item?.trackable_sent_email?.mail_campaign) {
      return this.item.trackable_sent_email.mail_campaign;
    }

    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  clean(name: string): string {
    return name.replace(/\.|\\/g, '_').toLowerCase();
  }
}
