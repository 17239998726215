
































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import Fileable from 'qs_vuetify/src/mixins/Fileable';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { RestParams } from 'qs_vuetify/src/types/states';

import Importable from '@/mixins/Importable';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';

@Component({
  components: {
    QsActionModal,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NewContributionsImportsForm extends mixins(
  Fileable,
  Importable,
) {
  formOrder = [
    'name',
  ];

  mounted() {
    this.optionsImports('App\\Models\\Contribution');
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      'imports.index': ListMixin.buildListState(
        this.$store.getters['importsView/options'],
        this.$store.getters['importsView/params'],
      ),
    };
  }

  @Watch('file')
  onNewContributionsImportsFormFileChanged() {
    this.parseFile();
  }

  @Watch('fileId')
  onNewContributionsImportsFormFileIdChanged(newFileId: number | null) {
    this.syncImport({
      ...this.item,
      importable_type: 'App\\Models\\Contribution',
      file: {
        id: newFileId,
      },
    });
  }
}
