
























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsEditableTextField from 'qs_vuetify/src/components/Fields/QsEditableTextField.vue';

@Component({
  components: {
    QsEditableTextField,
  },
})
export default class Context extends Vue {
  @Prop({ required: false, default: null }) campaign!: any;
  @Prop({ required: false, default: null }) contact!: any;

  // eslint-disable-next-line class-methods-use-this
  async patch(field: string, value: any) {
    const data: Record<string, any> = {};
    data[field] = value;

    await this.$store.dispatch('contacts/update', {
      id: this.contact.id,
      data,
    });

    this.$emit('patch', { field, value });
  }
}
