
















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { PersistedContact } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';
import { DataTableOptions } from 'qs_vuetify/src/types/components';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

const store: any = namespace('mailbox_options');
const view: any = namespace('mailboxOptionViews');

@Component({
  components: {
    QsDataTable,
    QsFilters,
  },
})
export default class MailboxOptions extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  InteractsWithNavbar,
  ListMixin,
) {
  @store.Getter data!: PersistedContact[];
  @store.Getter exportUrl!: string;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation addSelectedItem!: any;
  @view.Mutation removeSelectedItem!: any;
  @view.Mutation setSelectedItems!: any;

  markingAsDone: number | null = null;
  headers = [
    { text: 'Numéro de membre', value: 'v1_contact_id', sortable: true },
    { text: 'Prénom', value: 'first_name', sortable: false },
    { text: 'Nom', value: 'last_name', sortable: false },
    { text: 'Circonscription', value: 'local_instance.name', sortable: false },
    { text: 'Échéance', value: 'last_membership_period_end', sortable: false },
    { text: 'Demandé le', value: 'mailbox_option_requested_at', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  setListParams = this.buildSetParam('mailbox_options', this.setParams);

  get viewParams() {
    return {
      mailbox_options: {
        ...ListMixin.buildListState(this.options, this.params),
        fields: [
          'first_name',
          'last_membership_period_end',
          'last_name',
          'local_instance.name',
          'mailbox_option_requested_at',
          'v1_contact_id',
        ].join(','),
        order: '-mailbox_option_requested_at',
      },
    };
  }

  async markAsDone(id: number) {
    this.markingAsDone = id;
    await this.$store.dispatch('tags/destroy', { id: 7, prefix: `contacts/${id}` });
    this.reloadDataRoutesData();
    this.markingAsDone = null;
  }
}
