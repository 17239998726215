
































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import I18nMixin from 'qs_vuetify/src/mixins/I18nMixin';

import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

@Component({
  components: {
    QsButton,
  },
})
export default class FieldsMatcher extends mixins(I18nMixin) {
  @Prop({ required: false, type: Array, default: () => [] }) availableFields!: string[];
  @Prop({ required: true, type: Array }) data!: { [key: string]: any }[];
  @Prop({ required: true, type: String }) modelName!: string;
  @Prop({ required: true, type: Array }) parsedFields!: string[];
  @Prop({ required: true, type: Object }) value!: { [key: string]: number }

  internalValueKeys: string[] = [];
  previewIndex = 0;

  mounted() {
    this.internalValueKeys = Object.keys(this.value);
  }

  get unusedFields(): string[] {
    return this.availableFields.filter((f) => !this.internalValueKeys.includes(f));
  }

  @Watch('value', { deep: true })
  onValueChanged() {
    this.internalValueKeys = Object.keys(this.value);
  }

  addField(name: string) {
    // const emittedVal = this.value;
    // emittedVal[name] = Object.keys(this.value).length;
    this.$emit('input', {
      ...this.value,
      [name]: null,
    });
  }

  updateField(name: string, newKey: string) {
    const emittedVal = this.value;
    const oldVal = this.value[name];
    const newValue = this.parsedFields.indexOf(newKey);
    const otherField = Object.keys(this.value)
      .find((key) => this.value[key] === newValue);

    if (otherField) {
      emittedVal[otherField] = oldVal;
    }

    emittedVal[name] = newValue;
    this.$emit('input', emittedVal);
  }

  removeField(name: string) {
    const emittedVal = this.value;
    delete emittedVal[name];
    const index = this.internalValueKeys.indexOf(name);
    this.internalValueKeys.splice(
      index,
      1,
    );
    this.$emit('input', emittedVal);
  }
}
