
















































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form, isGenericFormField } from 'qs_vuetify/src/types/components';
import { Contact, Email } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

const store: any = namespace('emails');
const view: any = namespace('emailsView');

const contacts: any = namespace('contacts');

@Component({
  components: {
    QsActionModal,
    QsContactListItem,
    QsFormBuilder,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class EmailDialog extends mixins(
  DataRouteGuards,
  FormMixin,
) {
  @store.Getter error!: ErrorResponse;
  @store.Getter item!: Email;
  @store.Getter form!: Form;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Mutation('item') syncItem!: any

  @view.Getter detailModal!: boolean;
  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('detailModal') setDetailModal!: any;

  @contacts.Getter('data') contacts!: Array<Contact>;

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.onRouteChanged();
    this.setDetailModal(true);
    this.$store.commit('contacts/item', this.item.contact);
  }

  get emailForm(): Form {
    const { form } = this;

    delete form.weight;

    if (isGenericFormField(form.contact_id) && form.contact_id.query?.params) {
      form.contact_id.query.params.fields = [
        'address',
        'apartment',
        'birthdate',
        'city',
        'district.id',
        'district.name',
        'email',
        'emails',
        'first_name',
        'full_name',
        'gender',
        'home_phone',
        'last_name',
        'membership_status',
        'postal_code',
        'searchable_text',
        'v1_contact_id',
      ].join(',');
    }

    return form;
  }

  get viewParams(): { [key: string]: RestParams } {
    return {
      emails: {
        fields: 'email,contact.*',
      },
      'emails.index': ListMixin.buildListState(this.options, this.params),
    };
  }

  afterSave() {
    this.$store.commit('global/addNotification', {
      color: 'success',
      message: 'Courriel enregistré.',
    });

    this.reloadDataRoutesData(['emails.index']);

    this.setDetailModal(false);
    this.$router.push('/emails');
  }

  @Watch('detailModal')
  onModalVisibilityChange(newModalVisibiliy: boolean) {
    if (!newModalVisibiliy) {
      this.$router.push('/emails/');
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.$store.commit('global/subtitle', 'Courriels');
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
      this.setGlobalTitle();
    }
  }

  setGlobalTitle() {
    if (this.isNew) {
      this.$store.commit('global/title', 'Nouveau courriel');
    } else {
      this.$store.commit('global/title', this.item?.email || `Courriel #${this.item.id}`);
    }
  }

  submitForm() {
    const { item } = this;
    delete item.contact;

    this.submit();
  }
}
