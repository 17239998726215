import { ActionTree, Module } from 'vuex';

import { Contact } from 'qs_vuetify/src/types/models';
import { ContactsRestState } from '@/types/states';
import { RestParams, RootState } from 'qs_vuetify/src/types/states';

import {
  buildRestActions,
  buildRestGetters,
  buildRestMutations,
  buildRestState,
} from 'qs_vuetify/src/plugins/rest';
import axios from 'qs_vuetify/src/plugins/axios';

export const state: ContactsRestState = buildRestState<Contact>('contacts', {});

export const getters = buildRestGetters<Contact, ContactsRestState>();

export const actions: ActionTree<ContactsRestState, RootState> = {
  ...buildRestActions<Contact, ContactsRestState>(),
  async addMemberships({ commit }, {
    contactId,
    duration,
    transactionId,
    periodStart,
  }: {
    contactId: number;
    duration: number;
    periodStart: string | null;
    transactionId: number;
  }) {
    try {
      await axios.put(`/contacts/${contactId}/add_memberships/${duration}`, {
        transaction_id: transactionId,
        period_start: periodStart,
      });
    } catch (e) {
      commit('error', e);
    }
  },
  async createDuplicate({ commit }, params: RestParams) {
    const {
      comment,
      target_id,
      ...rest
    } = params;
    try {
      await axios.put('/jobs/contacts/create_duplicate', {
        comment,
        target_id,
      }, {
        params: {
          ...rest,
        },
      });
    } catch (e) {
      commit('error', e);
    }
  },
  async democratikSync(_, id: number | string) {
    await axios.put('/jobs/contacts/democratik_sync', {}, {
      params: {
        id,
      },
    });
  },
  async fixMembershipsPeriodStart({ commit }, params: RestParams) {
    try {
      await axios.put('/jobs/contacts/fix_memberships_period_start', {}, {
        params: {
          ...params,
          per_page: '*',
        },
      });
    } catch (e) {
      commit('error', e);
    }
  },
  async updateAdministrativeEntities({ commit }, params: RestParams) {
    try {
      await axios.put('/jobs/contacts/update_administrative_entities', {}, {
        params: {
          ...params,
          per_page: '*',
        },
      });
    } catch (e) {
      commit('error', e);
    }
  },
  async updateInstanceContactsJoinTable({ commit }, params: RestParams) {
    try {
      await axios.put('/jobs/contacts/update_instance_contacts_join_table', {}, {
        params: {
          ...params,
          per_page: '*',
        },
      });
    } catch (e) {
      commit('error', e);
    }
  },
};

export const mutations = buildRestMutations<Contact, ContactsRestState>();

export const contacts: Module<ContactsRestState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
