var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { value: true },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                !_vm.itemReady
                  ? [_vm._v(" Chargement... ")]
                  : _vm.isNew
                  ? [_vm._v(" Nouveau téléphone ")]
                  : [
                      _vm._v(
                        " Modifier " +
                          _vm._s(_vm.$store.getters["global/subtitle"]) +
                          " "
                      )
                    ]
              ]
            },
            proxy: true
          },
          _vm.userHas("PHONES_UPDATE")
            ? {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "qs-button",
                      {
                        attrs: { theme: "labase" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady
        ? [
            _vm.item
              ? _c("qs-form-builder", {
                  attrs: {
                    errors: _vm.error,
                    form: _vm.form,
                    item: _vm.item,
                    "model-name": _vm.slug,
                    order: _vm.formOrder
                  },
                  on: { input: _vm.syncItem }
                })
              : _vm._e(),
            _vm.itemReady && !_vm.isNew
              ? _c(
                  "v-expansion-panels",
                  { attrs: { flat: "", tile: "" } },
                  [
                    _c(
                      "v-expansion-panel",
                      [
                        _c(
                          "v-expansion-panel-header",
                          { staticClass: "pl-0 font-weight-bold" },
                          [
                            _vm._v(
                              " Appartient à " +
                                _vm._s(
                                  _vm.$tc(
                                    "models.contacts.counted_name",
                                    _vm.item.contacts.length
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "v-list",
                              _vm._l(_vm.item.contacts, function(contact) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: contact.id,
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push(
                                          "/contacts/" + contact.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("qs-contact-list-item", {
                                          attrs: { item: contact }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _c("qs-button", {
                                          attrs: {
                                            color: "error",
                                            icon: "mdi-close",
                                            label: "Dissocier",
                                            small: "",
                                            theme: "labase"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeContact(contact)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _vm.isAddingContact
                              ? _c("qs-relation-field", {
                                  attrs: {
                                    item: { contact: _vm.contact },
                                    label: "Chercher un contact",
                                    name: "contact_id",
                                    query:
                                      _vm.filtersDefinition["contacts.id"].query
                                  },
                                  on: { input: _vm.addContact },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("qs-contact-list-item", {
                                              attrs: { item: item }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    945673654
                                  ),
                                  model: {
                                    value: _vm.contact,
                                    callback: function($$v) {
                                      _vm.contact = $$v
                                    },
                                    expression: "contact"
                                  }
                                })
                              : _vm.userHas("PHONES_UPDATE")
                              ? _c(
                                  "qs-button",
                                  {
                                    attrs: {
                                      block: "",
                                      color: "info",
                                      small: "",
                                      theme: "labase"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.isAddingContact = true
                                      }
                                    }
                                  },
                                  [_vm._v(" Ajouter ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : [
            _c(
              "div",
              {
                staticClass: "d-flex justify-center align-center",
                staticStyle: { height: "30vh" }
              },
              [
                _c("v-progress-circular", {
                  attrs: { color: "primary", indeterminate: "" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }