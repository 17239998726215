<template>
  <v-app>
    <qs-layout-navigation
      :admin-menu="adminMenuItems"
      :primary-menu="primaryMenuItems"
      :show-notifications="!!user"
      theme="labase"
      version-link="https://docs.google.com/document/d/1-alIGPg0CEgLxB9xAhoTtrFy0E8ggRX0-j6vg290WC4/edit?usp=sharing"
      version-number="1.0.0"
      @login="loadUserOrRedirect"
      @logout="redirectToHome"
    />

    <v-main>
      <qs-layout-notifications />

      <router-view />

      <qs-confirmation-modal
        v-bind="confirmationDialog"
        @click:confirm="runConfirmationDialogCallback"
        @click:cancel="resetConfirmationDialog"
      />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsLayoutNavigation from 'qs_vuetify/src/components/Layout/QsLayoutNavigation.vue';
import QsLayoutNotifications from 'qs_vuetify/src/components/Layout/QsLayoutNotifications.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import MenusMixin from 'qs_vuetify/src/mixins/MenusMixin';

import { ConfirmationDialog, MenuItem } from 'qs_vuetify/src/types/components';
import { User } from 'qs_vuetify/src/types/models';

import { routes } from '@/router/index';

export const userFields = [
  'id', 'email', 'superadmin', 'contact_id', 'contact_name',
  'unread_notifications', 'default_instance_id', 'accepted_confidentiality_agreement',
  'rights_slugs', 'inherited_instances.id', 'inherited_instances.name',
].join(',');

@Component({
  components: {
    QsConfirmationModal,
    QsLayoutNavigation,
    QsLayoutNotifications,
  },
})
export default class App extends mixins(AuthenticationMixin, MenusMixin) {
  @Action('checkUser', { namespace: 'auth' }) checkUser!: any;
  @Action('loadUser', { namespace: 'auth' }) loadUser!: any;
  @Action('logout', { namespace: 'auth' }) logout!: any;
  @Getter('token', { namespace: 'auth' }) token!: string | null;
  @Getter('user', { namespace: 'auth' }) user!: User | null;

  @Getter('confirmationDialog', { namespace: 'global' }) confirmationDialog!: ConfirmationDialog;
  @Mutation('resetConfirmationDialog', { namespace: 'global' }) resetConfirmationDialog!: any;
  @Mutation('runConfirmationDialogCallback', { namespace: 'global' }) runConfirmationDialogCallback!: any;

  get adminMenuItems(): Array<MenuItem> {
    if (!this.userIsConnected) {
      return [];
    }

    return [
      {
        title: 'La Base',
        route: process.env.VUE_APP_LABASE_URL ? process.env.VUE_APP_LABASE_URL : '/',
        target: '_blank',
      },
      {
        title: 'Profil',
        route: process.env.VUE_APP_PROFIL_URL ? process.env.VUE_APP_PROFIL_URL : '/',
        target: '_blank',
      },
    ];
  }

  get primaryMenuItems(): Array<MenuItem> {
    const submenus = [
      { slug: 'moderation', title: 'Modération' },
      { slug: 'imports', title: 'Importations' },
      { slug: 'transactions', title: 'Transactions' },
      { slug: 'others', title: 'Autres' },
    ];
    return [
      ...this.buildPrimaryMenuItems(routes),
      ...this.filterParentMenuItems(
        submenus.map((i) => this.buildParentMenuItem(routes, i.slug, i.title)),
      ),
    ];
  }

  async mounted() {
    await this.loadUserOrRedirect();
  }

  async loadUserOrRedirect() {
    const {
      fullPath,
      name: routeName,
    } = this.$route;

    if (!this.user) {
      if (this.token) {
        try {
          await this.loadUser(userFields);
        } catch (e) {
          if (e.response.status === 401) {
            await this.logout();

            this.redirectAfterLoginFailure(routeName, fullPath);
            return;
          }
        }
      } else {
        this.redirectAfterLoginFailure(routeName, fullPath);
        return;
      }
    } else {
      try {
        await this.checkUser('id');

        this.loadUser(userFields);

        this.onUserIsConnectedChanged(true);
      } catch (e) {
        await this.logout();

        this.redirectAfterLoginFailure(routeName, fullPath);
        return;
      }
    }

    await this.$store.dispatch('districts/index', {
      fields: 'name',
      order: 'name',
      per_page: '*',
    });
  }

  redirectAfterLoginFailure(routeName: string | null | undefined, fullPath: string) {
    if (routeName !== 'Home') {
      if (!routeName) {
        this.redirectToHome(document.location.pathname);
      } else {
        this.redirectToHome(fullPath);
      }

      this.$store.commit('global/loginDialog', true);
    }
  }

  redirectToHome(redirect: string | null = null) {
    this.$router.push(`/${redirect ? `?r=${encodeURIComponent(redirect)}` : ''}`);
  }

  @Watch('userIsConnected')
  onUserIsConnectedChanged(connected: boolean) {
    if (connected) {
      if (this.$route.query && this.$route.query.r) {
        this.$router.push(`${this.$route.query.r}`);
      }
    } else {
      this.redirectToHome(this.$route.fullPath);
    }
  }
}
</script>
