import { render, staticRenderFns } from "./AutomatedEmailTemplateDialog.vue?vue&type=template&id=fd4fd2ac&"
import script from "./AutomatedEmailTemplateDialog.vue?vue&type=script&lang=ts&"
export * from "./AutomatedEmailTemplateDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fd4fd2ac')) {
      api.createRecord('fd4fd2ac', component.options)
    } else {
      api.reload('fd4fd2ac', component.options)
    }
    module.hot.accept("./AutomatedEmailTemplateDialog.vue?vue&type=template&id=fd4fd2ac&", function () {
      api.rerender('fd4fd2ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AutomatedEmailTemplates/AutomatedEmailTemplateDialog.vue"
export default component.exports