import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { capitalize } from 'qs_vuetify/src/plugins/filters';
import i18n from 'qs_vuetify/src/plugins/i18n';

const ContributionModal = () => import('@/views/Contributions/ContributionModal.vue');
const Contributions = () => import('@/views/Contributions/Contributions.vue');
const Memberships = () => import('@/views/Memberships/Memberships.vue');
const NewTransactionsForm = () => import('@/views/Transactions/NewTransactionsForm.vue');
const TransactionsForm = () => import('@/views/Transactions/TransactionsForm.vue');
const Transactions = () => import('@/views/Transactions/Transactions.vue');

const transactionRoutes = [
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    meta: {
      data: {
        transactions: {
          index: {},
        },
      },
      title: capitalize(i18n.tc('models.transactions.name', 2)),
      menus: ['transactions'],
      requires: ['TRANSACTIONS_RETRIEVE'],
    },
    children: [
      {
        component: NewTransactionsForm,
        name: 'NewTransactionsForm',
        path: 'new',
        props: () => ({ id: 'new' }),
        meta: {
          data: {
            contacts: {
              loadEmpty: {},
            },
            transactions: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.transactions.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.transactionsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
            },
          },
          title: 'Nouvelle transaction',
          menus: [],
          requires: ['TRANSACTIONS_CREATE'],
        },
      },
      {
        path: ':id',
        name: 'TransactionsForm',
        component: TransactionsForm,
        props: true,
        meta: {
          data: {
            transactions: {
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.transactions.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.transactionsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
            },
          },
          title: capitalize(i18n.tc('models.transactions.name', 1)),
          menus: [],
          requires: ['TRANSACTIONS_RETRIEVE'],
        },
      },
    ],
  },
  {
    path: '/memberships',
    name: 'Memberships',
    component: Memberships,
    meta: {
      data: {
        memberships: {
          index: {},
        },
      },
      title: capitalize(i18n.tc('models.memberships.name', 2)),
      menus: ['transactions'],
      requires: ['MEMBERSHIPS_RETRIEVE'],
    },
  },
  {
    path: '/contributions',
    name: 'Contributions',
    component: Contributions,
    meta: {
      data: {
        contributions: {
          index: {},
        },
        contribution_types: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.contribution_types.lastLoadedAt;
            },
            params: {
              fields: '*',
              order: 'name',
              per_page: '*',
            },
          },
        },
        transactions: {
          options: {},
        },
      },
      title: capitalize(i18n.tc('models.contributions.name', 2)),
      menus: ['transactions'],
      requires: ['CONTRIBUTIONS_RETRIEVE'],
    },
    children: [
      {
        path: ':id',
        name: 'ContributionModal',
        component: ContributionModal,
        props: true,
        meta: {
          data: {
            contributions: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contributions.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.contributionsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
              },
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
              },
            },
            contribution_types: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.contribution_types.lastLoadedAt;
                },
                params: {
                  fields: '*',
                  order: 'name',
                  per_page: '*',
                },
              },
            },
          },
          title: capitalize(i18n.tc('models.contributions.name', 1)),
          menus: [],
          requires: ['CONTRIBUTIONS_RETRIEVE'],
        },
      },
    ],
  },
];

export default transactionRoutes;
