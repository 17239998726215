












































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import InteractsWithNavbar from '@/mixins/InteractsWithNavbar';

import Context from '@/components/sms/Context.vue';
import Messenger from '@/components/sms/Messenger.vue';
import SentSmsList from '@/components/sms/SentSmsList.vue';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';

import { ButtonProps, DialogProps } from 'qs_vuetify/src/types/components';
import { SmsCampaign } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

const sent_sms: any = namespace('sent_sms');
const sms_campaigns: any = namespace('sms_campaigns');

@Component({
  components: {
    Context,
    Messenger,
    SentSmsList,
    QsConfirmationModal,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class Conversations extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  InteractsWithNavbar,
) {
  @sent_sms.Getter data!: Array<any>;
  @sent_sms.Getter exportUrl!: string;
  @sent_sms.Getter filtersDefinition!: FiltersDefinition;
  @sent_sms.Getter item!: any;
  @sent_sms.Getter loading!: boolean;
  @sent_sms.Getter markAsReadLoading!: number | null;
  @sent_sms.Getter replyLoading!: number | null;
  @sent_sms.Getter slug!: string;
  @sent_sms.Getter queuedTotal!: number;
  @sent_sms.Getter total!: number;
  @sent_sms.Action markAsRead!: (id: number) => void;
  @sent_sms.Action reply!: (payload: {id: number; body: string}) => void;

  @sms_campaigns.Getter('item') smsCampaign!: SmsCampaign | null;
  @sms_campaigns.Action send!: (payload: { id: number; amount?: number }) => void;

  autoFetch = false;
  replyBody = '';
  sendDialog: DialogProps = {
    callback: () => this.send({ id: this.item.id }),
    color: 'warning',
    loading: false,
    message: 'Cette action est irréversible.',
    title: 'Êtes-vous certain·e de vouloir envoyer?',
    value: false,
  }

  mounted() {
    this.setGlobalActions();
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'sms_campaigns.retrieve': {
        fields: [
          'name',
          'status',
          'instance.name',
          'start_at',
          'end_at',
          'initial_message',
          'preformatted_replies',
          'filter',
        ].join(','),
      },
    };
  }

  @Watch('autoFetch')
  onAutoFetchChanged() {
    this.setGlobalActions();
    this.onRouteDataLoadedChanged(true);
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      // this.onRouteChanged();
      this.setGlobalTitle();
      this.setGlobalSubtitle();

      if (this.autoFetch) {
        setTimeout(() => {
          this.reloadDataRoutesData(['sent_sms']);
        }, (1000 * 60));
      }
    }
  }

  @Watch('$route', { deep: true })
  onRouteParamsChanged() {
    this.reloadDataRoutesData();
    this.setGlobalSubtitle();
  }

  setGlobalTitle() {
    this.$store.commit('global/title', 'SMS');
    this.$emit('updateHead');
  }

  setGlobalActions() {
    const actions: Array<ButtonProps> = [];

    if (this.userHas('SMS_CAMPAIGNS_UPDATE')) {
      actions.push({
        onClick: () => {
          this.$router.push(`/sms/${this.$route.params.sms_campaign_id}`);
        },
        color: 'info',
        icon: 'mdi-message-processing',
        tooltip: 'Retour à la campagne',
      });
    }

    if (this.userHas('SMS_CAMPAIGNS_UPDATE') && this.userHas('SMS_CAMPAIGNS_DESTROY')) {
      actions.push({
        onClick: () => { this.sendDialog.value = true; },
        color: 'success',
        disabled: !this.smsCampaign || this.smsCampaign.status !== 'active' || this.queuedTotal > 5,
        progress: this.queuedTotal > 5 ? 100 - this.queuedTotal : null,
        icon: 'mdi-send',
        tooltip: 'Envoyer',
      });
    }

    actions.push({
      onClick: () => { this.autoFetch = !this.autoFetch; },
      color: this.autoFetch ? 'warning' : 'grey',
      icon: 'mdi-radio-tower',
      tooltip: 'Récupérer automatiquement les nouvelles réponses',
    });

    this.commitGlobalActions(actions);
  }

  setGlobalSubtitle() {
    this.$store.commit('global/subtitle', this.smsCampaign ? this.smsCampaign.name : 'SMS');
  }
}
