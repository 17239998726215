import { Module } from 'vuex';

import { ImportsViewState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const importsViewState: Module<ImportsViewState, RootState> = {
  namespaced: true,
  state: {
    options: {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
      itemsPerPage: 20,
    },
    showAlerts: true,
    params: {
      fields: [
        '*',
        'created_by_user.contact_name',
        'source.name',
        'tags.name',
      ].join(','),
      created_at: 'P1M:',
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
    params(state) {
      return state.params;
    },
  },
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    showAlerts(state, showAlerts) {
      state.showAlerts = showAlerts;
    },
    setParams(state, params) {
      state.params = params;
    },
  },
};

export default importsViewState;
