var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", lg: "3", xl: "3" } },
            [
              _c("qs-export-menu", {
                on: {
                  export: function($event) {
                    return _vm.exportWithWarningAs($event)
                  }
                }
              }),
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": Object.assign(
                    {},
                    _vm.filtersDefinition,
                    _vm.transactionFilters
                  ),
                  flat: "",
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: { input: _vm.setContributionsParam },
                scopedSlots: _vm._u([
                  {
                    key: "contact.id.item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-contact-list-item", { attrs: { item: item } })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "7", lg: "9", xl: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  actions: _vm.userHas("CONTRIBUTIONS_UPDATE") ? ["edit"] : [],
                  flat: "",
                  headers: _vm.headers,
                  items: _vm.data,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  tile: "",
                  total: _vm.total || 0
                },
                on: {
                  edit: function($event) {
                    return _vm.goto("contributions", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.transaction_id",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("transaction-admin-popover", {
                              attrs: { item: item.transaction }
                            }),
                            _vm._v(" #" + _vm._s(item.transaction_id) + " ")
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.contact_full_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.contact
                          ? _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c("contact-admin-popover", {
                                  attrs: { item: item.contact }
                                }),
                                _vm._v(
                                  " " + _vm._s(item.contact_full_name) + " "
                                )
                              ],
                              1
                            )
                          : _c("span", {}, [_vm._v("Contact manquant")])
                      ]
                    }
                  },
                  {
                    key: "item.affected_to_instance",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.affected_to_instance }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.amount_in_cad",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(item.amount_in_cad)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }