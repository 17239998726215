var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: ["qs-stats-card--" + _vm.color], attrs: { tile: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "end" } },
            [
              _c(
                "v-col",
                { staticClass: "display-2 text-right" },
                [
                  _vm.type === "currency"
                    ? [_vm._v(" " + _vm._s(_vm._f("currency")(_vm.stat)) + " ")]
                    : [_vm._v(" " + _vm._s(_vm._f("number")(_vm.stat)) + " ")]
                ],
                2
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "p",
                  { staticClass: "caption text-uppercase text-right mt-2" },
                  [_vm._v(" " + _vm._s(_vm.label) + " ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }