var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { attrs: { onSubmit: "return false;" } },
    [
      _vm.itemReady
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("first_name"),
                            description: _vm.descriptionOrNothing("first_name"),
                            value: _vm.item["first_name"],
                            name: "first_name",
                            errors: _vm.errorMessagesFor("first_name")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["first_name"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("last_name"),
                            description: _vm.descriptionOrNothing("last_name"),
                            value: _vm.item["last_name"],
                            name: "last_name",
                            errors: _vm.errorMessagesFor("last_name")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["last_name"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("qs-select-field", {
                      attrs: {
                        clearable: "",
                        description: _vm.descriptionOrNothing("gender"),
                        errors: _vm.errorMessagesFor("gender"),
                        items: _vm.labelsOrValues("gender", [
                          "female",
                          "male",
                          "other"
                        ]),
                        label: _vm.labelOrName("gender"),
                        name: "gender",
                        value: _vm.item.gender
                      },
                      on: {
                        input: function($event) {
                          _vm.item["gender"] = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("qs-text-field", {
                      attrs: {
                        errors: _vm.errorMessagesFor("birthdate"),
                        label: _vm.labelOrName("birthdate"),
                        mask: "####-##-##",
                        name: "birthdate",
                        placeholder: "AAAA-MM-JJ",
                        required: "",
                        value: _vm.item["birthdate"]
                      },
                      on: {
                        input: function($event) {
                          _vm.item["birthdate"] = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("address"),
                            description: _vm.descriptionOrNothing("address"),
                            value: _vm.item["address"],
                            name: "address",
                            errors: _vm.errorMessagesFor("address")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["address"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("city"),
                            description: _vm.descriptionOrNothing("city"),
                            value: _vm.item["city"],
                            name: "city",
                            errors: _vm.errorMessagesFor("city")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["city"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "3" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("apartment"),
                            description: _vm.descriptionOrNothing("apartment"),
                            value: _vm.item["apartment"],
                            name: "apartment",
                            errors: _vm.errorMessagesFor("apartment")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["apartment"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "6", md: "3" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("postal_code"),
                            description: _vm.descriptionOrNothing(
                              "postal_code"
                            ),
                            value: _vm.item["postal_code"],
                            name: "postal_code",
                            errors: _vm.errorMessagesFor("postal_code")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["postal_code"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c(
                      "qs-text-field",
                      _vm._b(
                        {
                          attrs: {
                            label: _vm.labelOrName("home_phone"),
                            description: _vm.descriptionOrNothing("home_phone"),
                            value: _vm.item["home_phone"],
                            name: "home_phone",
                            errors: _vm.errorMessagesFor("home_phone")
                          },
                          on: {
                            input: function($event) {
                              _vm.item["home_phone"] = $event
                            }
                          }
                        },
                        "qs-text-field",
                        null,
                        false
                      )
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6" } },
                  [
                    _c("qs-email-field", {
                      ref: "qs-text-field-emails",
                      attrs: {
                        value: _vm.item.emails,
                        "contact-id": _vm.item.id,
                        dense: "",
                        name: "emails",
                        theme: "labase"
                      },
                      on: {
                        input: function($event) {
                          _vm.item["emails"] = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("qs-district-form", {
              staticClass: "mt-3",
              attrs: { "postal-code": _vm.item["postal_code"] || "123456" },
              on: {
                input: function($event) {
                  return _vm.updateDistrict($event)
                }
              },
              model: {
                value: _vm.item.district,
                callback: function($$v) {
                  _vm.$set(_vm.item, "district", $$v)
                },
                expression: "item.district"
              }
            })
          ]
        : [
            _c(
              "div",
              { staticClass: "d-flex justify-center align-center" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "primary" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }