var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: { color: "white", dark: false, value: _vm.detailModal },
      on: {
        "click:close": function($event) {
          return _vm.setDetailModal(false)
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Modifier le courriel ")]
            },
            proxy: true
          },
          _vm.item
            ? {
                key: "subtitle",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.item.email) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      depressed: "",
                      disabled:
                        (!_vm.isNew && !_vm.hasChanged) || !_vm.routeDataLoaded,
                      tile: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitForm()
                      }
                    }
                  },
                  [_vm._v(" Enregistrer ")]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.itemReady
        ? _c("qs-form-builder", {
            attrs: {
              error: _vm.error,
              form: _vm.emailForm,
              item: _vm.item,
              "model-name": _vm.slug,
              order: ["contact_id", "email"]
            },
            on: { input: _vm.syncItem },
            scopedSlots: _vm._u(
              [
                {
                  key: "contact_id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                },
                {
                  key: "contact_id.selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", { attrs: { item: item } })
                    ]
                  }
                }
              ],
              null,
              false,
              2143680124
            )
          })
        : _c(
            "div",
            { staticClass: "d-flex justify-center align-center" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }