var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        dark: false,
        height: "70vh",
        loading: _vm.loading,
        "max-width": "800",
        value: true
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v(" Nouvelle adhésion ")]
          },
          proxy: true
        },
        {
          key: "prepend",
          fn: function() {
            return [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-4 qs-new-transaction-tabs",
                  attrs: { grow: "" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c("v-tab", [_vm._v("Contact")]),
                  _c(
                    "v-tab",
                    { attrs: { disabled: !_vm.canContinueToNextStep } },
                    [_vm._v("Adhésion")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _vm.step === 0 && _vm.isNew
                ? _c(
                    "qs-button",
                    {
                      attrs: {
                        disabled: !_vm.canContinueToNextStep,
                        theme: "labase"
                      },
                      on: {
                        click: function($event) {
                          _vm.step = 1
                        }
                      }
                    },
                    [
                      _vm._v(" Suivant "),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v("mdi-chevron-right")
                      ])
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "qs-button",
                        {
                          attrs: {
                            disabled:
                              (!_vm.isNew && !_vm.hasChanged) ||
                              !_vm.routeDataLoaded,
                            loading: _vm.loading,
                            theme: "labase"
                          },
                          on: {
                            click: function($event) {
                              _vm.isNew ? _vm.submitMembership() : _vm.submit()
                            }
                          }
                        },
                        [_vm._v(" Enregistrer ")]
                      )
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.step,
            callback: function($$v) {
              _vm.step = $$v
            },
            expression: "step"
          }
        },
        [
          _c(
            "v-tab-item",
            [
              [
                _c(
                  "v-card",
                  { staticClass: "mb-4", attrs: { color: "grey lighten-4" } },
                  [
                    _vm.transactionContactStatus === "existing"
                      ? _c(
                          "v-card-text",
                          [
                            _c("qs-relation-field", {
                              attrs: {
                                description:
                                  "Par prénom, nom, id ou numéro de membre",
                                item: {},
                                label: "Rechercher un contact",
                                name: "find-contact",
                                query: _vm.contactQuery,
                                value: _vm.membership.contact_id
                              },
                              on: { input: _vm.onQueriedContactInput },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("qs-contact-list-item", {
                                          attrs: { item: item }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.full_name) + " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                854319488
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-card-actions",
                      { staticClass: "align-center justify-center" },
                      [
                        _c(
                          "qs-split-button",
                          {
                            attrs: {
                              disabled: !!_vm.membership.contact_id,
                              theme: "labase"
                            },
                            on: { "click:main": _vm.toggleContactStatusMode },
                            scopedSlots: _vm._u([
                              {
                                key: "main-button",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.transactionContactStatus ===
                                            "existing"
                                            ? "Créer un nouveau contact"
                                            : "Chercher un contact existant"
                                        ) +
                                        " "
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.contactsLoadEmpty } },
                                  [_vm._v(" Vider le formulaire de contact ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                !_vm.contactLoading &&
                ((_vm.transactionContactStatus === "existing" &&
                  _vm.contactItem &&
                  _vm.contactItem.id) ||
                  _vm.transactionContactStatus === "new")
                  ? _c("qs-contact-form", {
                      attrs: { id: "new", "model-name": "contacts" }
                    })
                  : _vm._e(),
                _vm.contactLoading
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-center align-center" },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ],
            2
          ),
          _vm.form
            ? _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("h4", { staticClass: "overline mb-0" }, [
                            _vm._v("Type de renouvellement")
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { row: "" },
                              model: {
                                value: _vm.membership.renewal,
                                callback: function($$v) {
                                  _vm.$set(_vm.membership, "renewal", $$v)
                                },
                                expression: "membership.renewal"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  disabled: "",
                                  label: "Automatique",
                                  value: "auto"
                                }
                              }),
                              _c("v-radio", {
                                attrs: { label: "Manuel", value: "manual" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("h4", { staticClass: "overline mb-0" }, [
                            _vm._v("Durée de l'adhésion")
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { row: "" },
                              model: {
                                value: _vm.membership.duration,
                                callback: function($$v) {
                                  _vm.$set(_vm.membership, "duration", $$v)
                                },
                                expression: "membership.duration"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "1 an", value: "1" }
                              }),
                              _c("v-radio", {
                                attrs: { label: "2 ans", value: "2" }
                              }),
                              _c("v-radio", {
                                attrs: { label: "3 ans", value: "3" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              hint: _vm.descriptionOrNothing("type"),
                              items: _vm.labelsOrValues(
                                "type",
                                _vm.form.type.rules.oneOf
                              ),
                              label: _vm.labelOrNothing("type"),
                              "persistent-hint": !!_vm.descriptionOrNothing(
                                "type"
                              ),
                              placeholder: _vm.labelOrNothing("type")
                            },
                            model: {
                              value: _vm.membership.payment_method,
                              callback: function($$v) {
                                _vm.$set(_vm.membership, "payment_method", $$v)
                              },
                              expression: "membership.payment_method"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("qs-text-field", {
                            attrs: {
                              label: _vm.labelOrNothing("executed_at"),
                              name: "executed_at",
                              mask: "####-##-## ##:##:##",
                              placeholder: "AAAA-MM-JJ hh:mm:ss",
                              required: ""
                            },
                            model: {
                              value: _vm.membership.executed_at,
                              callback: function($$v) {
                                _vm.$set(_vm.membership, "executed_at", $$v)
                              },
                              expression: "membership.executed_at"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Montant total de la transaction",
                              rules: [
                                function(value) {
                                  return !!value || "Montant requis"
                                }
                              ],
                              type: "number"
                            },
                            model: {
                              value: _vm.membership.amount_in_cad,
                              callback: function($$v) {
                                _vm.$set(_vm.membership, "amount_in_cad", $$v)
                              },
                              expression: "membership.amount_in_cad"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("qs-text-field", {
                            attrs: {
                              hint: "Numéro de chèque",
                              label: "Identifiant externe",
                              errors: _vm.errorMessagesFor("external_id")
                            },
                            model: {
                              value: _vm.membership.external_id,
                              callback: function($$v) {
                                _vm.$set(_vm.membership, "external_id", $$v)
                              },
                              expression: "membership.external_id"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }