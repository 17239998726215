





































































































import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsContactListItem from 'qs_vuetify/src/components/Contacts/QsContactListItem.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { Duplicate, Filter, Persisted } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import DuplicatesInteractions from '@/components/DuplicatesInteractions.vue';
import QsStatsCard from '@/components/QsStatsCard.vue';

import { duplicatesFields } from '@/store/views/index';

const filters: any = namespace('filters');
const store: any = namespace('duplicates');
const view: any = namespace('duplicatesView');

@Component({
  components: {
    DuplicatesInteractions,
    QsBooleanIndicator,
    QsButton,
    QsConfirmationModal,
    QsContactListItem,
    QsDataTable,
    QsFilters,
    QsStatsCard,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})

export default class Duplicates extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ListMixin,
) {
  @filters.Getter('data') duplicateFilters!: Filter[];

  @store.Getter data!: Array<Duplicate>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter mergeDuplicatesIds!: number[];
  @store.Getter loading!: boolean;
  @store.Getter total!: number;
  @store.Getter slug!: string;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Getter selectedItems!: Persisted<Duplicate>[];
  @view.Mutation setOptions!: any;
  @view.Mutation setParams!: any;
  @view.Mutation setSelectedItems!: any;

  setDuplicatesFilter(filter: RestParams) {
    this.setParams(filter);
    this.reloadDataRoutesData(['duplicates.index'], true);
  }

  setDuplicatesParam = this.buildSetParam('duplicates', this.setParams);

  headers = [
    // { text: 'ID', value: 'id' },
    { text: 'Contact doublon', value: 'duplicate_contact.full_name', sortable: false },
    { text: 'Contact original', value: 'original_contact.full_name', sortable: false },
    {
      text: 'Interactions',
      value: 'interactions',
      sortable: false,
      align: 'center',
    },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  mounted() {
    this.setAction();
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      'duplicates.index': {
        ...ListMixin.buildListState(this.options, this.params),
        fields: duplicatesFields,
      },
      duplicates: {
        fields: duplicatesFields,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getDataTableItemClass(item: Duplicate) {
    const classes: string[] = [];
    if (item.is_duplicate === false) {
      classes.push('data-table-item--false-duplicate');
    }

    if (item.moderated_at) {
      classes.push('data-table-item--moderated');
    } else {
      classes.push('data-table-item--unmoderated');
    }

    return classes.join(' ');
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setAction();
    this.reloadDataRoutesData();
  }

  confirmAndMergeDuplicates(duplicateId: number | null = null) {
    this.confirmList(
      '',
      'Cette action réattribue plusieurs relations du doublon au contact original.'
       + ' Elle est irréversible.',
      'warning',
      'mdi-alert',
      async () => {
        await this.$store.dispatch('duplicates/mergeDuplicates', {
          params: { id: duplicateId || this.selectedItems.map((i) => i.id).join(',') },
        });

        this.$store.commit('global/addNotification', {
          color: 'success',
          message: 'Succès',
        });
        setTimeout(() => this.reloadDataRoutesData(['duplicates.index']), 500);
      },
    );
  }

  setAction() {
    if (this.userHas('DUPLICATES_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => {
            this.$router.push('/duplicates/new');
          },
          color: 'primary',
          icon: 'mdi-plus',
        },
      ]);
    }
  }
}
