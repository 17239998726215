import { render, staticRenderFns } from "./QsContactInstancesSection.vue?vue&type=template&id=461b94f9&"
import script from "./QsContactInstancesSection.vue?vue&type=script&lang=ts&"
export * from "./QsContactInstancesSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VChip,VIcon,VSpacer,VTreeview})


/* hot reload */
if (module.hot) {
  var api = require("/builds/quebec-solidaire/qs_administration/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('461b94f9')) {
      api.createRecord('461b94f9', component.options)
    } else {
      api.reload('461b94f9', component.options)
    }
    module.hot.accept("./QsContactInstancesSection.vue?vue&type=template&id=461b94f9&", function () {
      api.rerender('461b94f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contacts/QsContactInstancesSection.vue"
export default component.exports