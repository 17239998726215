































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import Resizeable from 'qs_vuetify/src/mixins/Resizeable';

import axios from 'qs_vuetify/src/plugins/axios';

const global: any = namespace('global');

@Component
export default class EventsVisibilityIndicator extends mixins(Resizeable) {
  @global.Mutation addNotification!: any;

  @Prop({ required: false, type: String, default: null }) color!: string | null;
  @Prop({ required: true, type: Number }) eventId!: number;
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean;
  @Prop({ required: false, type: Boolean, default: false }) isPast!: boolean;
  @Prop({ required: false, type: String, default: null }) value!: string | null;
  @Prop({ required: false, type: Boolean, default: false }) withTooltip!: boolean;

  loading = false;
  speedDialOpen = false;

  get computedColor(): string {
    if (this.color) {
      return this.color;
    }

    switch (this.value) {
      case 'public':
        return 'info';

      case 'private':
        return 'info';

      default:
        return 'grey';
    }
  }

  get icon(): string {
    switch (this.value) {
      case 'public':
        return 'mdi-eye';

      case 'private':
        return 'mdi-eye-off';

      default:
        return 'mdi-file';
    }
  }

  get tooltipLabel(): string {
    switch (this.value) {
      case 'public':
        return 'Public - Accessible sur Mouvement';

      case 'private':
        return "Privé - Accessible seulement par l'instance sur La Base";

      default:
        return 'Brouillon';
    }
  }

  async updateContactEventIntention(visibility: string) {
    this.loading = true;

    try {
      await axios.put(`/events/${this.eventId}`, {
        visibility,
      });

      this.addNotification({
        message: 'Modification enregistrée.',
        color: 'success',
        timeout: 2500,
      });

      this.$emit('input', visibility);
    } catch (e) {
      this.addNotification({
        message: 'Échec de la modification.',
        color: 'error',
        timeout: 2500,
      });
    } finally {
      this.loading = false;
    }
  }
}
