var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                flat: "",
                "model-name": _vm.slug,
                theme: "labase"
              },
              on: { input: _vm.setListParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("sms", $event)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.unread_replies_count
                        ? _c(
                            "v-badge",
                            { attrs: { content: "6", small: "", left: "" } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        : _c("span", [_vm._v(_vm._s(item.name))])
                    ]
                  }
                },
                {
                  key: "item.stats",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(item.contacts_count) +
                            " / " +
                            _vm._s(item.sent_sms_count) +
                            " / " +
                            _vm._s(item.unsent_sms_contacts_count) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "info",
                            icon: "",
                            small: "",
                            to: { name: "SmsCampaign", params: { id: item.id } }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            disabled: _vm.queuedTotal > 5,
                            icon: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.send({ id: item.id, amount: 100 })
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-send")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-view"),
            _c(
              "qs-confirmation-modal",
              _vm._b(
                {
                  on: {
                    "click:cancel": function($event) {
                      _vm.listDialog.value = false
                    },
                    "click:confirm": _vm.listDialog.callback
                  }
                },
                "qs-confirmation-modal",
                _vm.listDialog,
                false
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }