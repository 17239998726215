var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                "model-name": _vm.slug,
                order: _vm.filtersOrder,
                theme: "labase"
              },
              on: { input: _vm.setListParams },
              scopedSlots: _vm._u([
                {
                  key: "created_by_user.id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", {
                        attrs: { item: item.contact }
                      })
                    ]
                  }
                },
                {
                  key: "updated_by_user.id.item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("qs-contact-list-item", {
                        attrs: { item: item.contact }
                      })
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit", "view"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("revisions", $event)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("tr", { class: { reverted: !!item.reverted_at } }, [
                        _c("td", [_vm._v(" " + _vm._s(item.id) + " ")]),
                        _c(
                          "td",
                          [
                            item.type === "creation"
                              ? _c("v-icon", { attrs: { color: "qs-green" } }, [
                                  _vm._v("mdi-plus-box")
                                ])
                              : item.type === "deletion"
                              ? _c("v-icon", { attrs: { color: "qs-pink" } }, [
                                  _vm._v("mdi-minus-box")
                                ])
                              : _c(
                                  "v-icon",
                                  { attrs: { color: "qs-light-blue" } },
                                  [_vm._v("mdi-plus-minus-box")]
                                )
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                "[" +
                                  item.target_type +
                                  " #" +
                                  item.target_id +
                                  "]"
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          [
                            item.created_by_user
                              ? _c("qs-user-chip", {
                                  attrs: {
                                    item: item.created_by_user,
                                    small: ""
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("datetime")(item.created_at)) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: "",
                                  small: "",
                                  color: "qs-blue",
                                  to: "/revisions/" + item.id
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: "",
                                  small: "",
                                  color: "qs-blue",
                                  to:
                                    _vm.targetSlug(item) + "/" + item.target_id
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-magnify")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "", color: "qs-red" },
                                on: {
                                  click: function($event) {
                                    return _vm.$store.dispatch(
                                      "revisions/destroy",
                                      { id: item.id, parallel: false }
                                    )
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-undo")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c("router-view")
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }