import { RouteConfig } from 'vue-router';

const Conversations = () => import('@/views/Sms/Conversations.vue');
const ConversationReplies = () => import('@/views/Sms/ConversationReplies.vue');
const SmsCampaign = () => import('@/views/Sms/SmsCampaign.vue');
const SmsCampaigns = () => import('@/views/Sms/SmsCampaigns.vue');

const sentSmsIndex = {
  contextual(vm: Vue) {
    return {
      sms_campaign_id: vm.$route.params.sms_campaign_id,
    };
  },
  params: {
    fields: [
      'body',
      'contact.first_name',
      'contact.last_name',
      'unread_replies_count',
      'sent_at',
      'status',
      'to',
    ].join(','),
    order: '-unread_replies_count,-sent_at',
    per_page: '50',
  },
};

const retrieveSmsCampaign = {
  conditional(vm: Vue) {
    return !vm.$store.getters['sms_campaigns/item']
      || (vm.$store.getters['sms_campaigns/item']
      && vm.$store.getters['sms_campaigns/item'].id !== parseInt(vm.$route.params.sms_campaign_id, 10));
  },
  contextual(vm: Vue) {
    return {
      id: vm.$route.params.sms_campaign_id,
    };
  },
};

const smsRoutes: Array<RouteConfig> = [
  {
    path: '/sms',
    name: 'SmsCampaigns',
    component: SmsCampaigns,
    meta: {
      data: {
        sms_campaigns: {
          index: {
            params: {
              fields: [
                'name',
                'instance.name',
                'contacts_count',
                'sent_sms_count',
                'unsent_sms_contacts_count',
                'unread_replies_count',
              ].join(','),
            },
          },
        },
      },
      menus: [],
      title: 'SMS',
    },
  },
  {
    path: '/sms/:id',
    name: 'SmsCampaign',
    component: SmsCampaign,
    props: true,
    meta: {
      data: {
        contacts: {
          options: {},
          index: {
            params: {
              fields: [
                'full_name',
                'home_phone',
                'status',
                'v1_contact_id',
              ].join(','),
            },
            prefix(vm: Vue) {
              return `/sms_campaigns/${vm.$route.params.id}/`;
            },
          },
        },
        sent_sms: {
          index: {
            contextual(vm: Vue) {
              return {
                sms_campaign_id: vm.$route.params.id,
              };
            },
            params: {
              fields: [
                'contact.full_name',
                'contact.v1_contact_id',
                'to',
                'status',
                'unread_replies_count',
              ].join(','),
            },
          },
          fetchQueuedTotal: {},
        },
        sms_campaigns: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
      },
    },
  },
  {
    path: '/sms/:sms_campaign_id/conversations',
    name: 'SmsConversations',
    component: Conversations,
    meta: {
      data: {
        sent_sms: {
          index: sentSmsIndex,
        },
        sms_campaigns: {
          retrieve: retrieveSmsCampaign,
        },
      },
      title: 'Conversations',
      menus: [],
      // require: ['SMS_CAMPAIGN_CREATE'],
    },
    children: [
      {
        path: ':sent_sms_id',
        name: 'SmsConversationReplies',
        component: ConversationReplies,
        meta: {
          data: {
            sent_sms: {
              index: sentSmsIndex,
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.sent_sms_id,
                  };
                },
                params: {
                  fields: [
                    'body',
                    'contact.adopted_instances.name',
                    'contact.district.name',
                    'contact.first_name',
                    'contact.last_name',
                    'contact.apartment',
                    'contact.address',
                    'contact.city',
                    'contact.postal_code',
                    'contact.v1_contact_id',
                    'created_at',
                    'from',
                    'sms_campaign.*',
                    'sms_replies.*',
                    'sent_at',
                    'status',
                    'to',
                  ].join(','),
                  order: '-unread_replies_count,-sent_at',
                },
              },
            },
            sms_campaigns: {
              retrieve: retrieveSmsCampaign,
            },
          },
          title: 'Conversation',
          menus: [],
          // require: ['SMS_CAMPAIGN_CREATE'],
        },
      },
    ],
  },
];

export default smsRoutes;
