var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("qs-base-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function() {
          return [
            _c("qs-filters", {
              attrs: {
                "active-filters": _vm.params,
                "filters-definition": _vm.filtersDefinition,
                flat: "",
                "model-name": _vm.slug,
                theme: "labase"
              },
              on: { input: _vm.setListParams }
            })
          ]
        },
        proxy: true
      },
      {
        key: "main",
        fn: function() {
          return [
            _c("qs-data-table", {
              attrs: {
                action: ["edit"],
                headers: _vm.headers,
                items: _vm.data,
                loading: !_vm.routeDataLoaded || _vm.loading,
                options: _vm.options,
                "set-options": _vm.setOptions,
                tile: "",
                total: _vm.total || 0
              },
              on: {
                edit: function($event) {
                  return _vm.goto("form_definition_templates", $event)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "item.actions",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("qs-button", {
                            staticClass: "mr-2",
                            attrs: {
                              color: "info",
                              icon: "mdi-pencil",
                              label: "Modifier",
                              small: "",
                              theme: "labase",
                              to: {
                                name: "FormDefinitionTemplatesForm",
                                params: { id: item.id }
                              }
                            }
                          }),
                          _c("qs-button", {
                            staticClass: "mr-2",
                            attrs: {
                              color: "info",
                              icon: "mdi-content-copy",
                              label: "Créer une copie",
                              small: "",
                              theme: "labase"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadCopy(item)
                              }
                            }
                          }),
                          _c("qs-button", {
                            attrs: {
                              color: "error",
                              icon: "mdi-delete",
                              label: "Supprimer",
                              small: "",
                              theme: "labase"
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirmAndDelete(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-view"),
            _c(
              "qs-confirmation-modal",
              _vm._b(
                {
                  on: {
                    "click:cancel": function($event) {
                      _vm.listDialog.value = false
                    },
                    "click:confirm": _vm.listDialog.callback
                  }
                },
                "qs-confirmation-modal",
                _vm.listDialog,
                false
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }