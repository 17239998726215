







































import Vue from 'vue';
import Component from 'vue-class-component';

import BaseLayout from '@/components/Layout/BaseLayout.vue';
import DataEntryTable from '@/components/DataEntryTable.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsRelationField from 'qs_vuetify/src/components/Fields/QsRelationField.vue';

import { PersistedTag } from 'qs_vuetify/src/types/models';

@Component({
  components: {
    BaseLayout,
    DataEntryTable,
    QsButton,
    QsCard,
    QsRelationField,
  },
})
export default class Sandbox extends Vue {
  icon: string = 'mdi-account';

  tag: PersistedTag | null = null;
  tagsQueryDef: any = {
    key: 'data',
    slug: 'tags',
    text: 'name',
    value: 'id',
  };
}
