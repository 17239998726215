import { Module } from 'vuex';

import { EmailsViewState } from '@/types/states';
import { RootState } from 'qs_vuetify/src/types/states';

export const emailsView: Module<EmailsViewState, RootState> = {
  namespaced: true,
  state: {
    detailModal: false,
    options: {
      sortBy: ['id'],
      sortDesc: [],
      page: 1,
      itemsPerPage: 20,
    },
    params: {
      fields: '*,contact.address,contact.apartment,contact.birthdate,contact.city,contact.district.id,contact.district.name,contact.email,contact.emails,contact.first_name,contact.full_name,contact.gender,contact.home_phone,contact.last_name,contact.membership_status,contact.postal_code,contact.searchable_text,contact.v1_contact_id',
    },
  },
  getters: {
    detailModal(state) {
      return state.detailModal;
    },
    options(state) {
      return state.options;
    },
    params(state) {
      return state.params;
    },
  },
  mutations: {
    detailModal(state, value) {
      state.detailModal = value;
    },
    options(state, options) {
      state.options = options;
    },
    params(state, params) {
      state.params = params;
    },
  },
};

export default emailsView;
